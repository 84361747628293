<template>
  <v-dialog v-model="show" persistent max-width="600px" scrollable>
    <v-card>
      <v-toolbar color="primaryLight" dark dense fixed>
        <v-toolbar-title>Perfil de usuario</v-toolbar-title>

        <v-spacer />
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn icon dark @click="cancelar" v-bind="attrs" v-on="on">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>Cerrar perfil de usuario</span>
        </v-tooltip>
        <!-- <v-btn icon dark @click="cancelar">
          <v-icon>mdi-close</v-icon>
        </v-btn> -->
      </v-toolbar>

      <v-card-text>
        <v-container v-if="usuario">
          <!-- email -->
          <v-row class="mt-1">
            <v-col>
              <b>Email: </b> {{ usuario.USUARIO }}
            </v-col>
          </v-row>

          <!-- nombre y apellidos-->
          <v-row class="mt-1">
            <v-col>
              <b>Nombre y apellidos: </b> {{ nombre }}
            </v-col>
          </v-row>

          <!-- entidad -->
          <v-row class="mt-1">
            <v-col>
              <b>Entidad: </b> {{ usuario.ENTIDAD.entidad_titular }}
            </v-col>
          </v-row>

          <!-- rol -->
          <v-row class="mt-1">
            <v-col>
              <b>Rol: </b> {{ getRolList(usuario.ROL) }}
            </v-col>
          </v-row>

          <!-- separador de contenido -->
          <v-row v-if="formPass || formAjustarPeriodicidad">
            <v-divider 
              class="mx-3 mb-3" 
              v-show="!$vuetify.breakpoint.mobile" 
              horizontal
            />
          </v-row>

          <!-- SECCION CONTRASEÑA -->
          <div v-if="formPass">
            <v-form ref="form" v-model="isValid">
              <!-- contraseña actual -->
              <v-row class="mt-4 mb-0" dense align="center" justify="center">
                <v-col xs="2" sm="8" md="8" lg="8" xl="8">
                  <v-text-field 
                    label="Contraseña actual" 
                    v-model="currentPassword" 
                    :rules="[passwordRules.required]" 
                    :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'" 
                    :type="showCurrentPassword ? 'text' : 'password'" 
                    @click:append="showCurrentPassword = !showCurrentPassword" 
                    counter maxlength="20" outlined dense
                  />
                </v-col>
              </v-row>

              <!-- nueva contraseña -->
              <v-row class="mt-0 mb-0" dense align="center" justify="center">
                <v-col xs="2" sm="8" md="8" lg="8" xl="8">
                  <v-text-field 
                    label="Nueva contraseña" 
                    v-model="newPassword" 
                    :rules="[securePasswordRules.complete]" 
                    :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'" 
                    :type="showNewPassword ? 'text' : 'password'" 
                    @click:append="showNewPassword = !showNewPassword" 
                    counter maxlength="20" outlined dense
                  />
                </v-col>
              </v-row>

              <!-- repetir contraseña -->
              <v-row class="mt-0 mb-0" dense align="center" justify="center">
                <v-col xs="2" sm="8" md="8" lg="8" xl="8">
                  <v-text-field 
                    label="Repetir contraseña" 
                    v-model="repeatPassword" 
                    :rules="[securePasswordRules.complete, passwordRules.match(repeatPassword, newPassword)]" 
                    :append-icon="showRepeatNewPassword ? 'mdi-eye' : 'mdi-eye-off'" 
                    :type="showRepeatNewPassword ? 'text' : 'password'" 
                    @click:append="showRepeatNewPassword = !showRepeatNewPassword" 
                    counter maxlength="20" outlined dense
                  />
                </v-col>
              </v-row>
            </v-form>
          </div>

          <!-- SECCION PERIODICIDAD CAMBIO DE CONTRASEÑA -->
          <div v-if="formAjustarPeriodicidad">
            <v-form ref="formPeriodicidad">
              <!-- Información al usuario -->
              <v-row class="mt-0">
                <v-col class="mt-5" xs="10" sm="10" md="10" lg="10" xl="10">
                  <span>¿Permitir el cambio de contraseña tras un periodo de tiempo?</span>
                </v-col>
                <!-- Switch permitir/no permitir cambio de contraseña -->
                <v-col class="mt-0" xs="2" sm="2" md="2" lg="2" xl="2">
                  <v-switch
                    v-model="permiteCambioPass"
                  />
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <!-- :disabled="!isValid" -->
        <!-- cambiar contraseña -->
        <v-btn
          v-show="!formPass && !formAjustarPeriodicidad" @click="mostrarFormPass()" 
          text color="blue" class="mt-0"
        >
          Cambiar contraseña
          <v-icon small>mdi-lock-outline</v-icon>
        </v-btn>

        <!-- botón gestionar periodicidad cambio contraseña -->
        <v-btn
          v-show="!formAjustarPeriodicidad && !formPass" @click="gestionarCambioContraseña()" 
          text color="green" class="mt-0"
        >
          Periodicidad contraseña
          <v-icon small>mdi-lock-clock</v-icon>
        </v-btn>
        <v-spacer />
        <!-- cancelar, ocultar formulario de cambio de contraseña -->
        <v-btn 
          v-show="formPass" 
          @click="cerrarFormPass()" 
          text color="red" 
          class="mt-0"
        >
          Cancelar
          <v-icon small>mdi-close</v-icon>
        </v-btn>
        <v-btn 
          v-show="isValid" 
          @click="aceptar()" 
          text color="green" 
          class="mt-0"
        >
          guardar
          <v-icon dense>mdi-content-save</v-icon>
        </v-btn>
        <!-- cancelar, ocultar formulario de periodicidad de contraseña -->
        <v-btn 
          v-show="formAjustarPeriodicidad" 
          @click="cerrarFormAjustarPeriodicidad()" 
          text color="red" 
          class="mt-0"
        >
          Cancelar
          <v-icon dense>mdi-close</v-icon>
        </v-btn>
        <v-btn
          v-show="formAjustarPeriodicidad"
          @click="aceptarPeriodicidad()"
          text color="green"
          class="mt-0"
          :disabled="isLoading"
          :loading="isLoading"
        >
          Guardar
          <v-icon dense>mdi-lock-clock</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    show: Boolean
  },

  data: () => ({
    isValid: false,
    formPass: false,
    formAjustarPeriodicidad: false,
    permiteCambioPass: false,
    usuario: null,
    nombre: null,

    currentPassword: null,
    showCurrentPassword: false,
    newPassword: null,
    repeatPassword: null,
    showNewPassword: false,
    showRepeatNewPassword: false,

    isLoading: false
  }),

  watch: {
    async show () {
      this.usuario = this.currentUsuario
      this.nombre = this.currentUsuario.NOMBRE + ' ' + this.currentUsuario.APELLIDOS
      this.permiteCambioPass = this.currentUsuario.CADUCIDAD_PASSWORD
    }
  },

  computed: {
    ...mapGetters('usuario', [
      'currentUsuario', 'checkCurrentPassUsuario'
    ])
  },

  methods: {
    ...mapActions('usuario', ['existUsuario', 'resetPasswordPerfilUsuario', 
      'updateCaducidadPassword']),

    getRolList (listaRoles) {
      let roles = ''
      for (let i = 0; i < listaRoles.length; i++) {
        if (i === 0) {
          roles = listaRoles[i].nombre
        } else {
          roles = roles + ', ' +listaRoles[i].nombre
        }
      }
      return roles
    },

    cancelar () {
      if (this.formPass) {
        this.$refs.form.reset()
        this.formPass = false
        this.isValid = false
      } else if (this.formAjustarPeriodicidad) {
        this.formAjustarPeriodicidad = false
        this.isValid = false
      }
      this.$emit('cerrar')
    },

    async aceptar () {
      // Validar contraseña actual escrita por el usuario
      let validate = {
        user: this.usuario.USUARIO,
        data: this.currentPassword
      }
      let response = await this.$store.dispatch('usuario/checkCurrentPassUsuario', validate)

      if (response) {
        // Validar nueva contraseña y repetir nueva contraseña
        if (this.newPassword === this.repeatPassword) {
          // actualizar la nueva contraseña
          let validate = {
            id_usuario: this.usuario.ID,
            password: this.newPassword
          }
          this.resetPasswordPerfilUsuario(validate)
          // Limpiar form
          this.$refs.form.reset()
          this.formPass = false
          this.isValid = false
          // Cerrar modal
          this.$emit('cerrar')
        } else {
          this.$notify({
            title: 'Las contraseñas no coinciden',
            text: 'Revise la nueva contraseña introducida',
            type: 'error'
          })
        }
      } else {
        this.$notify({
          title: 'Contraseña incorrecta',
          text: 'La contraseña actual no coincide',
          type: 'error'
        })
      }
    },

    mostrarFormPass () {
      this.formPass = true
    },

    cerrarFormPass () {
      this.$refs.form.reset()
      this.formPass = false
      this.isValid = false
    },

    gestionarCambioContraseña () {
      this.formAjustarPeriodicidad = true
    },

    cerrarFormAjustarPeriodicidad () {
      this.formAjustarPeriodicidad = false
      this.isValid = false
    },

    async aceptarPeriodicidad () {
      this.isLoading = true
      const response = await this.updateCaducidadPassword({
        id_usuario: this.usuario.ID, 
        solicitar_cambio_password: this.permiteCambioPass
      })

      response && this.$notify({
        title: 'Preferencias guardadas correctamente', 
        text: 'Se han actualizado correctamente las preferencias de periodicidad de contraseña'
      })

      !response && this.$notify({
        title: 'Error en el guardado', 
        text: 'No se han podido actualizar las preferencias de periodicidad de contraseña',
        type: 'error'
      })

      this.isLoading = false
      this.formAjustarPeriodicidad = false
    }
  },
}
</script>

<style scoped>
::v-deep .v-text-field > .v-input__control > .v-input__slot::before {
  border-style: none;
  border-width: 0;
}
</style>