<template>
  <div>
    <v-form ref="form">
      <v-row>
        <v-col>
          <v-select 
            v-model="faseSelected" 
            :items="fasesEmergencia" 
            :menu-props="{ bottom: true, offsetY: true }"
            label="Fase" 
            dense outlined 
            item-text="nombre" 
            item-value="id" 
            placeholder="Fase"
            :rules="[globalRules.required]"
            single-line autocomplete hide-details
          />
        </v-col>
      </v-row>
      <v-row
        v-if="faseSelected && situacionesOperativas && situacionesOperativas.length > 0"
      >
        <v-col>
          <v-select 
            v-model="situacionOperativaSelected" 
            :items="situacionesOperativas" 
            :menu-props="{ bottom: true, offsetY: true }"
            label="Situacion Operativa" 
            dense outlined 
            item-text="nombre" 
            item-value="id"
            placeholder="Situacion Operativa"
            :rules="[globalRules.required]"
            single-line autocomplete hide-details
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { SituacionOperativaRequest } from '@/api/situacionOperativa'

export default {

  props: {
    situacionOperativa:{
      type: Object,
      default: null
    }
  },

  data: () => ({
    situacionOperativaSelected: null,
    situacionesOperativas: null,
    faseSelected: null 
  }),
  
  async mounted () {
    if (this.situacionOperativa){
      // this.faseSelected = this.situacionOperativa.fase
      // this.situacionOperativaSelected = this.situacionOperativa.id
      // if (this.faseSelected){
      //   this.situacionesOperativas = 
      //     await SituacionOperativaRequest.getByFaseEmergencia(this.faseSelected)
      // }
    }

    this.$eventHub.$on('resetFormtSituacionOperativa', this.resetForm)
    
  },

  computed: {
    fasesEmergencia () {
      return this.$store.state.emergencia.fasesEmergencia
    }
  },
  watch: {
    async faseSelected (newValue) {
      if (newValue){
        this.situacionesOperativas =
          await SituacionOperativaRequest.getByFaseEmergencia(newValue)
      }
    },

    situacionesOperativas (newValue){
      if (newValue && newValue.length == 1){
        this.situacionOperativaSelected = newValue[0].id
      } else {
        // Ocultar fecha y hora de declaracion
        this.$emit('showFechaHoraDeclaracion')
      }
    },

    situacionOperativaSelected (newValue){
      if (this.faseSelected) {
        this.$emit('situacionOperativaSelected', {
          fase: this.faseSelected, 
          situacionOperativa: newValue
        })
      }
    }
  },

  methods: {
    resetForm () {
      this.$refs.form.reset()
      this.faseSelected = null
      this.situacionOperativaSelected = null
      this.situacionesOperativas = null
    }
  }

}
</script>