var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":"","height":"100%"}},[_c('v-toolbar',{staticStyle:{"z-index":"2"},attrs:{"color":"primaryLight","dark":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Gestión de medios")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.disabledByRol,"fab":"","bottom":"","right":"","absolute":"","color":"primary"},on:{"click":_vm.showAddMedioDialog}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Añadir medio")])])],1),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"sm":"3"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Búsqueda","outlined":"","clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-data-table',{staticClass:"table",attrs:{"headers":_vm.headers,"items":_vm.medios,"search":_vm.search,"fixed-header":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('p',[_vm._v("Datos no disponibles.")])]},proxy:true},{key:"item.TELEFONO_INTERNO",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.TELEFONO_INTERNO))])]}},{key:"item.TELEFONO_EXTERNO",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'tel:+34' + item.TELEFONO_EXTERNO}},[_vm._v(_vm._s(item.TELEFONO_EXTERNO))])]}},{key:"item.FECHA",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.FECHA)))])]}},{key:"item.ACCIONES",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.disabledByRol,"icon":""},on:{"click":function($event){return _vm.showEditMedioDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"teal"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.disabledByRol,"icon":""},on:{"click":function($event){return _vm.acceptDeleteMedio(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Borrar")])])]}}],null,true)})],1)],1)],1),_c('vx-dialog-add-edit-medio',{attrs:{"show":_vm.showDialog,"isEdit":_vm.isEdit,"editedMedio":_vm.editMedio},on:{"cancelar":function($event){_vm.showDialog = false},"addMedio":_vm.acceptAddMedio,"editMedio":_vm.acceptEditMedio}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }