<template>
  <v-card tile height="100%">
    <v-toolbar color="primaryLight" dark dense style="z-index: 2">
      <v-toolbar-title>Gestión de contactos e interlocutores</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <v-container fluid>
        <v-row align="center">
          <v-col cols="6" md="3" sm="3">
            <v-text-field
              :class="{'inputSelectMobile': $vuetify.breakpoint.mobile}"
              v-model="search"
              append-icon="mdi-magnify"
              label="Búsqueda"
              :dense="$vuetify.breakpoint.mobile"
              outlined
              clearable
              hide-details
              @input="searchEntidades"
            />
          </v-col>

          <v-col cols="6" md="3" sm="3">
            <v-select
              :class="{'inputSelectMobile': $vuetify.breakpoint.mobile}"
              v-model="distrito"
              :items="listaDistritos"
              :menu-props="{ bottom: true, offsetY: true }"
              label="Distritos"
              :dense="$vuetify.breakpoint.mobile"
              outlined
              item-text="nombre"
              item-value="nombre"
              return-object
              clearable
              hide-details
            />
          </v-col>
        </v-row>
                
        <v-row>
          <v-data-table
            :class="{
              'table': !$vuetify.breakpoint.mobile,
              'tableMobile': $vuetify.breakpoint.mobile}"
            :headers="headers"
            :items="entidadesFilter ? entidadesFilter : entidadesTable"
            height="100%"
            :loading="isLoading"
            loading-text="Cargando datos... por favor, espere"
            fixed-header
            hide-default-footer
            :page.sync="currentPage"
            :items-per-page="itemsPerPage"
          >
            <!-- NO DATA -->
            <template #no-data>
              <p>Datos no disponibles.</p>
            </template>

            <!-- FECHA ULTIMA ACTUALIZACION -->
            <template #[`item.fechastamp`]="{ item }">
              <span>{{ item.fechastamp | formatDate }}</span>
            </template>

            <!-- USUARIO -->
            <template #[`item.Usuario`]="{ item }">
              <span>{{ item.Usuario.nombre + ' ' + item.Usuario.apellidos }}</span>
            </template>

            <!-- ACCIONES CONTACTOS EMERGENCIA-->
            <template #[`item.ACCIONES_EMERGENCIA`]="{ item }">
              <!-- VER CONTACTOS EMERGENCIA -->
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="showContactsByType(item, 'emergencias')"
                  >
                    <img
                      :src="require('@/assets/icons/contacto_emergencia.svg')" 
                      height="24px" width="24px"
                    >
                  </v-btn>
                </template>
                <span>Ver contactos emergencias</span>
              </v-tooltip>
            </template>

            <template #[`item.ACCIONES_ADMINISTRACION`]="{ item }">
              <!-- VER CONTACTOS ADMINISTRACION -->
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="showContactsByType(item, 'administrativos')"
                  >
                    <img :src="require('@/assets/icons/contacto_administrativo.svg')" height="24px" width="24px">
                  </v-btn>
                </template>
                <span>Ver contactos administrativos</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-row>

        <!-- SECCIÓN PAGINACIÓN -->
        <v-row>
          <v-col xs="2" sm="3" md="2" v-if="!$vuetify.breakpoint.mobile">
            <v-row>
              <div class="text-center align-center mt-6 pl-6 ml-6">
                <span>Total {{ totalRecords }} registros</span>
              </div>
            </v-row>
          </v-col>
          <v-col xs="1" sm="4" md="3">
            <v-row>
              <v-col sm="4" md="4" class="textMostrar">
                <div>
                  <span>Mostrar</span>
                </div>
              </v-col>
              <v-col xs="4" sm="5" md="4">
                <v-select
                  dense
                  outlined
                  hide-details
                  v-model="itemsPerPage"
                  :items="perPageOptions"
                  :class="{
                    'select': !$vuetify.breakpoint.mobile,
                    'selectMobile': $vuetify.breakpoint.mobile}"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="4" md="6">
            <div>
              <v-pagination
                v-model="currentPage"
                :length="pageCount"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { StringHelper } from '@/helpers/StringHelper'

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
pdfMake.vfs = pdfFonts.pdfMake.vfs

export default {
  components: {},

  data: () => ({
    entidadesTable: [],
    search: '',
    entidadesFilter: null,
    isLoading: false,
    distrito: undefined,

    currentPage: 1,
    itemsPerPage: 15,
    perPageOptions: [15, 50, 75, 100],
  }),

  watch: {
    listaEntidades () {
      if (this.listaEntidades) {
        this.entidadesTable = this.listaEntidades
      }
    },
  },
  computed: {
    ...mapGetters('entidad', ['listaEntidades']),

    headers () {
      return [
        {
          text: 'Entidad',
          value: 'entidad_titular',
          width: '350px',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Tipo entidad',
          value: 'Tipo_entidad.nombre',
          width: '140px',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Dirección',
          value: 'domicilio',
          width: '300px',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Código postal',
          value: 'cp',
          width: '130px',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Distrito',
          value: 'Distrito.nombre',
          width: '120px',
          align: 'left',
          sortable: true,
          filter: this.filterDistrito,
        },
        {
          text: 'Ult. actualización',
          value: 'fechastamp',
          align: 'left',
          width: '120px',
          sortable: true
        },
        {
          text: 'Usuario',
          value: 'Usuario',
          align: 'left',
          width: '120px',
          sortable: true
        },
        {
          text: 'Contactos de emergencia',
          value: 'ACCIONES_EMERGENCIA',
          align: 'center',
          width: '110px',
          sortable: false,
          svg: 'contacto_emergencia.svg'
        },
        {
          text: 'Contactos administrativos',
          value: 'ACCIONES_ADMINISTRACION',
          align: 'center',
          width: '100px',
          sortable: false,
          svg: 'contacto_administrativo.svg'
        },
      ]
    },

    listaDistritos () {
      return this.$store.getters['distrito/distritos']
    },

    totalRecords () {
      return this.entidadesFilter ? 
        this.entidadesFilter.length : 
        this.entidadesTable ? this.entidadesTable.length : 
          0
    },

    pageCount () {
      return parseInt((this.totalRecords / this.itemsPerPage) + 1)
    }
  },
  methods: {
    ...mapActions('entidad', ['fetchListaEntidades', 'updateListaEntidades']),
    ...mapActions('distrito', ['fetchDistritos']),

    filterDistrito (value) {
      if (!this.distrito) {
        return true
      }

      return value === this.distrito.nombre
    },

    showContactsByType (item, type) {
      this.$router
        .push({
          path: '/contactos/listaContactos/',
          query: {
            id_entidad: item.id_entidad,
            entidad_titular: item.entidad_titular,
            type: type,
          },
        })
        .catch(() => {})
    },

    async loadData () {
      this.isLoading = true
      try {
        await this.fetchListaEntidades()
        let distritos = await this.$store.getters['distrito/distritos']
        if (!distritos) {
          await this.fetchDistritos()
        }
      } catch (err) {
        if (err.response.status !== 200 && err.response.status !== 500) {
          this.$notify({
            title: err.response.statusText,
            text: 'El usuario no dispone de permisos para acceder al módulo de Gestión de contactos',
            type: 'error'
          })
        }
        this.$router.push('/')
      }
      
      this.isLoading = false
    },

    searchEntidades () {

      // Atributos no validos para filtrar
      function atributoValido (atributo) {
        let atributosNoValidos = ['codigo', 'borrado', 'descripcion','fechastamp', 
          'logo', 'convenio', 'documentacion', 'Delegacions', 'recogida_info', 
          'is_chat_default', 'Periodicidad', 'Tipo_actualizacion', 'Grupo_accions', 
          'usuariostamp', 'id_tipo_entidad', 'id_tipo_actualizacion', 'id_entidad',
          'id_distrito', 'documentacion', 'is_default', 'id_delegacion', 'id_departamento',
          'id_periodicidad', 'id_grupo_accion']

        let encontrado = atributosNoValidos.find(x => x === atributo)
        return encontrado ? false : true
      }

      // Filtro de busqueda para todas las entidades
      let searchText = this.search ? this.search : ''

      this.entidadesFilter = this.entidadesTable.filter(function (entidades) {
        for (var attrEntidad in entidades) {
          if (atributoValido(attrEntidad)) {
            // Usuario
            if (attrEntidad === 'Usuario') {
              for (var attrUsuario in entidades[attrEntidad]) {
                if (atributoValido(attrUsuario)) {
                  let atributo = entidades[attrEntidad]['nombre'] + ' ' + entidades[attrEntidad]['apellidos']

                  if (StringHelper.compareString(searchText, atributo)) return entidades[attrEntidad]
                }
              }
            } else if (attrEntidad === 'Tipo_entidad') {
              // Tipo_entidad
              for (var attrTipoEntidad in entidades[attrEntidad]) {
                if (atributoValido(attrTipoEntidad)) {
                  let atributo = entidades[attrEntidad][attrTipoEntidad]

                  if (StringHelper.compareString(searchText, atributo)) return entidades[attrEntidad]
                }
              }                
            } else if (attrEntidad === 'Distrito') {
              // Distrito
              if (entidades[attrEntidad]) {
                for (var attrDistrito in entidades[attrEntidad]){
                  if (atributoValido(attrDistrito)) {
                    let atributo = entidades[attrEntidad][attrDistrito]

                    if (StringHelper.compareString(searchText, atributo)) return entidades[attrEntidad]
                  }
                }
              }
            }
            else {
              if (entidades[attrEntidad]) {
                let atributo = entidades[attrEntidad].toString() // CP es int

                if (StringHelper.compareString(searchText, atributo)) return entidades
              }
            }
          }
        }
      })
    }
  },

  async created () {
    // await this.loadData()
  },

  async activated () {
    await this.loadData()
  }
}
</script>

<style lang="scss" scoped>
/* Estilos para que la tabla tenga un max-height y pueda crecer */
.table {
  width: 100vw;
  max-height: calc(100vh - 270px);
}
.tableMobile {
  width: 100vw;
  max-height: calc(100vh - 290px)
}

.v-data-table {
  overflow: auto;
}

.v-data-table >>> .v-data-table__wrapper {
  overflow: unset;
}

/* Hover transparente para todas las filas */
::v-deep tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
/* Hover en color gris para las filas impares */
::v-deep tbody {
  tr:hover:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05) !important;
  }
}
/* Color gris fila data table impar */
::v-deep tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}

/* Sticky column data-table - Entidad */
::v-deep thead tr th:nth-of-type(1) {
  position: sticky;
  left: 0;
}

::v-deep tbody tr:nth-of-type(odd) td:nth-of-type(1) {
  background: whitesmoke;
  position: sticky;
  left: 0;
}

::v-deep tbody tr td:nth-of-type(1) {
  background: white;
  position: sticky;
  left: 0;
}

/* Sticky column data-table - Contactos emergencia */
::v-deep thead tr th:nth-of-type(8) {
  position: sticky;
  right: 0;
}

::v-deep tbody tr:nth-of-type(odd) td:nth-of-type(8) {
  background: whitesmoke;
  position: sticky;
  right: 0;
}

::v-deep tbody tr td:nth-of-type(8) {
  background: white;
  position: sticky;
  right: 0;
}

/* Sticky column data-table - Contactos administracion */
::v-deep thead tr th:nth-of-type(9) {
  position: sticky;
  right: 1;
}

::v-deep tbody tr:nth-of-type(odd) td:nth-of-type(9) {
  background: whitesmoke;
  position: sticky;
  right: 1;
}

::v-deep tbody tr td:nth-of-type(9) {
  background: white;
  position: sticky;
  right: 1;
}

/* Tamnaño inputs y select */
.inputSelectMobile {
  // margin-top: -20px;
  margin-bottom: 0px;
}

/* Tamaño select mostrar por página */
.select {
  width: 85px;
}
.selectMobile {
  width: 85px;
}

/* Mostrar */
.textMostrar {
  margin-top: 10px;
  padding-left: 0px;
  padding-right: 0px;
  display: flex; 
  justify-content: flex-end;
}

/* Resultados */
.textResultados {
  margin-top: 10px;
  padding-left: 0px;
  padding-right: 0px;
  margin-left: -15px;
  margin-right: 0px;
  display: flex; 
  justify-content: flex-start;
}

/* Paginacion */
.paginacion {
  padding-left: 0px;
  padding-right: 0px;
  display: flex; 
  justify-content: flex-end;
}
</style>
