<template>
  <v-card height="275px">
    <v-toolbar color="primaryLight" dark dense>
      <v-toolbar-title>Recuperación de acceso</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <div class="containerInfo" align="left">
        <span class="informacionUsuario">
          Para recuperar su usuario o contraseña puede ponerse en contacto con el administrador a través de los siguientes medios:
        </span>
      </div>

      <div class="containerEmail" align="left">
        <span class="email">Email: 
          <a href="mailto:osemergencias@madrid.es" class="dataEmail">osemergencias@madrid.es</a>
        </span>
      </div>

      <div class="containerTelefono" align="left">
        <span class="telefono">Teléfono: 
          <a href="tel:+34 91 480 34 88" class="dataTelefono">+34 91 480 34 88</a>
        </span>
      </div>
    </v-card-text>

    <v-card-actions class="justify-center">
      <v-btn color="primary" :width="$vuetify.breakpoint.mobile ? '35%' : '30%'" @click="cancelar">Entendido</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {

  methods: {
    cancelar () {
      this.$emit('cancelar')
    }
  }

}
</script>

<style scoped lang="scss">

.containerInfo {
    margin-top: 4px;
    margin-left: 15px;
    margin-right: 15px;
    .informacionUsuario {
        justify-content: center;
    }
}

.containerEmail {
    margin-top: 16px;
    margin-left: 15px;
    margin-right: 15px;
    .email {
        color: #003DF6;
        font-size: 1.10rem;
        .dataEmail {
            text-decoration: none;
            font-size: 1.10rem;
        }
    }
}

.containerTelefono {
    margin-top: 10px;
    margin-bottom: -13px;
    margin-left: 15px;
    margin-right: 15px;
    .telefono {
        color: #003DF6;
        font-size: 1.10rem;
        .dataTelefono {
            text-decoration: none;
            font-size: 1.10rem;
        }
    }
}


</style>
