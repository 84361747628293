<template>
  <v-dialog v-model="showList" scrollable persistent max-width="800px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title> {{ nombreCargo }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-data-table 
              :class="{'table': !$vuetify.breakpoint.mobile, 
                       'tablemobile': $vuetify.breakpoint.mobile}"
              :headers="headers"
              :items="usuarios"
              height="100%"
              :search="search"
              loading-text="Cargando datos... por favor, espere"
              disable-pagination
              hide-default-footer
            >
              <!-- NO DATA -->
              <template #no-data>
                <p>Datos no disponibles</p>
              </template>
            </v-data-table>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    showList: Boolean,
    cargo: String,
    listUsuarios: Array
  },

  data: () => ({
    search: '',
    usuarios: []
  }),

  computed: {
    headers () {
      return [
        { text: 'Usuario', value: 'username', align: 'left', sortable: true, width: '80px' },
        { text: 'Nombre', value: 'nombre', align: 'left', sortable: true, width: '80px' },
        { text: 'Apellidos', value: 'apellidos', align: 'left', sortable: true, width: '120px' },
        { text: 'Entidad', value: 'Entidad.entidad_titular', align: 'left', sortable: true, width: '200px' }
      ]
    },

    nombreCargo () {
      return this.cargo
    }
  },

  watch: {
    async showList () {
      if (this.showList) {
        this.usuarios = this.listUsuarios
      }
    }
  },

  methods: {
    cancelar () {
      this.clearData()
      this.$emit('cancelar')
    },

    clearData () {
      this.usuarios = []
    }
  }
}
</script>

<style lang="scss" scoped>
/* Estilos para que la tabla tenga un max-height y pueda crecer */
.table {
  width: 100vw;
  max-height: calc(100vh - 215px);
}
.tableMobile {
  width: 100vw;
  max-height: calc(100vh - 209px)
}

.v-data-table {
  overflow: auto;
}

.v-data-table >>> .v-data-table__wrapper {
  overflow: unset;
}

/* Hover transparente para todas las filas */
::v-deep tbody {
          tr:hover {
            background-color: transparent !important;
          }
        }
/* Hover en color gris para las filas impares */
::v-deep tbody {
  tr:hover:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05) !important;
  }
}
/* Color gris fila data table impar */
::v-deep tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}
</style>