<template>
  <v-card tile height="100%">
    <v-toolbar color="primaryLight" dark dense>
      <v-toolbar-title v-if="type === 'emergencias' && entidad_titular">{{ entidad_titular }} - Contactos de {{ type }}</v-toolbar-title>
      <v-toolbar-title v-else-if="type === 'administrativos' && entidad_titular">{{ entidad_titular }} - Contactos {{ type }}</v-toolbar-title>
      <!-- AÑADIR CONTACTO?? -->
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            fab
            bottom
            right
            absolute
            color="primary"
            :disabled="!userIsSameEntity() && !comprobarPermisos([])"
            v-show="comprobarPermisos(['MOD_CONTACTOS'])"
            @click="showAddContactoDialog"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Añadir contacto</span>
      </v-tooltip>
    </v-toolbar>

    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col xs="3" sm="3">
            <v-text-field
              :class="{'inputSelectMobile': $vuetify.breakpoint.mobile}"
              v-model="search"
              append-icon="mdi-magnify"
              label="Búsqueda"
              outlined
              :dense="$vuetify.breakpoint.mobile"
              clearable
              hide-details
              @input="searchContactos"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-data-table 
            class="table"
            :headers="headersContactos"
            :items="contactosFilter ? contactosFilter : contactos ? contactos : []"
            height="100%"
            :loading="isLoading"
            loading-text="Cargando datos... por favor, espere"
            fixed-header
            disable-pagination
            hide-default-footer
          >
            <!-- NO DATA -->
            <template #no-data>
              <p>Datos no disponibles.</p>
            </template>

            <!-- TIPO CONTACTO -->
            <template #[`item.titular`]="{ item }">
              <span v-if="item.titular === true">Titular</span>
              <span v-else>Suplente</span>
            </template>

            <!-- TLF. FIJO -->
            <template #[`item.Telefono_contactos.fijo`]="{ item }">
              <div v-for="(subItem, iSub) in item.Telefono_contactos" :key="subItem.id_telefono_contacto">
                <div v-if="subItem.fijo">
                  <!-- enlace -->
                  <span v-if="iSub < item.Telefono_contactos.findIndex(x => x.fijo === true).length - 1">
                    <a :href="`tel:${subItem.numero}`">{{ subItem.numero }}</a>,
                  </span>
                  <span v-else>
                    <a :href="`tel:${subItem.numero}`">{{ subItem.numero }}</a>
                  </span>
                </div>
              </div>
            </template>

            <!-- TLF. MOVIL -->
            <template #[`item.Telefono_contactos`]="{ item }">
              <div v-for="(subItem, iSub) in item.Telefono_contactos" :key="subItem.id_telefono_contacto">
                <div v-if="!subItem.fijo">
                  <span v-if="iSub < item.Telefono_contactos.findIndex(x => x.fijo === false).length - 1">
                    <a :href="`tel:${subItem.numero}`">{{ subItem.numero }}</a>, 
                  </span>
                  <span v-else>
                    <a :href="`tel:${subItem.numero}`">{{ subItem.numero }}</a>
                  </span>
                </div>
              </div>
            </template>

            <!-- EMAIL -->
            <template #[`item.Email_contactos`]="{ item }">
              <div v-for="(subItem, iSub) in item.Email_contactos" :key="subItem.id_email_contacto">
                <span v-if="iSub < item.Email_contactos.length - 1">
                  <a :href="`mailto:${subItem.email}`">{{ subItem.email }}</a>, 
                </span>
                <span v-else>
                  <a :href="`mailto:${subItem.email}`">{{ subItem.email }}</a> 
                </span>
              </div>
            </template>

            <!-- FECHA ULTIMA ACTUALIZACION -->
            <template #[`item.fechastamp`]="{ item }">
              <span>{{ item.fechastamp | formatDate }}</span>
            </template>

            <!-- USUARIO -->
            <template #[`item.Usuario`]="{ item }">
              <span>{{ item.Usuario.nombre + ' ' + item.Usuario.apellidos }}</span>
            </template>

            <!-- ACCIONES -->
            <template #[`item.ACCIONES`]="{ item }">
              <!-- EDITAR CONTACTO DELEGACION -->
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    :disabled="!userIsSameEntity() && !comprobarPermisos([])"
                    v-show="comprobarPermisos(['MOD_CONTACTOS'])"
                    @click="showEditContactoDialog(item)"
                  >
                    <v-icon color="teal">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <!-- BORRAR CONTACTO DELEGACION -->
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    :disabled="!userIsSameEntity() && !comprobarPermisos([])"
                    v-show="comprobarPermisos(['MOD_CONTACTOS'])"
                    @click="acceptDeleteContacto(item)"
                  >
                    <v-icon color="error">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Borrar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-row>
      </v-container>
    </v-card-text>

    <!-- DIALOG EDIT CONTACTO -->
    <vx-dialog-add-edit-contacto
      :show="showDialog"
      :isEdit="isEdit"
      :editedContacto="editContacto"
      :tipoContacto="type"
      :idEntidad="id_entidad"
      @cancelar="showDialog = false"
      @addContacto="acceptAddContacto"
      @editContacto="acceptEditContacto"
    />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { StringHelper } from '@/helpers/StringHelper'

import DialogAddEditContacto from './DialogAddEditContactos.vue'

export default {

  components: {
    'vx-dialog-add-edit-contacto': DialogAddEditContacto
  },

  data: () => ({
    type: null,
    id_entidad: null,
    entidad_titular: null,

    headersContactos: [
      { text: 'Delegación', value: 'Nivel_contacto.Delegacion.nombre', align: 'left', sortable: true, width: '120px' },
      { text: 'Dirección', value: 'Nivel_contacto.Delegacion.domicilio', align: 'left', sortable: true, width: '120px' },
      { text: 'Distrito', value: 'Nivel_contacto.Delegacion.Distrito.nombre', align: 'left', sortable: true, width: '100px' },
      { text: 'Nivel escalado', value: 'Nivel_contacto.nivel', align: 'left', sortable: true, width: '100px' },
      { text: 'Tipo contacto', value: 'titular', align: 'left', sortable: true, width: '130px' },
      { text: 'Nombre', value: 'nombre', align: 'left', sortable: true, width: '120px' },
      { text: 'Cargo', value: 'cargo', align: 'left', sortable: true, width: '220px' },
      { text: 'Fijo', value: 'Telefono_contactos.fijo', align: 'left', sortable: true, width: '100px' },
      { text: 'Móvil', value: 'Telefono_contactos', align: 'left', sortable: true, width: '100px' },
      { text: 'Correo electrónico', value: 'Email_contactos', align: 'left', sortable: true, width: '130px' },
      { text: 'Otros posibles medios', value: 'otros_medios', align: 'left', sortable: true, width: '140px' },
      { text: 'Observaciones', value: 'observaciones', align: 'left', sortable: true, width: '140px' },
      { 
        text: 'Ult. actualizacion', 
        value: 'fechastamp', 
        align: 'left', 
        width: '150px', 
        sortable: true 
      },
      {
        text: 'Usuario',
        value: 'Usuario',
        align: 'left',
        width: '120px',
        sortable: true
      }
    ],

    contactos: null,

    search: '',
    contactosFilter: null,

    isLoading: false,

    showDialog: false,
    isEdit: false,
    editContacto: {}
  }),

  watch: {},

  computed: {},

  async activated () {
    this.id_entidad = this.$route.query.id_entidad
    this.entidad_titular = this.$route.query.entidad_titular
    this.type = this.$route.query.type
    let data = {
      id_entidad: this.id_entidad,
      type: this.type === 'emergencias' ? true : false 
    }
    // Obtener datos de contactos de las delegaciones, by entidad
    await this.getContactosByEntidad (data)
  },

  methods: {
    ...mapActions('contacto', [
      'addContactoByDelegacion', 'editContactoByDelegacion'
    ]),
    ...mapActions('entidad', [
      'fetchEntidades', 'fetchListaEntidades'
    ]),

    comprobarPermisos (listaRolesPermisos) {
      return this.$store.getters['usuario/rolesUsuario'](listaRolesPermisos)
    },

    async getContactosByEntidad (data) {
      try {
        this.contactos = JSON.parse(JSON.stringify(await this.$store.getters['contacto/getContactosByEntidad'](data)))
      } catch (err) {
        if (err.response && (err.response.status !== 200 && err.response.status !== 500)) {
          this.$notify({
            title: err.response.statusText,
            text: 'El usuario no dispone de permisos para acceder al listado de contactos',
            type: 'error'
          })
        }
        this.$router.push('/')
      }
    },

    showAddContactoDialog () {
      this.editContacto = null
      this.showDialog = true
      this.isEdit = false
    },

    showEditContactoDialog (item) {
      this.editContacto = Object.assign({}, item)
      this.showDialog = true
      this.isEdit = true
    },

    async acceptAddContacto (contacto) {
      this.showDialog = false
      let newContacto = await this.addContactoByDelegacion(contacto)
      newContacto && this.notificarNuevoContacto(newContacto)
      // if (newContacto) {
      //   this.contactos.push(contacto)
      // }
      let data = {
        id_entidad: this.id_entidad,
        type: this.type === 'emergencias' ? true : false 
      }

      // Listar nuevamente datos de contactos de las delegaciones, by entidad
      await this.getContactosByEntidad (data)
      await this.fetchEntidades()
      await this.fetchListaEntidades()

    },

    async acceptEditContacto (contacto) {
      this.showDialog = false

      // Editar contacto
      await this.editContactoByDelegacion(contacto)

      let data = {
        id_entidad: this.id_entidad,
        type: this.type === 'emergencias' ? true : false 
      }

      // Listar nuevamente datos de contactos de las delegaciones, by entidad
      await this.getContactosByEntidad (data)
      await this.fetchEntidades()
      await this.fetchListaEntidades()
    },

    async acceptDeleteContacto (contacto) {
      let msg = contacto.nombre ? 'Eliminando el contacto ' + contacto.nombre : 'Eliminando el contacto'
      this.$root.$confirmDialog.open('¿Está seguro?', msg).then((result) => {
        if (result) {
          this.$store.dispatch('contacto/deleteContactoById', contacto).then(() => {
            if (result) {
              this.notificarContactoBorrado(contacto)
              let data = {
                id_entidad: this.id_entidad,
                type: this.type === 'emergencias' ? true : false 
              }

              // Listar nuevamente datos de contactos de las delegaciones, by entidad
              this.getContactosByEntidad (data)
              this.fetchEntidades()
              this.fetchListaEntidades()
            }
          })
        }
      })
    },

    notificarContactoBorrado (contacto) {
      this.$notify({
        title: 'Contacto borrado',
        text: `Se ha borrado el contacto ${contacto.nombre}`
      })
    },

    notificarNuevoContacto (contacto) {
      this.$notify({
        title: 'Contacto creado',
        text: contacto.nombre ? `Se ha creado el contacto ${contacto.nombre}` : 'Se ha creado el contacto'
      })
    },

    userIsSameEntity () {
      return this.$store.getters['usuario/permisosEntidad'](this.id_entidad)
    },

    searchContactos () {

      function atributoValido (atributo) {
        let atributosNoValidos = ['borrado', 'fechastamp', 'otros_medios', 'titular',
          'emergencia', 'usuariostamp', 'fijo', 'id_nivel_contacto', 'id_contacto', 
          'id_telefono_contacto', 'id_delegacion', 'is_default', 'id_entidad', 
          'id_distrito']

        let encontrado = atributosNoValidos.find(x => x === atributo)
        return encontrado ? false : true
      }

      // Filtro de busqueda para todos los contactos
      let searchText = this.search ? this.search : ''

      this.contactosFilter = this.contactos.filter(function (contactos) {
        for (var attrContacto in contactos) {
          // Usuario
          if (attrContacto === 'Usuario') {
            for (var attrUsuario in contactos[attrContacto]) {
              if (atributoValido(attrUsuario)) {
                let atributo = contactos[attrContacto]['nombre'] + ' ' + contactos[attrContacto]['apellidos']

                if (StringHelper.compareString(searchText, atributo)) return contactos[attrContacto]
              }
            }
          } else if (attrContacto === 'Nivel_contacto') {
            // Nivel_contacto
            for (var attrNivelContacto in contactos[attrContacto]) {
              // Delegacion
              if (attrNivelContacto === 'Delegacion') {
                for (var attrDelegacion in contactos[attrContacto][attrNivelContacto]) {
                  //Distrito
                  if (attrDelegacion === 'Distrito') {
                    for(var attrDistrito in contactos[attrContacto][attrNivelContacto][attrDelegacion]) {
                      if (atributoValido(attrDistrito)) {
                        let atributo = contactos[attrContacto][attrNivelContacto][attrDelegacion][attrDistrito].toString() // cp es int

                        if (StringHelper.compareString(searchText, atributo)) return contactos[attrContacto][attrNivelContacto][attrDelegacion]
                      }
                    }
                  }

                  // Delegacion
                  if (atributoValido(attrDelegacion)) {
                    let atributo = contactos[attrContacto][attrNivelContacto][attrDelegacion].toString() // cp es int

                    if (StringHelper.compareString(searchText, atributo)) return contactos[attrContacto][attrNivelContacto]
                  }
                  
                }
              }

              // Atributos nivel contacto
              if (atributoValido(attrNivelContacto)) {
                let atributo = contactos[attrContacto][attrNivelContacto].toString()

                if (StringHelper.compareString(searchText, atributo)) return contactos[attrContacto]
              }
            }
          } else if (attrContacto === 'Telefono_contactos') {
            // Telefono_contactos
            for (var telefono in contactos[attrContacto]) {
              // Telefono
              for (var attrTelefono in contactos[attrContacto][telefono]) {
                if (atributoValido(attrTelefono)) {
                  let atributo = contactos[attrContacto][telefono][attrTelefono].toString() // numero es int

                  if (StringHelper.compareString(searchText, atributo)) return contactos[attrContacto][telefono]
                }
              }
            }
          } else if (attrContacto === 'Email_contactos') {
            // Email_contactos
            for (var email in contactos[attrContacto]) {
              // Email
              for (var attrEmail in contactos[attrContacto][email]) {
                if (atributoValido(attrEmail)) {
                  let atributo = contactos[attrContacto][email][attrEmail]

                  if (StringHelper.compareString(searchText, atributo)) return contactos[attrContacto][email]
                }
              }
            }
          } else {
            if (contactos[attrContacto]) {
              if (atributoValido(attrContacto)) {
                let atributo = contactos[attrContacto]

                if (StringHelper.compareString(searchText, atributo)) return contactos
              }
            }
          }
        }
      })
    }
  },

  created () {

    // Mostrar acciones si currentUsuario rol admin o rol mod_contactos
    if (this.$store.getters['usuario/rolesUsuario'](['MOD_CONTACTOS'])) {
      // Acciones contactos
      this.headersContactos.push({ text: 'Acciones', value: 'ACCIONES', align: 'center', width: '140px', sortable: false})
    }
  }
}
</script>

<style lang="scss" scoped>
/* Estilos para que la tabla tenga un max-height y pueda crecer */
.table {
  width: 100vw;
  max-height: calc(100vh - 128px)
}
.v-data-table {
  overflow: auto;
}
.v-data-table >>> .v-data-table__wrapper {
  /* Header fijo */
  overflow: unset;
}
/* .v-data-table >>> .v-data-footer {
    position: fixed;
    bottom: 0;
    width: 100vw;
    background: white;
  } */

/* Hover transparente para todas las filas */
::v-deep tbody {
          tr:hover {
            background-color: transparent !important;
          }
        }
/* Hover en color gris para las filas impares */
::v-deep tbody {
  tr:hover:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05) !important;
  }
}
/* Color gris fila data table impar */
::v-deep tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}

/* Sticky column data-table - Delegacion */
::v-deep thead tr th:nth-of-type(1) {
  position: sticky;
  left: 0;
}

::v-deep tbody tr:nth-of-type(odd) td:nth-of-type(1) {
  background: whitesmoke;
  position: sticky;
  left: 0;
}

::v-deep tbody tr td:nth-of-type(1) {
  background: white;
  position: sticky;
  left: 0;
}

/* Sticky column data-table - Acciones */
::v-deep thead tr th:nth-of-type(15) {
  position: sticky;
  right: 0;
}

::v-deep tbody tr:nth-of-type(odd) td:nth-of-type(15) {
  background: whitesmoke;
  position: sticky;
  right: 0;
}

::v-deep tbody tr td:nth-of-type(15) {
  background: white;
  position: sticky;
  right: 0;
}

/* Tamnaño inputs y select */
.inputSelectMobile {
  // margin-top: -20px;
  margin-bottom: 0px;
}
</style>