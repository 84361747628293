import api from '@/api'
import constants from '@/helpers/constants'

let Q = require('q')

const state = {
  tiposActualizacion: null
}

const getters = {
  tiposActualizacion: (state) => state.tiposActualizacion
}

const mutations = {
  SET_TIPOS_ACTUALIZACION (state, listaTiposActualizacion) {
    if (listaTiposActualizacion) {
      state.tiposActualizacion = listaTiposActualizacion
    }
  },

  ERROR_AUTORIZACION (state, data) {
    this.notify(data.title, data.text, 'error')
  },

  ERROR_RESPUESTA_SERVER (state, data) {
    this.notify(data.title, data.text, 'error')
  }
}

const actions = {
  async fetchTiposActualizacion ({ commit }) {
    let deferred = Q.defer()

    try {
      const response = await api.tipoActualizacion.getTiposActualizacion()
      if (response.status === 200) {
        commit('SET_TIPOS_ACTUALIZACION', response.data.body)

        deferred.resolve()
      }
    } catch (err) {
      if (err.response && (err.response.status !== 200 && err.response.status !== 500)) {

        commit('ERROR_AUTORIZACION', 
          {
            title: err.response.statusText, 
            text: constants.notificacionesPermisos.TipoActualizacion.getTiposActualizacion
          })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500]
        })
      }
      console.error(err)
      deferred.reject(err)
    }
    return deferred.promise
  }
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default module