var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":"","height":"100%"}},[_c('v-toolbar',{staticStyle:{"z-index":"2"},attrs:{"color":"primaryLight","dark":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Gestión de medios y recursos")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.comprobarPermisos(['MOD_MEDIOS'])),expression:"comprobarPermisos(['MOD_MEDIOS'])"}],attrs:{"fab":"","bottom":"","right":"","absolute":"","color":"primary"},on:{"click":_vm.showAddUnidadMedioDialog}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Añadir unidad medio")])])],1),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6","md":"3","sm":"3"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Búsqueda","outlined":"","dense":_vm.$vuetify.breakpoint.mobile,"clearable":"","hide-details":""},on:{"input":_vm.searchMediosRecursos},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"6","md":"3","sm":"3"}},[_c('v-select',{attrs:{"label":"Medio/Recurso","items":_vm.medios ? _vm.medios : [],"menu-props":{ bottom: true, offsetY: true },"item-text":"nombre","item-value":"nombre","return-object":"","outlined":"","dense":_vm.$vuetify.breakpoint.mobile,"hide-details":"","clearable":true},model:{value:(_vm.medioRecursoSelected),callback:function ($$v) {_vm.medioRecursoSelected=$$v},expression:"medioRecursoSelected"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"3"}},[_c('v-select',{class:{'inputSelectMobile': _vm.$vuetify.breakpoint.mobile},attrs:{"label":"Entidad","items":_vm.entidades ? _vm.entidades : [],"menu-props":{ bottom: true, offsetY: true },"item-text":"entidad_titular","item-value":"entidad_titular","return-object":"","outlined":"","dense":_vm.$vuetify.breakpoint.mobile,"hide-details":"","clearable":""},model:{value:(_vm.entidadSelected),callback:function ($$v) {_vm.entidadSelected=$$v},expression:"entidadSelected"}})],1)],1),_c('v-row',[_c('v-data-table',{class:{
            'table': !_vm.$vuetify.breakpoint.mobile,
            'tableMobile': _vm.$vuetify.breakpoint.mobile},attrs:{"headers":_vm.headers,"items":_vm.filterUnidadesMedios ? _vm.filterUnidadesMedios : _vm.unidadesMedios ? _vm.unidadesMedios : [],"height":"100%","loading":_vm.isLoading,"loading-text":"Cargando datos... por favor, espere","fixed-header":"","hide-default-footer":"","page":_vm.currentPage,"items-per-page":_vm.itemsPerPage},on:{"update:page":function($event){_vm.currentPage=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('p',[_vm._v("Datos no disponibles.")])]},proxy:true},{key:"item.Departamento",fn:function(ref){
            var item = ref.item;
return [(item.Departamento.nombre !== item.Departamento.Delegacion.nombre)?_c('span',[_vm._v(_vm._s(item.Departamento.nombre))]):_vm._e()]}},{key:"item.activacion_movilizacion",fn:function(ref){
            var item = ref.item;
return [(item.activacion_movilizacion === false)?_c('span',[_vm._v("No")]):(item.activacion_movilizacion && item.ruta_protocolo)?_c('span',[_vm._v(" Sí "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.getDocumento(item.id_unidad_medio)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"width":"40px","height":"40px","color":"primary"}},[_vm._v("mdi-file-document")])],1)]}}],null,true)},[_c('span',[_vm._v("Descargar documento")])])],1):_c('span',[_vm._v("Sí")])]}},{key:"item.fechastamp",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.fechastamp)))])]}},{key:"item.Usuario",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.Usuario.nombre + ' ' + item.Usuario.apellidos))])]}},{key:"item.ACCIONES",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.comprobarPermisos(['MOD_MEDIOS'])),expression:"comprobarPermisos(['MOD_MEDIOS'])"}],attrs:{"icon":"","disabled":!_vm.userIsSameEntity(item) && !_vm.comprobarPermisos([])},on:{"click":function($event){return _vm.showEditUnidadMedioDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"teal"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.comprobarPermisos(['MOD_MEDIOS'])),expression:"comprobarPermisos(['MOD_MEDIOS'])"}],attrs:{"icon":"","disabled":!_vm.userIsSameEntity(item) && !_vm.comprobarPermisos([])},on:{"click":function($event){return _vm.acceptDeleteMedioRecurso(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Borrar")])])]}}],null,true)})],1),_c('v-row',[(!_vm.$vuetify.breakpoint.mobile)?_c('v-col',{attrs:{"xs":"2","sm":"3","md":"2"}},[_c('v-row',[_c('div',{staticClass:"text-center align-center mt-6 pl-6 ml-6"},[_c('span',[_vm._v("Total "+_vm._s(_vm.totalRecords)+" registros")])])])],1):_vm._e(),_c('v-col',{attrs:{"xs":"1","sm":"4","md":"3"}},[_c('v-row',[_c('v-col',{staticClass:"textMostrar",attrs:{"sm":"4","md":"4"}},[_c('div',[_c('span',[_vm._v("Mostrar")])])]),_c('v-col',{attrs:{"xs":"4","sm":"5","md":"4"}},[_c('v-select',{class:{
                  'select': !_vm.$vuetify.breakpoint.mobile,
                  'selectMobile': _vm.$vuetify.breakpoint.mobile},attrs:{"dense":"","outlined":"","hide-details":"","items":_vm.perPageOptions},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"6"}},[_c('div',[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])],1)],1)],1),_c('vx-dialog-add-edit-medio',{attrs:{"show":_vm.showDialog,"isEdit":_vm.isEdit,"editedUnidadMedio":_vm.editUnidadMedio},on:{"cancelar":function($event){_vm.showDialog = false},"addUnidadMedio":_vm.acceptAddUnidadMedio,"editUnidadMedio":_vm.acceptEditUnidadMedio}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }