import { Emergencia } from '@/models/Emergencia'
import * as ArcGIS from  '@/helpers/ArcGIS'
import { ServerHelper } from './ServerHelper'
import constants from '../constants'
import Vue from 'vue'

export class EmergenciaArcGISHelper {

  static EMERGENCIA_LAYER_TITLE = constants.EMERGENCIA_LAYER_TITLE
  
  static showInactiveLocal = false

  static getLayerEmergencia () {
    return ArcGIS.getLayerByTitle(this.EMERGENCIA_LAYER_TITLE)
  }

  static showLayer (show){
    const layer = this.getLayerEmergencia()
    if (layer){
      ArcGIS.changeVisibilityWebMap(layer.id, show)
    }
    
  }
  
  static async filterLayerEmergencias (showInactive) {
    // Guardar valor recibido por parámetro para controlar showInactive en EmergenciaArcgisHelper
    this.showInactiveLocal = showInactive

    const layer = this.getLayerEmergencia()
    const mapView = ArcGIS.getMapView()

    if (layer && mapView) {
      const estadoEmergencia = this.mostrarEmergenciasInactivas(showInactive)
      mapView.whenLayerView(layer).then(function (featureLayerView) {
        featureLayerView.filter = {
          where: `Activa='${estadoEmergencia}'`
        }
      })
      this.refreshLayer()
    }
  }

  static refreshLayer () {
    const layer = this.getLayerEmergencia()
    layer?.refresh()
  }
  
  static mostrarEmergenciasInactivas (estadoInactiva) {
    return estadoInactiva ? constants.EstadoEmergencia.noActiva : constants.EstadoEmergencia.activa
  }

  /**
   * @param {Emergencia} emergencia 
   */
  static async addEmergencia (emergencia){
    const urlToAdd = `${this.getLayerEmergencia().url}/0/addFeatures`
    const coords = ArcGIS.convertirLatLonToETRS89(
      emergencia.latitude, 
      emergencia.longitude
    )
    const featureToAdd = {
      attributes: {
        Nombre: emergencia.nombre,
        Situacion: this.#getEmergenciaSituacionOperativa(emergencia),
        ID_emergencias: emergencia.id,
        activa: emergencia.activa ? constants.EstadoEmergencia.activa : constants.EstadoEmergencia.noActiva
      },
      geometry: {
        x: Math.trunc(coords[0]) ,
        y: Math.trunc(coords[1]) ,
        spatialReference: { wkid: 25830 }
      }
    }

    try {
      const result = await ServerHelper.sendData(urlToAdd, featureToAdd)
      this.refreshLayer()
      this.filterLayerEmergencias(this.showInactiveLocal)
    } catch (error){
      if (error.response.status !== 200 && error.response.status !== 500){
        Vue.notify({
          title: 'Error al añadir datos a la capa de emergencias',
          text: error.response.statusText + '. ' + constants.notificacionesPermisos.Emergencias.sendData,
          type: 'error'
        })
      } else if (error.request) {
        Vue.notify({
          title: 'Error al añadir datos a la capa de emergencias',
          text: constants.notificacionesPermisos.Error[500],
          type: 'error'
        })
      }
      throw error
    }
  }

  static async updateEmergencia (emergencia){
    await this.deleteEmergencia(emergencia.id)
    await this.addEmergencia(emergencia)
  }

  static async deleteEmergencia (idEmergencia){
    const urlToDelete = `${this.getLayerEmergencia().url}/0/deleteFeatures`
    const condition = {
      where: `ID_emergencias = '${idEmergencia}'`,
      geometryType: 'esriGeometryPoint',
      returnDeleteResults: true,
      rollbackOnFailure: true,
      f: 'pjson'
    }
    try {
      const result = await ServerHelper.deletePoints
      (urlToDelete, condition)
      this.refreshLayer()
    } catch (err) {
      if (err.response && (err.response.status !== 200 && err.response.status !== 500)) {
        Vue.notify({
          title: 'Error al eliminar un punto de la capa de emergencias',
          text: err.response.statusText + '. ' + constants.notificacionesPermisos.Emergencias.deletePoints,
          type: 'error'
        })
      } else if (err.request) {
        Vue.notify({
          title: 'Error al eliminar un punto de la capa de emergencias',
          text: constants.notificacionesPermisos.Error[500],
          type: 'error'
        })
      }
    }
  }

  /**
   * @param {Emergencia} emergencia 
   */
  static #getEmergenciaSituacionOperativa (emergencia){
    switch (emergencia.SituacionOperativa.codigo) {
    case 0:
      return 'SOP 0'
    case 1:
      return 'SOP 1'
    case 2:
      return 'SOP 2'
    case 3:
      return 'Plan de recuperación'
    case 4:
      return 'SOP 3'
    }
  }
} 