import { render, staticRenderFns } from "./RecuperarCredenciales.vue?vue&type=template&id=33ebda8a&scoped=true&"
import script from "./RecuperarCredenciales.vue?vue&type=script&lang=js&"
export * from "./RecuperarCredenciales.vue?vue&type=script&lang=js&"
import style0 from "./RecuperarCredenciales.vue?vue&type=style&index=0&id=33ebda8a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33ebda8a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VToolbar,VToolbarTitle})
