import api from '@/api'
import { FileHelper } from '@/helpers/FileHelper'
import Vue from 'vue'
import constants from '@/helpers/constants'

let Q = require('q')

const state = {
  documentos: [],
  cachedDocumentos: []
}

const getters = {
  documentos: (state) => {
    return state.documentos
  },

  getFileByDocumentoId: (state) => async (data) => {
    let fileSave
    try {
      const response = await api.documento.getFileByDocumentoId(data)
      if (response.status === 200) {
        fileSave = {
          id_documento: data.id_documento,
          nombre: response.data.body.nombre,
          ruta: response.data.body.fileBase64
        }
      }
      return fileSave
    } catch (err) {
      if (err.response && (err.response.status !== 200 && err.response.status !== 500)) {
        Vue.notify({
          title: err.response.statusText,
          text: constants.notificacionesPermisos.RepositorioDocumental.getFileByDocumento,
          type: 'error'
        })
      } else if (err.request) {
        Vue.notify({
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
          type: 'error'
        })
      }
    }
    // return state.documentoEntidad
  },

  getDocumentoLocalById: (state) => async (data) => {
    return await state.cachedDocumentos.find(x => x.id_documento === data.id_documento)
  },

  getSizeCacheDocuments: (state) => {
    return state.cachedDocumentos.length
  }
}

const mutations = {
  SET_DOCUMENTOS (state, data) {
    state.documentos = data
  },

  ADD_DOCUMENTO (state, data) {
    if (data) {
      this.notify('Documento añadido', `Se ha añadido el documento ${data.nombre}`)
    }
  },

  UPDATE_DOCUMENTO (state, data) {
    if (data) {
      this.notify('Documento actualizado', `Se ha actualizado el documento ${data.nombre}`)
    }
  },
  ADD_CACHED_DOCUMENTO_BASE64 (state, data) {
    if (data) {
      const extension = FileHelper.getExtensionNombreArchivo(data.nombre)
      const tipoArchivo = FileHelper.getFileType(extension)
      let doc = {
        id_documento: data.id_documento,
        ruta: 'data:'+data.mimeType+';base64,'+data.ruta,
        type: tipoArchivo
      }
      state.cachedDocumentos.push(doc)
    }
  },
  DELETE_FIRST_ITEM_CACHED_DOCUMENTO_BASE64 (state, data) {
    if (data) {
      state.cachedDocumentos.splice(0, 1)
    }
  },

  ERROR_AUTORIZACION (state, data) {
    this.notify(data.title, data.text, 'error')
  },

  ERROR_RESPUESTA_SERVER (state, data) {
    this.notify(data.title, data.text, 'error')
  }
}

const actions = {
  async fetchDocumentos ({ commit }) {
    let deferred = Q.defer()

    try {
      const response = await api.documento.getAllDocumentos()
      if (response.status === 200) {
        commit('SET_DOCUMENTOS', response.data.body)
      }
      deferred.resolve()
    } catch (err) {
      if (err.response && (err.response.status !== 200 && err.response.status !== 500)) {

        commit('ERROR_AUTORIZACION', 
          {
            title: err.response.statusText, 
            text: constants.notificacionesPermisos.RepositorioDocumental.getDocumentos
          })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500]
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async addDocumento ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.documento.addDocumento(data)
      if (response.status === 200) {
        commit('ADD_DOCUMENTO', response.data.body)
      }
      deferred.resolve()
    } catch (err) {
      if (err.response && (err.response.status !== 200 && err.response.status !== 500)) {

        commit('ERROR_AUTORIZACION', 
          {
            title: err.response.statusText, 
            text: constants.notificacionesPermisos.RepositorioDocumental.nuevoDocumento
          })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500]
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async updateDocumento ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.documento.updateDocumento(data)
      if (response.status === 200) {
        commit('UPDATE_DOCUMENTO', data)
      }

      deferred.resolve()
    } catch (err) {
      if (err.response && (err.response.status !== 200 && err.response.status !== 500)) {

        commit('ERROR_AUTORIZACION', 
          {
            title: err.response.statusText, 
            text: constants.notificacionesPermisos.RepositorioDocumental.editarDocumento
          })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500]
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async validateInvalidateDocumentoById ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.documento.validateInvalidateDocumento(data)
      if (response.status === 200) {
        return true
      }
  
      deferred.resolve()
    } catch (err) {
      if (err.response && (err.response.status !== 200 && err.response.status !== 500)) {

        commit('ERROR_AUTORIZACION', 
          {
            title: err.response.statusText, 
            text: constants.notificacionesPermisos.RepositorioDocumental.validarInvalidar
          })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500]
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async addCachedDocumentoBase64 ({ commit }, data) {
    let deferred = Q.defer()

    try {
      if (data) {
        commit('ADD_CACHED_DOCUMENTO_BASE64', data)
      }
      deferred.resolve(true)
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async deleteFirstCachedDocumentoBase64 ({ commit }, data) {
    let deferred = Q.defer()

    try {
      if (data) {
        commit('DELETE_FIRST_ITEM_CACHED_DOCUMENTO_BASE64', data)
      }
      deferred.resolve(true)
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  }
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default module

