import request from '../request'

export default {
  getAllAccionSituacionTipoEmergencia: function () {
    return request.get('accionSituacionTipoEmergencia')
  },

  assignUnassignAccionSituacionTipoEmergencia: function (data) {
    return request.post('accionSituacionTipoEmergencia', data)
  },

  getAccionesByTipoEmergenciaIdSituacionOperativaId (data) {
    return request.get('accionSituacionTipoEmergencia/accionesByTipoEmerSitOper', { params: { tipoEmergenciaId: data.tipoEmergenciaId, situacionOperativaId: data.situacionOperativaId } })
  }
}