var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":"","height":"100%"}},[_c('v-toolbar',{attrs:{"color":"primaryLight","dark":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Gestión de cargos")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.comprobarPermisos(['MOD_USUARIOS'])),expression:"comprobarPermisos(['MOD_USUARIOS'])"}],attrs:{"fab":"","bottom":"","right":"","absolute":"","color":"primary"},on:{"click":_vm.showAddCargoDialog}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Añadir cargo")])])],1),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"xs":"3","sm":"3"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Búsqueda","dense":_vm.$vuetify.breakpoint.mobile,"outlined":"","clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-data-table',{class:{
            'table': !_vm.$vuetify.breakpoint.mobile, 
            'tableMobile': _vm.$vuetify.breakpoint.mobile},attrs:{"headers":_vm.headers,"items":_vm.cargos,"height":"100%","search":_vm.search,"loading":_vm.isLoading,"loading-text":"Cargando datos... por favor, espere","fixed-header":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('p',[_vm._v("Datos no disponibles.")])]},proxy:true},{key:"item.fechastamp",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.fechastamp)))])]}},{key:"item.UsuarioModificador",fn:function(ref){
            var item = ref.item;
return [(item.UsuarioModificador)?_c('span',[_vm._v(" "+_vm._s(item.UsuarioModificador.nombre + ' ' + item.UsuarioModificador.apellidos)+" ")]):_vm._e()]}},{key:"item.ACCIONES",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.comprobarPermisos(['MOD_USUARIOS'])),expression:"comprobarPermisos(['MOD_USUARIOS'])"}],attrs:{"icon":""},on:{"click":function($event){return _vm.showEditCargoDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"teal"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.comprobarPermisos(['MOD_USUARIOS'])),expression:"comprobarPermisos(['MOD_USUARIOS'])"}],attrs:{"icon":""},on:{"click":function($event){return _vm.acceptDeleteCargo(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Borrar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.comprobarPermisos(['MOD_USUARIOS', 'VER_USUARIOS'])),expression:"comprobarPermisos(['MOD_USUARIOS', 'VER_USUARIOS'])"}],attrs:{"icon":"","disabled":item.Usuarios && !item.Usuarios.length},on:{"click":function($event){return _vm.showListCargoUsuariosDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-list-box")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver usuarios asociados al cargo")])])]}}],null,true)})],1)],1)],1),_c('vx-dialog-add-edit-cargo',{attrs:{"show":_vm.showDialog,"isEdit":_vm.isEdit,"editedCargo":_vm.editCargo},on:{"cancelar":function($event){_vm.showDialog = false},"addCargo":_vm.acceptAddCargo,"updateCargo":_vm.acceptEditCargo}}),_c('vx-dialog-list-cargo-usuarios',{attrs:{"showList":_vm.showListDialog,"listUsuarios":_vm.dataUsuarios,"cargo":_vm.nombreCargo},on:{"cancelar":function($event){_vm.showListDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }