import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import es from 'vuetify/lib/locale/es'
// import en from 'vuetify/lib/locale/en'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { es },
    current: 'es'
  },
  breakpoint: {
    mobileBreakpoint: 'xs'
  },
  theme: {
    themes: {
      light: {
        primary: '#003DF6',
        primaryLight: '#272727',
        secondary: '#272727',
        accent: '#8c9eff',
        error: '#b71c1c',
        lightGray: '#cccccc'
      }
    },
    options: {
      customProperties: true,
      variations: false
    }
  }
})
