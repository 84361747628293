import api from '@/api'

let Q = require('q')

const state = {}

const getters = {
  getNivelContactoDelegacionByEntidad: (state) => async (data) => {
    // DATA: {id_entidad, type (tipo de contacto)}
    const response = await api.nivelContacto.getNivelesContactoDelegacionByEntidad(data)
    if (response.status === 200) {
      return response.data.body
    }
  }
}

const mutations = {}

const actions = {}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
  
export default module