import api from '@/api'

let Q = require('q')

const state = {
  loading: false,

  refreshInterval: null
}

const getters = {
  loading: (state) => state.loading
}

const mutations = {
  SET_LOADING (state, isLoading) {
    state.loading = isLoading
  },

  SET_REFRESH_INTERVAL (state, func) {
    state.refreshInterval = setInterval(func, 25000)
  }
}

const actions = {
  init ({ commit, dispatch, rootGetters }) {
    // if (rootGetters['auth/accessToken']) {
    commit('SET_LOADING', true)

    let promises = []

    // promises.push(dispatch('usuario/userLoggedIn', null, { root: true }))
    // promises.push(dispatch('map/fetchParamsComunidad', null, { root: true }))
    /* promises.push(dispatch('incendio/fetchAllIncendios', null, { root: true }))
    promises.push(dispatch('incendio/fetchIncendios', null, { root: true }))
    promises.push(dispatch('incendio/fetchTiposIncendioEstado', null, { root: true }))
    promises.push(dispatch('incendio/fetchTiposDeteccion', null, { root: true }))

    promises.push(dispatch('medio/fetchMedios', null, { root: true }))
    promises.push(dispatch('medio/fetchTiposMedio', null, { root: true }))
    promises.push(dispatch('medio/fetchBases', null, { root: true }))
    promises.push(dispatch('medio/fetchZonasMedio', null, { root: true }))
    promises.push(dispatch('medio/fetchEmergencias', null, { root: true }))

    promises.push(dispatch('parametro/fetchParametros', null, { root: true })) */

    Q.all(promises).then(() => {

      // this._vm.$socket.connect()
      // dispatch('usuario/userLoggedIn', null, { root: true })

      // INIT INTERVAL REFRESH
      //commit('SET_REFRESH_INTERVAL', () => {
      // let pendingRequests = api.request.pendingRequests

      //if (pendingRequests === 0) { // Solo lanzar el interval si no hay ninguna peticion en proceso
      /* dispatch('incendio/fetchAllIncendios', null, { root: true })
          dispatch('incendio/fetchIncendios', null, { root: true })
          dispatch('medio/fetchMedios', null, { root: true })
          dispatch('parametro/fetchParametros', null, { root: true }) */
      //}
      //})
    }).catch((error) => {
      console.error(`Error init data ${error}`)
      this.notify('Error', 'Error al realizar la carga inicial de datos' + error, 'error')
    }).finally(() => {
      commit('SET_LOADING', false)
    })
  },

  setLoading ({ commit }, isLoading) {
    commit('SET_LOADING', isLoading)
  }
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default module
