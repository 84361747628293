import request from '../request'

export default {
  getContactosByEntidad: function (data) {
    return request.post('contacto/contactosByEntidad', data)
  },

  addContactoByDelegacion: function (data) {
    return request.post('contacto', data)
  },

  editContactoByDelegacion: function (data) {
    return request.put(`contacto/${data.id_contacto}`, data)
  },

  deleteContactoById: function (data) {
    return request.delete(`contacto/${data.id_contacto}`, { data: data })
  }
}