<template>
  <div class="chats-content">
    <vx-edit-chat-name 
      v-model="isShowEditChat"
      :chatId="idChatToEdit"
      @cancelar="isShowEditChat = false"
    />
    <div class="chats">
      <v-list>
        <v-list-item-group
          value="chatActiveIndex"
          active-class=""
        >
          <v-list-item 
            @click.stop="openChat(chat)"
            v-for="chat in chats" 
            :key="chat.id"
          >
            <v-list-item-content>
              <v-list-item-title>{{ chat.nombre }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    @click="editChat(chat.id)"
                    icon
                    v-bind="attrs" 
                    v-on="on"
                    v-show="userCanEditEmergencias"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Cambiar nombre</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </div>
  </div>
</template>
<script>
import DialogEditChatName from './DialogEditChatName.vue'


export default {
  
  components: {
    'vx-edit-chat-name': DialogEditChatName
  },

  data: () => ({
    isShowEditChat: false,
    idChatToEdit: null
  }),

  async mounted (){
    await this.$store.dispatch('entidad/fetchEntidades')
  },

  computed: {

    chats () {
      return this.$store.state.chat.chats
    },

    chatActiveIndex () {
      return this.$store.getters['chat/chatActiveIndex']
    },

    userCanEditEmergencias (){
      return this.$store.getters['usuario/rolesUsuario'](['MOD_EMERGENCIAS'])
    },
  },

  watch: {
  },

  methods: {
    openChat (chat){
      this.$store.dispatch('chat/SET_CHAT_ACTIVO', chat)
    },
    editChat (chatId){
      this.idChatToEdit = chatId
      this.isShowEditChat = true
    }
  }

}
</script>

<style scoped lang="scss">
  .chats-content {
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    .chats {
      flex-grow: 1;
      height: 100%;
      overflow: auto;
      ::v-deep .v-list-item__title {
        text-overflow: inherit;
        white-space: normal;
      }
    }
    .buttons {
      padding: 8px;
      display: flex;
      gap: 6px;
      justify-content: space-between;
      width: 100%;
    }
  }
</style>