<template>
  <v-dialog v-model="show" persistent width="450px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Crear grupo</v-toolbar-title>
      </v-toolbar>

      <v-card-text class="dialog-content">
        <v-form ref="form">
          <v-container>
            <v-row class="mt-0">
              <v-col xs="12" sm="12" md="12">
                <v-text-field
                  type="text" 
                  label="Nombre*"
                  placeholder="Nombre del grupo"
                  v-model="nombre"
                  counter maxlength="250"
                  dense
                />
              </v-col>
            </v-row>
          </v-container>
          <div class="entidades">
            <vx-entidades-select-list
              :entidades="entidades"
              v-model="entidadesSelected"
            />
          </div>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn text color="green" @click="aceptar">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EntidadesSelectList from './EntidadesSelectList.vue'
import {EmergenciaRequest} from '@/api/emergencia'


export default {

  components: {
    'vx-entidades-select-list': EntidadesSelectList
  },

  props: {
    show: Boolean
  },

  data: () => ({
    entidadesSelected: [],
    nombre: null,
  }),

  computed: {
    entidades (){
      return this.$store.state.entidad.entidades
    },
    emergenciaSelected (){
      return this.$store.state.emergencia.emergenciaSelected
    },
    chats () {
      return this.$store.state.chat.chats
    },
  },

  methods: {

    chatWithSameNameExist (nombre){
      return this.chats.some((chat) => chat.nombre === nombre)
    },

    cancelar () {
      this.$refs.form.reset()
      this.$emit('cancelar')
    },

    async aceptar () {
      const addedEntitysIds = this.getEntidadesIdSelectedByIndex(this.entidadesSelected)
      if (this.nombre && addedEntitysIds && addedEntitysIds.length > 0){
        if (!this.chatWithSameNameExist(this.nombre)){
          const newChat = await EmergenciaRequest.createChat(this.nombre, this.emergenciaSelected.id, addedEntitysIds)
          if (newChat){
            this.$notify({
              title: 'Grupo creado',
              text: `Se ha creado el grupo ${newChat.nombre}`
            })
            this.$refs.form.reset()
            this.$store.dispatch('chat/FETCH_CHATS', this.emergenciaSelected.id)
            this.cancelar()
          } else {
            this.$notify({
              title: 'Error al crear grupo',
              text: 'Ha ocurrido un error desconocido al crear el grupo',
              type: 'error'
            })
          }
        } else {
          this.$notify({
            title: 'Nombre del grupo ya usado',
            text: `Ya existe un grupo con el nombre ${this.nombre}`,
            type: 'warning'
          })
        }
      } else {
        this.$notify({
          title: 'Se necesitan mas datos',
          text: 'Se requiere un nombre y al menos una entidad selecionada para crear un grupo',
          type: 'warning'
        })
      }
    },

    getEntidadesIdSelectedByIndex (selectedIndex){
      const result = selectedIndex.map(index => this.entidades.at(index).id_entidad)
      return result
    },

  },

  watch: {
    entidadesSelected (newValue){
    }
  }

}
</script>

<style scoped lang="scss">
.inputText {
  margin-top: -15px;
}
.entidades {
  height: 20rem;
}
</style>