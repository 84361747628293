<template>
  <v-card tile height="100%">
    <v-toolbar color="primaryLight" dark dense style="z-index: 2">
      <v-toolbar-title>Gestión de medios y recursos</v-toolbar-title>

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            fab
            bottom
            right
            absolute
            color="primary"
            v-show="comprobarPermisos(['MOD_MEDIOS'])"
            @click="showAddUnidadMedioDialog"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Añadir unidad medio</span>
      </v-tooltip>
    </v-toolbar>

    <v-card-text>
      <v-container fluid>
        <v-row align="center">
          <!-- <v-btn fab small color="red">
            <v-icon color="white">mdi-file-pdf-box</v-icon>
          </v-btn> -->

          <v-col cols="6" md="3" sm="3">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Búsqueda"
              outlined
              :dense="$vuetify.breakpoint.mobile"
              clearable
              hide-details
              @input="searchMediosRecursos"
            />
          </v-col>

          <v-col cols="6" md="3" sm="3">
            <v-select
              v-model="medioRecursoSelected"
              label="Medio/Recurso" 
              :items="medios ? medios : []"
              :menu-props="{ bottom: true, offsetY: true }"
              item-text="nombre"
              item-value="nombre"
              return-object
              outlined
              :dense="$vuetify.breakpoint.mobile"
              hide-details
              :clearable="true"
            />
          </v-col>

          <v-col cols="12" md="3" sm="3">
            <v-select
              :class="{'inputSelectMobile': $vuetify.breakpoint.mobile}"
              v-model="entidadSelected"
              label="Entidad"
              :items="entidades ? entidades : []"
              :menu-props="{ bottom: true, offsetY: true }"
              item-text="entidad_titular"
              item-value="entidad_titular"
              return-object
              outlined
              :dense="$vuetify.breakpoint.mobile"
              hide-details
              clearable
            />
          </v-col>
        </v-row>

        <v-row>
          <v-data-table
            :class="{
              'table': !$vuetify.breakpoint.mobile,
              'tableMobile': $vuetify.breakpoint.mobile}"
            :headers="headers"
            :items="filterUnidadesMedios ? filterUnidadesMedios : unidadesMedios ? unidadesMedios : []"
            height="100%"
            :loading="isLoading"
            loading-text="Cargando datos... por favor, espere"
            fixed-header
            hide-default-footer
            :page.sync="currentPage"
            :items-per-page="itemsPerPage"
          >
            <!-- :items-per-page="30" -->
            <template #no-data>
              <p>Datos no disponibles.</p>
            </template>

            <!-- NOMBRE DEPARTAMENTO -->
            <template #[`item.Departamento`]="{ item }">
              <span v-if="item.Departamento.nombre !== item.Departamento.Delegacion.nombre">{{ item.Departamento.nombre }}</span>
            </template>

            <!-- ACTIVACION MOVILIZACION -->
            <template #[`item.activacion_movilizacion`]="{ item }">
              <span v-if="item.activacion_movilizacion === false">No</span>
              <span v-else-if="item.activacion_movilizacion && item.ruta_protocolo">
                Sí
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="getDocumento(item.id_unidad_medio)"
                    >
                      <v-icon width="40px" height="40px" color="primary">mdi-file-document</v-icon>
                    </v-btn>
                  </template>
                  <span>Descargar documento</span>
                </v-tooltip>
              </span>
              <span v-else>Sí</span>
              <!-- TODO: pendiente descarga de documentacion -->
            </template>

            <!-- FECHASTAMP -->
            <template #[`item.fechastamp`]="{ item }">
              <span>{{ item.fechastamp | formatDate }}</span>
            </template>

            <!-- USUARIO -->
            <template #[`item.Usuario`]="{ item }">
              <span>{{ item.Usuario.nombre + ' ' + item.Usuario.apellidos }}</span>
            </template>

            <!-- ACCIONES -->
            <template #[`item.ACCIONES`]="{ item }">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    :disabled="!userIsSameEntity(item) && !comprobarPermisos([])"
                    v-show="comprobarPermisos(['MOD_MEDIOS'])"
                    @click="showEditUnidadMedioDialog(item)"
                  >
                    <v-icon color="teal">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    :disabled="!userIsSameEntity(item) && !comprobarPermisos([])"
                    v-show="comprobarPermisos(['MOD_MEDIOS'])"
                    @click="acceptDeleteMedioRecurso(item)"
                  >
                    <v-icon color="error">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Borrar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-row>

        <!-- SECCIÓN PAGINACIÓN -->
        <v-row>
          <v-col xs="2" sm="3" md="2" v-if="!$vuetify.breakpoint.mobile">
            <v-row>
              <div class="text-center align-center mt-6 pl-6 ml-6">
                <span>Total {{ totalRecords }} registros</span>
              </div>
            </v-row>
          </v-col>
          <v-col xs="1" sm="4" md="3">
            <v-row>
              <v-col sm="4" md="4" class="textMostrar">
                <div>
                  <span>Mostrar</span>
                </div>
              </v-col>
              <v-col xs="4" sm="5" md="4">
                <v-select
                  dense
                  outlined
                  hide-details
                  v-model="itemsPerPage"
                  :items="perPageOptions"
                  :class="{
                    'select': !$vuetify.breakpoint.mobile,
                    'selectMobile': $vuetify.breakpoint.mobile}"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="4" md="6">
            <div>
              <v-pagination
                v-model="currentPage"
                :length="pageCount"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <vx-dialog-add-edit-medio
      :show="showDialog"
      :isEdit="isEdit"
      :editedUnidadMedio="editUnidadMedio"
      @cancelar="showDialog = false"
      @addUnidadMedio="acceptAddUnidadMedio"
      @editUnidadMedio="acceptEditUnidadMedio"
    />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { StringHelper } from '@/helpers/StringHelper'
import DialogAddEditMedioRecurso from './DialogAddEditMedioRecurso.vue'

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
pdfMake.vfs = pdfFonts.pdfMake.vfs

export default {
  components: {
    'vx-dialog-add-edit-medio': DialogAddEditMedioRecurso
  },
  data: () => ({

    showDialog: false,
    isLoading: false,

    search: '',
    filterUnidadesMedios: null,

    medioRecursoSelected: null,
    entidadSelected: null,

    editUnidadMedio: {},
    isEdit: false,

    currentPage: 1,
    itemsPerPage: 15,
    perPageOptions: [15, 50, 75, 100],
    
  }),

  watch: {},
  computed: {
    unidadesMedios () {
      return this.$store.getters['unidadMedio/unidadesMedios']
    },

    ...mapGetters('usuario', [
      'currentUsuario', 'rolesUsuario'
    ]),

    entidades () {
      return this.$store.getters['entidad/listaEntidades']
    },

    medios () {
      return this.$store.getters['medio/medios']
    },

    headers () {
      return [
        // { text: 'Código Actividad', value: 'Medio.Actividad_medio.codigo', align: 'left', sortable: true, width: '120px' },
        { text: 'Actividad', value: 'Medio.Actividad_medio.nombre', align: 'left', sortable: true, width: '140px' },
        // { text: 'Código medio/recurso', value: 'Medio.codigo', align: 'left', sortable: true, width: '140px' },
        { text: 'Medios/Recursos', value: 'Medio.nombre', align: 'left', sortable: true, width: '150px', filter: this.filterMediosRecursos },
        { text: 'Unidades', value: 'numero_unidades', align: 'left', sortable: true, width: '110px' },
        { text: 'Tipo entidad', value: 'Departamento.Delegacion.Entidad.Tipo_entidad.nombre', align: 'left', sortable: true, width: '130px' },
        { text: 'Entidad', value: 'Departamento.Delegacion.Entidad.entidad_titular', align: 'left', sortable: true, width: '140px', filter: this.filterEntidad },
        { text: 'Delegación', value: 'Departamento.Delegacion.nombre', align: 'left', sortable: true, width: '120px' },
        { text: 'Dirección delegación', value: 'Departamento.Delegacion.domicilio', align: 'left', sortable: true, width: '170px' },
        { text: 'Departamento', value: 'Departamento', sortable: true, width: '130px'},
        { text: 'Procedimiento activación y movilización', value: 'activacion_movilizacion', align: 'left', sortable: true, width: '130px' },
        { text: 'Información protocolo', value: 'info_protocolo', align: 'left', sortable: true, width: '130px' },
        { text: 'Observaciones', value: 'observaciones', align: 'left', sortable: false, width: '130px' },
        { text: 'Últ. actualización', value: 'fechastamp', align: 'left', sortable: true, width: '150px' },
        { text: 'Usuario', value: 'Usuario', align: 'left', width: '120px', sortable: true }
      ]
    },

    totalRecords () {
      return this.filterUnidadesMedios ? 
        this.filterUnidadesMedios.length : 
        this.unidadesMedios ? this.unidadesMedios.length : 
          0
    },

    pageCount () {
      return parseInt((this.totalRecords / this.itemsPerPage) + 1)
    }

  },

  methods: {
    ...mapActions('unidadMedio', ['fetchUnidadesMedios', 'addUnidadMedio', 'updateUnidadMedio']),
    ...mapActions('tipoMedio', ['fetchTiposMedios']),
    ...mapActions('sectorMedio', ['fetchSectoresMedios']),
    ...mapActions('actividadMedio', ['fetchActividadesMedios']),
    ...mapActions('tipoEntidad', ['fetchTiposEntidad']),
    ...mapActions('entidad', ['fetchListaEntidades']),
    ...mapActions('delegacion', ['fetchDelegaciones']),
    ...mapActions('entidad', ['fetchEntidades', 'fetchListaEntidades']),
    ...mapActions('medio', ['fetchMedios']),

    async loadData () {
      this.isLoading = true
      try {
        await this.fetchUnidadesMedios()
        await this.fetchTiposMedios()
        await this.fetchSectoresMedios()
        await this.fetchActividadesMedios()
        await this.fetchMedios()
        let tiposEntidad = await this.$store.getters['tipoEntidad/tiposEntidad']
        if (!tiposEntidad) {
          await this.fetchTiposEntidad()
        }
        let listaEntidades = await this.$store.getters['entidad/listaEntidades']
        if (!listaEntidades) {
          await this.fetchListaEntidades()
        }
        let entidades = await this.$store.getters['entidad/entidades']
        if (!entidades) {
          await this.fetchEntidades()
        }
        let delegaciones = await this.$store.getters['delegacion/delegaciones']
        if (!delegaciones) {
          await this.fetchDelegaciones()
        }
      } catch (err) {
        if (err.response.status !== 200 && err.response.status !== 500) {
          this.$notify({
            title: err.response.statusText,
            text: 'El usuario no dispone de permisos para acceder al módulo de Gestión de recursos',
            type: 'error'
          })
        }
        this.$router.push('/')
      }
      
      this.isLoading = false
    },

    comprobarPermisos (listaRolesPermisos) {
      return this.$store.getters['usuario/rolesUsuario'](listaRolesPermisos)
    },

    userIsSameEntity (unidadMedio) {
      return this.$store.getters['usuario/permisosEntidad'](
        unidadMedio
          .Departamento
          .Delegacion
          .Entidad
          .id_entidad)
    },

    showAddUnidadMedioDialog () {
      this.editUnidadMedio = null
      this.showDialog = true
      this.isEdit = false
    },

    showEditUnidadMedioDialog (unidadMedio) {
      this.editUnidadMedio = Object.assign({}, unidadMedio)
      this.showDialog = true
      this.isEdit = true
    },

    async acceptAddUnidadMedio (unidadMedio) {
      this.showDialog = false
      await this.addUnidadMedio(unidadMedio)
      unidadMedio.createMedioDefault && await this.fetchTiposMedios()
      await this.recargarDatos()
    },

    async acceptEditUnidadMedio (unidadMedio) {
      this.showDialog = false
      await this.updateUnidadMedio(unidadMedio)
      unidadMedio.createMedioDefault && await this.fetchTiposMedios()
      await this.recargarDatos()
    },

    acceptDeleteMedioRecurso (unidadMedio) {
      let msg = 'Eliminando unidad del medio ' + unidadMedio.Medio.nombre
      this.$root.$confirmDialog.open('¿Está seguro?', msg).then((confirmacion) => {
        if (confirmacion) {
          this.$store.dispatch('unidadMedio/deleteUnidadMedio', unidadMedio).then(() => {
            this.recargarDatos()
          })
        }
      })
    },

    async recargarDatos () {
      this.isLoading = true
      await this.fetchUnidadesMedios()
      await this.fetchEntidades()
      await this.fetchDelegaciones()
      await this.fetchListaEntidades()
      this.isLoading = false
    },

    async getDocumento (id_unidad_medio) {
      try {
        let docPdf = await this.$store.getters['unidadMedio/getDocumentoByUnidadMedio'](id_unidad_medio)
        this.downloadPDF(docPdf.documento, docPdf.nombre)
      } catch (err) {
        if (err.response && err.response.status !== 200) {
          this.$notify({
            title: 'Se ha producido un error',
            text: err.response.data.message,
            type: 'error'
          })
        }
      }
      
    },

    downloadPDF (url, name) {
      let linkSource = `data:application/pdf;base64, ${url}`
      let downloadLink = document.createElement('a')
      let filename = name
      downloadLink.href = linkSource
      downloadLink.download = filename
      downloadLink.click()
    },

    filterMediosRecursos (value) {
      if (!this.medioRecursoSelected) {
        return true
      }

      return value === this.medioRecursoSelected.nombre
    },

    filterEntidad (value) {
      if (!this.entidadSelected) {
        return true
      }

      return value === this.entidadSelected.entidad_titular
    },

    searchMediosRecursos () {

      function atributoValido (atributo) {
        let atributosNoValidos = ['borrado', 'fechastamp', 'usuariostamp', 
          'ruta_protocolo', 'id_departamento', 'id_medio', 'id_unidad_medio',
          'activacion_movilizacion', 'codigo','id_actividad_medio',
          'Sector_medio', 'id_delegacion','id_departamento',
          'is_default', 'id_tipo_entidad', 'logo', 'id_entidad', 'id_periodicidad',
          'recogida_info', 'convenio', 'id_distrito', 'id_tipo_actualizacion', 
          'id_sector_medio', 'id_tipo_medio']

        let encontrado = atributosNoValidos.find(x => x === atributo)
        return encontrado ? false : true
      }

      // Filtro de busqueda para todos las unidadesMedio
      let searchText = this.search ? this.search : ''

      this.filterUnidadesMedios = this.unidadesMedios.filter(function (unidadesMedios) {
        for (var attrUnidadMedio in unidadesMedios) {
          // Usuario
          if (attrUnidadMedio === 'Usuario') {
            for (var attrUsuario in unidadesMedios[attrUnidadMedio]) {
              if (atributoValido(attrUsuario)) {
                let atributo = unidadesMedios[attrUnidadMedio]['nombre'] + ' ' + unidadesMedios[attrUnidadMedio]['apellidos']

                if (StringHelper.compareString(searchText, atributo)) return unidadesMedios[attrUnidadMedio]
              }
            }
          } else if (attrUnidadMedio === 'Medio') {
            // Medio
            for (var attrMedio in unidadesMedios[attrUnidadMedio]) {
              // Actividad_medio
              if (attrMedio === 'Actividad_medio') {
                for (var attrActMedio in unidadesMedios[attrUnidadMedio][attrMedio]) {
                  if (atributoValido(attrActMedio)) {
                    let atributo = unidadesMedios[attrUnidadMedio][attrMedio][attrActMedio]

                    if (StringHelper.compareString(searchText, atributo)) return unidadesMedios[attrUnidadMedio][attrMedio]
                  }
                }
              }

              // Medio
              if (unidadesMedios[attrUnidadMedio]) {
                if (attrMedio !== 'Actividad_medio') {
                  if (atributoValido(attrMedio)) {
                    let atributo = unidadesMedios[attrUnidadMedio][attrMedio]

                    if (StringHelper.compareString(searchText, atributo)) return unidadesMedios[attrUnidadMedio][attrMedio]
                  }
                }
              }
            }
          } else if (attrUnidadMedio === 'Departamento') {
            // Departamento
            for (var attrDepartamento in unidadesMedios[attrUnidadMedio]) {
              // Delegacion
              if (attrDepartamento === 'Delegacion') {
                for (var attrDelegacion in unidadesMedios[attrUnidadMedio][attrDepartamento]) {
                  if(atributoValido(attrDelegacion)) {
                    if (unidadesMedios[attrUnidadMedio][attrDepartamento][attrDelegacion]) {

                      if (attrDelegacion === 'Entidad') {
                        // Entidad
                        for (var attrEntidad in unidadesMedios[attrUnidadMedio][attrDepartamento][attrDelegacion]) {
                          // Tipo_entidad
                          if (attrEntidad === 'Tipo_entidad') {
                            for (var attrTipoEntidad in unidadesMedios[attrUnidadMedio][attrDepartamento][attrDelegacion][attrEntidad]) {
                              if (atributoValido(attrTipoEntidad)) {
                                let atributo = unidadesMedios[attrUnidadMedio][attrDepartamento][attrDelegacion][attrEntidad][attrTipoEntidad]

                                if (StringHelper.compareString(searchText, atributo)) return unidadesMedios[attrUnidadMedio][attrDepartamento][attrDelegacion][attrEntidad]
                              }
                            }
                          }

                          // Entidad
                          if (unidadesMedios[attrUnidadMedio][attrDepartamento][attrDelegacion][attrEntidad]) {
                            if (attrEntidad !== 'Tipo_entidad') {
                              if (atributoValido(attrEntidad)) {
                                let atributo = unidadesMedios[attrUnidadMedio][attrDepartamento][attrDelegacion][attrEntidad].toString()

                                if (StringHelper.compareString(searchText, atributo)) return unidadesMedios[attrUnidadMedio][attrDepartamento][attrDelegacion]
                              }
                            }
                          }
                        }
                      }

                      // Delegacion
                      let atributo = unidadesMedios[attrUnidadMedio][attrDepartamento][attrDelegacion].toString()

                      if (StringHelper.compareString(searchText, atributo)) return unidadesMedios[attrUnidadMedio][attrDepartamento]
                    }
                  }
                }
              }

              // Departamento
              if (atributoValido(attrDepartamento)) {
                if (attrDepartamento !== 'Delegacion') {
                  let atributo = unidadesMedios[attrUnidadMedio][attrDepartamento]

                  if (StringHelper.compareString(searchText, atributo)) return unidadesMedios[attrUnidadMedio][attrDepartamento]
                }
              }
            }
          } else {
            // Datos unidad medio
            if (unidadesMedios[attrUnidadMedio]) {
              if (atributoValido(attrUnidadMedio)) {
                let atributo = unidadesMedios[attrUnidadMedio].toString()

                if (StringHelper.compareString(searchText, atributo)) return unidadesMedios[attrUnidadMedio]
              }
            }
          }
        }
      })
    }

  },

  async created () {

    // Mostrar columna acciones si currentUsuario tiene rol admin o rol mod_medios
    if (this.$store.getters['usuario/rolesUsuario'](['MOD_MEDIOS'])) {
      this.headers.push({ text: 'Acciones', value: 'ACCIONES', align: 'center', sortable: false, width: '110px' })
    }

    // await this.loadData()
  },

  async activated () {
    await this.loadData()
  }
}
</script>

<style lang="scss" scoped>
/* Estilos para que la tabla tenga un max-height y pueda crecer */
.table {
  width: 100vw;
  max-height: calc(100vh - 270px)
}
.tableMobile {
  width: 100vw;
  max-height: calc(100vh - 380px)
}
.v-data-table {
  overflow: auto;
}
.v-data-table >>> .v-data-table__wrapper {
  /* Header fijo */
  overflow: unset;
}
/* .v-data-table >>> .v-data-footer {
    position: fixed;
    bottom: 0;
    width: 100vw;
    background: white;
  } */

/* Hover transparente para todas las filas */
::v-deep tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
/* Hover en color gris para las filas impares */
::v-deep tbody {
  tr:hover:nth-of-type(odd) {
    background-color: rgb(245,245,245) !important;
  }
}
/* Color gris fila data table impar */
::v-deep tbody tr:nth-of-type(odd) {
  background-color: rgb(245,245,245) !important;
}

/* Sticky column data-table - Actividad */
::v-deep thead tr th:nth-of-type(1) {
  position: sticky;
  left: 0;
}

::v-deep tbody tr:nth-of-type(odd) td:nth-of-type(1) {
  background: whitesmoke;
  position: sticky;
  left: 0;
}

::v-deep tbody tr td:nth-of-type(1) {
  background: white;
  position: sticky;
  left: 0;
}

/* Sticky column data-table - Acciones */
::v-deep thead tr th:nth-of-type(14) {
  position: sticky;
  right: 0;
}

::v-deep tbody tr:nth-of-type(odd) td:nth-of-type(14) {
  background: whitesmoke;
  position: sticky;
  right: 0;
}

::v-deep tbody tr td:nth-of-type(14) {
  background: white;
  position: sticky;
  right: 0;
}

/* Tamnaño inputs y select */
.inputSelectMobile {
  margin-top: -15px;
  margin-bottom: 0px;
}

/* Tamaño select mostrar por página */
.select {
  width: 85px;
}
.selectMobile {
  width: 85px;
}

/* Mostrar */
.textMostrar {
  margin-top: 10px;
  padding-left: 0px;
  padding-right: 0px;
  display: flex; 
  justify-content: flex-end;
}

/* Resultados */
.textResultados {
  margin-top: 10px;
  padding-left: 0px;
  padding-right: 0px;
  margin-left: -15px;
  margin-right: 0px;
  display: flex; 
  justify-content: flex-start;
}

/* Paginacion */
.paginacion {
  padding-left: 0px;
  padding-right: 0px;
  display: flex; 
  justify-content: flex-end;
}
</style>