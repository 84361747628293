<template>
  <v-dialog v-model="show" scrollable persistent max-width="600px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title> {{ accionDialog }} </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-form ref="form" v-model="isValid">
          <v-container>
            <v-row class="mt-1">
              <v-col>
                <!-- NOMBRE ACCION -->
                <v-text-field
                  label="Acción de trabajo*"
                  v-model="nombre"
                  dense
                  maxlength="240"
                  :rules="[globalRules.required]"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  label="Descripción*"
                  v-model="descripcion"
                  dense
                  maxlength="360"
                  :rules="[globalRules.required]"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn text color="green" :disabled="!isValid" @click="aceptar">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    show: Boolean,
    isEdit: Boolean,
    editedTipoAccion: Object
  },

  data: () => ({
    isValid: false,

    nombre: null,
    descripcion: null,
  }),

  watch: {
    async show () {
      if (this.show) {
        if (this.isEdit) {
          await this.llenarCamposConTipoAccion (this.editedTipoAccion)
        }
      }
    }
  },

  computed: {
    ...mapGetters('usuario', ['currentUsuario']),
    
    accionDialog () {
      return this.editedTipoAccion ? 'Editar acción' : 'Nueva acción'
    }
  },

  methods: {
    async llenarCamposConTipoAccion (editedTipoAccion) {
      this.nombre = editedTipoAccion.nombre
      this.descripcion = editedTipoAccion.descripcion
    },

    cancelar () {
      this.$refs.form.reset()
      this.$emit('cancelar')
    },

    async aceptar () {
      let tipoAccion = (this.editedTipoAccion && JSON.parse(JSON.stringify(this.editedTipoAccion))) || {}

      tipoAccion.nombre = this.nombre
      tipoAccion.descripcion = this.descripcion
      tipoAccion.usuariostamp = this.currentUsuario.ID

      if (this.isEdit) {
        this.$refs.form.reset()
        tipoAccion.id = this.editedTipoAccion.id
        this.$emit('editTipoAccion', tipoAccion)
      } else {
        this.$refs.form.reset()
        this.$emit('addTipoAccion', tipoAccion)
      }
    }
  }
}
</script>

<style scope>

</style>