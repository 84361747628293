<template>
  <div>
    <vx-add-situacion-operativa-dialog
      :show="showEditFase"
      :emergencia="emergenciaSelected"
      @close="showEditFase = false"
      @selectedSituacionOperativa="updateSituacionOperativa"
    />
    <div class="content">
      <div class="campos-emergencia">
        <div class="datos">
          <span>
            <span class="label-title">Coordenadas:</span> 
            {{ emergenciaSelected.latitude }}, {{ emergenciaSelected.longitude }}
          </span>
          <span>
            <span class="label-title">Descripcion:</span> 
            {{ emergenciaSelected.descripcion }}
          </span>
          <span 
            v-if="emergenciaSelected.TipoEmergencium"
          >
            <span class="label-title">Tipo de emergencia:</span> 
            {{ emergenciaSelected.TipoEmergencium.nombre }}
          </span>
          <span>
            <span class="label-title">Fecha de inicio:</span>
            {{ emergenciaSelected.fechaCreacion | formatDate }}
          </span>
          <span>
            <span class="label-title">Fecha de activación:</span>
            {{ emergenciaSelected.fechaActivacion | formatDate }}
          </span>
          <span>
            <span class="label-title">Riesgos:</span>
            {{ emergenciaSelected.TipoRiesgos && showRiesgosEmergencia(emergenciaSelected.TipoRiesgos) }}
          </span>
        </div>
        <div class="actions">
          <v-tooltip bottom v-if="userCanEditEmergencia && !$vuetify.breakpoint.mobile">
            <template #activator="{ on, attrs }">
              <v-btn
                fab small v-bind="attrs" v-on="on" color="#1A33F4" 
                @click="$emit('showEditEmergencia')"
              >
                <v-icon color="white">mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Editar emergencia</span>
          </v-tooltip>

          <div id="create" style="z-index: 2" v-show="userCanEditEmergencia && $vuetify.breakpoint.mobile">
            <v-speed-dial
              v-model="fab"
              :top="top"
              :bottom="bottom"
              :right="right"
              :left="left"
              :direction="direction"
              :open-on-hover="hover"
              :transition="transition"
            >
              <template #activator>
                <v-btn
                  v-model="fab"
                  color="primary"
                  dark
                  fab
                  small
                >
                  <v-icon v-if="fab">
                    mdi-close
                  </v-icon>
                  <img v-else :src="require('@/assets/icons/menu_emergente.svg')">
                </v-btn>
              </template>
              <!-- EDITAR EMERGENCIA -->
              <v-tooltip bottom v-if="userCanEditEmergencia">
                <template #activator="{ on, attrs }">
                  <v-btn
                    fab small v-bind="attrs" v-on="on" color="#1A33F4" 
                    @click="$emit('showEditEmergencia')"
                  >
                    <v-icon color="white">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar emergencia</span>
              </v-tooltip>
              <!-- AÑADIR SITUACION OPERATIVA A EMERGENCIA -->
              <v-tooltip bottom v-if="userCanEditEmergencia">
                <template #activator="{ on, attrs }">
                  <v-btn
                    fab small v-bind="attrs" v-on="on" color="#1A33F4" 
                    @click="openAddSituacionOperativa"
                  >
                    <v-icon color="white">mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Añadir situación operativa a emergencia</span>
              </v-tooltip>
            </v-speed-dial>
          </div>
        </div>
      </div>
      
      <div class="estados">
        <v-data-table
          class="table"
          :sort-by="['fechastamp']"
          :headers="headers" 
          :items="situacionesOperativasEmergencia"
          :height="mobile ? 400 : 160"
          hide-default-footer
          disable-pagination
          fixed-header
          dense
          :loading="isLoading"
          item-key="id"
          @click:row="emergenciaSituacionClicked"
          :single-select="true"
        >
          <template #no-data>
            <p>Datos no disponibles.</p>
          </template>

          <template #[`item.fecha_declaracion`]="{ item }">
            <span v-if="item.tiene_hora">{{ item.fecha_declaracion | formatDate }}</span>
            <span v-else> {{ fechaSinHoraFormateada(item.fecha_declaracion) }} </span>
          </template>

          <template #[`header.ACCIONES`]>
            <v-tooltip bottom v-if="userCanEditEmergencia">
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs" v-on="on" 
                  @click="openAddSituacionOperativa"
                >
                  <v-icon>mdi-plus-circle</v-icon>
                </v-btn>
              </template>
              <span>Añadir situacion operativa a emergencia</span>
            </v-tooltip>
          </template>

         
          <template #[`item.fechastamp`]="{ item }">
            <span>{{ item.fechastamp | formatDate }}</span>
          </template>

          <template #[`item.FASE`]="{ item }">
            <div class="fase-container">
              <span 
                class="dot"
                :class="{
                  'green': item.SituacionOperativa.codigo == 0,
                  'red':item.SituacionOperativa.codigo == 1,
                  'blue': item.SituacionOperativa.codigo == 3,
                  'strong-red': item.SituacionOperativa.codigo == 2,
                  'purple': item.SituacionOperativa.codigo == 4
                }"
              />
              <span :class="$vuetify.breakpoint.mobile ? 'title-fase-mobile' : 'title-fase'">{{ item.SituacionOperativa.FaseEmergencium.nombre }}</span>
            </div>
          </template>

          <template #[`item.SITUACION_OPERATIVA`]="{ item }">
            <span>{{ item.SituacionOperativa.nombre }}</span>
          </template>

          <template #[`item.ACCIONES`]="{ item }">
            <v-tooltip bottom v-if="userCanEditEmergencia">
              <template #activator="{ on, attrs }">
                <v-btn
                  icon v-bind="attrs" v-on="on" 
                  @click="deleteSituacionOperativaChange(item.id)"
                >
                  <v-icon color="black">mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Quitar estado emergencia</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import { EmergenciaRequest } from '@/api/emergencia'
import { SituacionOperativaRequest } from '@/api/situacionOperativa'
// import { TipoRiesgoRequest } from '@/api/tipoRiesgo'
import { EmergenciaArcGISHelper } from '@/helpers/ArcGISHelpers/EmergenciaArcGISHelper'
import DialogChangeSituacionOperativaVue from './DialogChangeSituacionOperativa.vue'


export default {
  components: {
    'vx-add-situacion-operativa-dialog': DialogChangeSituacionOperativaVue
  },

  props: {},

  data: () => ({
    showEditFase: false,
    headers: [
      { text: 'Actualización', 
        value: 'fechastamp', 
        // sortable: true, 
        align: ' d-none', 
        sort: (a, b) => a < b? 1: -1 
      },
      { 
        text: 'DECLARACIÓN', 
        value: 'fecha_declaracion', 
        sortable: true, 
        width: '25%',
        // sort: (a, b) => a < b? 1: -1
      },
      { text: 'FASE', value: 'FASE', sortable: false },
      { text: 'SITUACIÓN OPERATIVA', value: 'SITUACION_OPERATIVA', sortable: false }, 
      { value: 'ACCIONES', width: '5%', sortable: false }
    ],
    situacionesOperativasEmergencia: [],
    esqueletoAccionesSitOpeTipoEmer: [],
    accionesSituacionEmergencia: [],
    isLoading: false,

    // Speed dial buttons
    direction: 'left',
    fab: false,
    fling: false,
    hover: false,
    tabs: null,
    top: false,
    right: true,
    bottom: true,
    left: false,
    transition: 'slide-x-reverse-transition',
  }),

  async mounted (){
    await this.updateSituacionesOperativas()

    // if (this.emergenciaSelected) {
    //   await this.cargaInicialAccionesSituacionOperativa()
    // }
    
  },

  computed: {
    emergenciaSelected: {
      get (){
        return this.$store.state.emergencia.emergenciaSelected
      },
      set (value){
        this.$store.commit('emergencia/setEmergenciaSelected', value)
      }
    },

    userCanEditEmergencia (){
      return this.$store.getters['usuario/rolesUsuario'](['MOD_EMERGENCIAS'])
    },

    mobile () {
      return this.$vuetify.breakpoint.mobile
    }
  },

  methods: {

    async emergenciaSituacionClicked (value, row) {
      
      let id_emergencia_situacion = value.id

      // Comprobar si tiene acciones para la emergencia situacion seleccionada
      this.accionesSituacionEmergencia = await this.getAccionesSituacionEmergencia(id_emergencia_situacion)

      let situacionOperativaActiva = null // Object
      let isSituacionOperativaActiva = false // Boolean

      if (this.accionesSituacionEmergencia.length) {
        // Comprobar si es la situacionOperativa actual
        situacionOperativaActiva = this.getUltimaSituacionOperativaEmergencia()
        if (id_emergencia_situacion === situacionOperativaActiva.id) {
          isSituacionOperativaActiva = true
        } else {
          isSituacionOperativaActiva = false
        }

        // Activar el panel de protocolo intervencion y enviar los datos al padre
        this.$emit('openProtocolo', {
          showPanel: 1, 
          data: this.accionesSituacionEmergencia, 
          isSituacionOperativaActiva: isSituacionOperativaActiva,
          // accionesSinCompletar: accionesSinCompletar
        })
      } else {
        this.notificarSituacionOperativaNoTieneAcciones()

        // Comprobar si es la situacionOperativa actual
        situacionOperativaActiva = this.getUltimaSituacionOperativaEmergencia()
        if (id_emergencia_situacion === situacionOperativaActiva.id) {
          isSituacionOperativaActiva = true
        } else {
          isSituacionOperativaActiva = false
        
        }

        this.$emit('openProtocolo', {
          showPanel: 1, 
          data: this.accionesSituacionEmergencia, 
          idEmergenciaSituacion: id_emergencia_situacion,
          isSituacionOperativaActiva: isSituacionOperativaActiva,
        })
      }
    },

    notificarSituacionOperativaNoTieneAcciones () {
      this.$notify({
        title: 'No hay acciones disponibles',
        text: 'La situación operativa actual, no tiene acciones a realizar',
        type: 'warning'
      })
    },

    getClassBySituacionOperativaCodigo (codigo){
      switch (codigo){
      case 0:
        return 'green'
      case 1, 2: 
        return 'red'
      case 3: 
        return 'blue'
      case 4:
        return 'purple'
      default: 
        return ''
      }
    },

    async deleteSituacionOperativaChange (id){
      if (this.situacionesOperativasEmergencia && this.situacionesOperativasEmergencia.length > 1){
        this.$root.$confirmDialog.open('¿Está seguro?', 'Eliminando estado previo de emergencia')
          .then(async (isAffirmative) => {
            if (isAffirmative) {
              await EmergenciaRequest.deleteSituacionOperativa(id)
              this.$notify({
                title: 'Estado eliminado',
                text: 'La situación operativa ha sido eliminada del historico de la emergencia'
              })
              this.updateSituacionesOperativas()
              this.updateEmergenciaSelectedData()
              this.$store.dispatch('emergencia/fetchEmergencias')
            }
          })
      } else {
        this.$notify({
          title: 'No se puede eliminar el estado',
          text: 'La emergencia no puede quedarse sin ningun estado asignado, asigne otro estado antes de eliminarlo',
          type: 'error'
        })
      }
    },

    getSituacionOperativaActivaId (){
      return this.situacionesOperativasEmergencia
        .reduce((prev, current) => {
          return prev.fechastamp < current.fechastamp ? prev : current
        }).situacionOperativa
    },

    async openAddSituacionOperativa (){
      /**
       * Comprobar que para la emergencia y situacion operativa actual, se han 
       * completado todas las tareas. Si no, mostrar mensaje de confirmacion para
       * el cambio de situacion operativa
       */
      if (this.situacionesOperativasEmergencia.length) {
        this.isLoading = true
        
        // Situacion operativa actual
        let emergencia_situacion_operativa = this.getUltimaSituacionOperativaEmergencia()
        // Acciones de la situacion operativa actual
        let accionesSituacionOperativaEmergencia = await this.getAccionesSituacionEmergencia(emergencia_situacion_operativa.id)

        // Comprobar si todas las acciones estan completadas
        let hayAccionesNoCompletadas = await this.comprobarAccionesNoCompletadas (accionesSituacionOperativaEmergencia)

        if (hayAccionesNoCompletadas) {

          if (accionesSituacionOperativaEmergencia.length) {
            let msg = 'Existen acciones pendientes de revisar en la situación operativa actual. ¿Deseas igualmente cambiar de situación operativa?'
            
            this.$root.$confirmDialog.open('¿Está seguro?', msg).then(confirmacionUsuario => {
              if (confirmacionUsuario) {
                this.showEditFase = true
              }
            })
          } else {
            // No tiene acciones
            this.showEditFase = true
          }
        } else {
          this.showEditFase = true
        }
        this.isLoading = false
      }
    },

    getUltimaSituacionOperativaEmergencia () {
      if (this.situacionesOperativasEmergencia.length > 1) {
        let sitOpeEmer = JSON.parse(JSON.stringify(this.situacionesOperativasEmergencia))
        sitOpeEmer.sort((a, b) => {
          let aOrden = a.fechastamp
          let bOrden = b.fechastamp
          return (aOrden > bOrden) ? -1 : 1
        })
        return sitOpeEmer[0]
      } else if (this.situacionesOperativasEmergencia.length === 1) {
        return this.situacionesOperativasEmergencia[0]
      } else {
        return null
      }
    },

    async getAccionesSituacionEmergencia (idEmergenciaSituacionOperativa) {
      // try {
      return await this.$store.getters['accionSituacionEmergencia/getAccionesSituacionEmergencia']({situacionEmergenciaId: idEmergenciaSituacionOperativa})
      /*} catch (err) {
      }*/
    },

    comprobarAccionesNoCompletadas (accionesSituacionOperativaEmergencia) {
      let accionesNoCompletadas = accionesSituacionOperativaEmergencia.filter(x => x.completada === false)
      return accionesNoCompletadas.length ? true : false
    },

    notificarAccionesSituacionEmergenciaNoCompletadas () {
      this.$notify({
        title: 'Acciones pendientes',
        text: 'Existen acciones pendientes de revisar en la situación operativa actual',
        type: 'warning'
      })
    },

    async updateSituacionesOperativas (){
      this.situacionesOperativasEmergencia = 
        (await SituacionOperativaRequest
          .getSituacionesOperativasFromEmergencia(this.emergenciaSelected.id))
          .sort((a, b) => a.fechastamp < b.fechastamp? 1: -1)

      // Carga inicial de acciones para la situacion operativa actual
      if (this.situacionesOperativasEmergencia.length) {
        await this.cargaInicialAccionesSituacionOperativa()
      }
    },

    async updateEmergenciaSelectedData (){
      await this.$store.dispatch('emergencia/updateEmergenciaSelectedData')
      EmergenciaArcGISHelper.updateEmergencia(this.emergenciaSelected)
    },

    async updateSituacionOperativa (newSituacionOperativa) {
      if (newSituacionOperativa.id != this.getSituacionOperativaActivaId()){
        this.showEditFase = false
        if (this.emergenciaSelected.situacionOperativa !== newSituacionOperativa.id){
          EmergenciaRequest.addSituacionOperativa(
            this.emergenciaSelected.id ,
            newSituacionOperativa
          ).then(async () => {
            this.$notify({
              title: 'Estado de emergencia actualizado',
              text: 'El estado de la emergencia ha sido modificado'
            })
            await this.updateEmergenciaSelectedData()
            this.$store.dispatch('emergencia/fetchEmergencias')
          }).catch((error) => {
            this.$notify({
              title: 'Estado no actualizado',
              text: 'Ha ocurrido un error desconocido al actualizar el estado de la emergencia',
              type: 'error'
            })
            this.$store.dispatch('emergencia/fetchEmergencias')
            console.error(error)
          })
        } else {
          this.$notify({
            title: 'Estado no actualizado',
            text: 'No puede asignar el estado ya presente en la emergencia',
            type: 'error'
          })
        }
      }
    },

    fechaSinHoraFormateada (fechaDeclaracion) {
      let fechaSinHora = (new Date(new Date (fechaDeclaracion) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      
      let fechaFormateada = this.$date.parseDate(fechaSinHora, 'YYYY-MM-DD')
      return fechaFormateada.isValid() ? this.$date.formatDate(fechaFormateada, 'DD/MM/YYYY') : ''
    },

    async cargaInicialAccionesSituacionOperativa () {
      this.isLoading = true
      try {
      // Situacion operativa actual
        let emergencia_situacion_operativa = this.getUltimaSituacionOperativaEmergencia()

        // try {
        // Acciones de la situacion operativa actual
        let accionesSituacionOperativaEmergencia = await this.getAccionesSituacionEmergencia(emergencia_situacion_operativa.id)

        let situacionOperativaActiva = null // Object
        let isSituacionOperativaActiva = false // Boolean

        if (accionesSituacionOperativaEmergencia.length) {
        // Comprobar si es la situacionOperativa actual
          situacionOperativaActiva = this.getUltimaSituacionOperativaEmergencia()
          if (emergencia_situacion_operativa.id === situacionOperativaActiva.id) {
            isSituacionOperativaActiva = true
          } else {
            isSituacionOperativaActiva = false
          }

          // Activar el panel de protocolo intervencion y enviar los datos al padre
          this.$emit('openProtocolo', {
            showPanel: 1, 
            data: accionesSituacionOperativaEmergencia, 
            isSituacionOperativaActiva: isSituacionOperativaActiva,
          // accionesSinCompletar: accionesSinCompletar
          })
        } else {
          this.notificarSituacionOperativaNoTieneAcciones()

          // Comprobar si es la situacionOperativa actual
          situacionOperativaActiva = this.getUltimaSituacionOperativaEmergencia()
          if (emergencia_situacion_operativa.id === situacionOperativaActiva.id) {
            isSituacionOperativaActiva = true
          } else {
            isSituacionOperativaActiva = false
        
          }

          this.$emit('openProtocolo', {
            showPanel: 1, 
            data: accionesSituacionOperativaEmergencia, 
            idEmergenciaSituacion: emergencia_situacion_operativa.id,
            isSituacionOperativaActiva: isSituacionOperativaActiva,
          })
        }
      } catch (err) {
        // Do nothing
      }
      
      this.isLoading = false
    },

    showRiesgosEmergencia (TipoRiesgos) {
      let stringRiesgos = ''
      if (TipoRiesgos.length) {
        for (let tipoRiesgo of TipoRiesgos) {
          stringRiesgos = stringRiesgos === '' ? 
            stringRiesgos + tipoRiesgo.nombre :
            stringRiesgos + '; ' + tipoRiesgo.nombre
        }
      }
      return stringRiesgos
    }
  },

  watch: {
    emergenciaSelected (newValue){
      this.situacionOperativaSelected = newValue.situacionOperativa
      this.updateSituacionesOperativas()
    },
    'emergenciaSelected.situacionOperativa': function () {
      this.updateSituacionesOperativas()
    },
    'emergenciaSelected.TipoRiesgos': function () {
      if (this.emergenciaSelected.TipoRiesgos) {
        if (this.emergenciaSelected.TipoRiesgos.length) {
          this.showRiesgosEmergencia(this.emergenciaSelected.TipoRiesgos)
        }
      }
            
    }
  }
  
}
</script>

<style scoped lang="scss">

.v-data-table::v-deep {
  th {
    font-size: 12px !important;
  }
  td {
    font-size: 14px !important;
  }
}


.content {
  padding: 12px;
  .campos-emergencia{
    width: 100%;
    display: flex;
    gap: 3px;
    .datos {
      width: 100%;
      display: flex;
      gap: 6px;
      flex-direction: column;
      margin-bottom: 9px;
      span {
        line-height: 19px;
        .label-title {
          font-weight: bold;
        }
      }
    }
    .actions {
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      align-items: center;
      gap: 6px;
      margin-bottom: 6px;
    }
  }
  .add-so {
    display: flex;
    justify-content: flex-end;
  }
}

  .estados::v-deep {
    overflow: hidden;
    .v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
      min-height: 20px;
    }
    .table {
      tbody{
        overflow: auto;
      }
    }
    ::v-deep .v-data-table-header {
      font-size: 12px;
    }
    .add-situacion-operativa {
      z-index: 3;
      margin-right: 12px;
    }
  }
  .fase-container {
    display: flex;
    align-items: center;
    max-width: 200px;
    .dot {
    height: 17px;
    width: 17px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
    &.green {
      background-color: #008001;
    }
    &.red {
      background-color: #ED1C24;
    }
    &.strong-red {
      background-color: #760000;
    }
    &.blue {
      background-color: #008ECC;
    }
    &.gray {
      background-color: #969696;
    }
  }
  .title-fase {
      max-width: 200px;
      // word-break: break-word;
    }
    .title-fase-mobile {
      max-width: 120px;
      display: block;
      text-align: left;
      padding-left: 15px;

      // word-break: break-word;
    }
  }
  
  .chip {
    width: 100px;
  }

  .chip >>> .v-chip__content { /* Centrar texto chips */
    margin: auto !important;
  }

/* Speed dial list */
#create .v-speed-dial {
  position: absolute;
  top: 75px;
}

#create .v-btn--floating {
  position: relative;
}

::v-deep .v-speed-dial--right {
    right: 12px;
    // top: 18px;
}

::v-deep .v-speed-dial--direction-left .v-speed-dial__list, .v-speed-dial--direction-right .v-speed-dial__list {
    height: 100%;
    top: -273px;
    padding: 0 16px;
    margin-top: 22px;
}

/* Alinear iconos speed dial list */
.iconsSpeedDial {
  margin-top: 15px;
}
</style>
