import api from '@/api'
import constants from '../../helpers/constants'

import DateService from '../../helpers/DateService'
import UUIDService from '../../helpers/UUIDService'

import Vue from 'vue'

let Q = require('q')

const state = {
  incendiosAll: [],
  incendios: [],

  tiposIncendioEstado: [],

  tiposDeteccion: []
}

const getters = {
  incendiosAll: (state) => state.incendiosAll,

  incendios: (state) => state.incendios,
  getIncendioByID: (state) => (idIncendio) => {
    let incendio = null

    if (idIncendio) {
      incendio = state.incendios.find(x => x.ID_INCENDIO.equalsIgnoreCase(idIncendio))
    }
    return incendio
  },

  isIncendioFinalizado: (state) => (idIncendio) => {
    let estadoFinalizado = null

    if (idIncendio) {
      let incendio = state.incendios.find(x => x.ID_INCENDIO.equalsIgnoreCase(idIncendio))

      if (incendio && incendio.ESTADOS) {
        estadoFinalizado = incendio.ESTADOS.find(x => x.TIPO_INCENDIO_ESTADO.VALUE === 4)
      }
    }

    return !!estadoFinalizado
  },

  tiposIncendioEstado: (state) => state.tiposIncendioEstado,
  getTipoIncendioEstadoByValue: (state) => (value) => {
    return state.tiposIncendioEstado.find(x => x.VALUE === value)
  },

  tiposDeteccion: (state) => state.tiposDeteccion,
  getTipoDeteccionById: (state) => (idTipoDeteccion) => {
    let deteccion = null

    if (idTipoDeteccion) {
      deteccion = state.tiposDeteccion.find(x => x.ID_TIPO_DETECCION === idTipoDeteccion) // TODO: por algun motivo no es uuid, es numerico
    }
    return deteccion
  }
}

const mutations = {
  SET_ALL_INCENDIOS (state, incendiosAll) {
    state.incendiosAll = incendiosAll
  },

  SET_INCENDIOS (state, incendios) {
    // state.incendios = incendios
    // state.incendios = []

    // Parsear las geometrias de los planes de operaciones
    let incTemp = []
    for (let i = 0; i < incendios.length; i++) { // TODO: HACER EN BACKEND
      let incendio = incendios[i]

      incendio.SECTORES.sort((a, b) => { // ORDENAR LOS SECTORES // TODO: si se hace lo de los planOps, no quitar esto
        return a.SECTOR === 'GENERAL' ? -1 : b.SECTOR === 'GENERAL' ? 1 : a.SECTOR.localeCompare(b.SECTOR)
      })

      if (incendio.PLANES_OPERACIONES) {
        for (let j = 0; j < incendio.PLANES_OPERACIONES.length; j++) {
          let plan = incendio.PLANES_OPERACIONES[j]

          if (plan.GRAFICOS) {
            for (let z = 0; z < plan.GRAFICOS.length; z++) {
              let oldGrafico = plan.GRAFICOS[z]

              incendios[i].PLANES_OPERACIONES[j].GRAFICOS[z].coordenadas = JSON.parse(oldGrafico.GEOMETRIA)
              incendios[i].PLANES_OPERACIONES[j].GRAFICOS[z].symbol = JSON.parse(oldGrafico.SIMBOLO)
              incendios[i].PLANES_OPERACIONES[j].GRAFICOS[z].attributes = JSON.parse(oldGrafico.ATRIBUTOS)
              incendios[i].PLANES_OPERACIONES[j].GRAFICOS[z].type = oldGrafico.TIPO

              // let symbol = incendios[i].PLANES_OPERACIONES[j].GRAFICOS[z].symbol
              // if (Array.isArray(symbol.color)) { // Parsear color de graficos recibidos / fetcheados
              //   symbol.color = {
              //     r: symbol.color[0],
              //     g: symbol.color[1],
              //     b: symbol.color[2],
              //     a: symbol.color[3] / 255
              //   }
              // }

              delete incendios[i].PLANES_OPERACIONES[j].GRAFICOS[z].GEOMETRIA // TODO: cambiar los nombres de los parametros en Arcgis svm.on y no hara falta hacer esta chapuza
              delete incendios[i].PLANES_OPERACIONES[j].GRAFICOS[z].SIMBOLO
              delete incendios[i].PLANES_OPERACIONES[j].GRAFICOS[z].ATRIBUTOS
              delete incendios[i].PLANES_OPERACIONES[j].GRAFICOS[z].TIPO
            }
          }
        }
      }

      incTemp.push(incendios[i])
    }

    state.incendios = incTemp
  },

  OCULTAR_INCENDIO (state, data) {
    for (let i = 0; i < state.incendiosAll.length; i++) { // Flag en listado de incendios
      if (state.incendiosAll[i].ID_INCENDIO.equalsIgnoreCase(data.ID_INCENDIO)) {
        state.incendiosAll[i].OCULTO = data.OCULTO
        break
      }
    }

    if (data.OCULTO) {
      for (let i = 0; i < state.incendios.length; i++) { // Sacar de la lista de incendios actuales
        if (state.incendios[i].ID_INCENDIO.equalsIgnoreCase(data.ID_INCENDIO)) {
          state.incendios.splice(i, 1)
          break
        }
      }
    } else { // Descargar incendios actuales
      this.dispatch('incendio/fetchIncendios')
    }
  },

  SET_TIPOS_INCENDIO_ESTADO (state, tiposIncendioEstado) {
    state.tiposIncendioEstado = tiposIncendioEstado
  },

  SET_TIPOS_DETECCION (state, tiposDeteccion) {
    state.tiposDeteccion = tiposDeteccion
  },

  SOCKET_addIncendio (state, data) {
    state.incendios.push(data)

    this.notify('Nueva emergencia', `Se ha iniciado una emergencia en ${data.LOCALIDAD}`)
  },
  SOCKET_changeIncendio (state, data) {
    let incendio = state.incendios.find(x => x.ID_INCENDIO.equalsIgnoreCase(data.ID_INCENDIO))

    if (incendio) {
      incendio.LOCALIDAD = data.LOCALIDAD
      incendio.LATITUD = data.LATITUD
      incendio.LONGITUD = data.LONGITUD
      incendio.ID_TIPO_DETECCION = data.ID_TIPO_DETECCION
      incendio.OBSERVACIONES = data.OBSERVACIONES

      this.notify('Emergencia ' + incendio.LOCALIDAD, 'Se han actualizado los datos')
    }

    // Actualizar los datos en la lista de todos los incendios - por si acaso -
    let incendioAll = state.incendiosAll.find(x => x.ID_INCENDIO.equalsIgnoreCase(data.ID_INCENDIO))
    if (incendioAll) {
      incendioAll.LOCALIDAD = data.LOCALIDAD
      incendioAll.LATITUD = data.LATITUD
      incendioAll.LONGITUD = data.LONGITUD
      incendioAll.ID_TIPO_DETECCION = data.ID_TIPO_DETECCION
      incendioAll.OBSERVACIONES = data.OBSERVACIONES
    }
  },

  SOCKET_addEstadoIncendio (state, data) {
    let incendio = state.incendios.find(x => x.ID_INCENDIO.equalsIgnoreCase(data.ID_INCENDIO))

    if (incendio) {
      incendio.ESTADOS.unshift(data)

      this.notify('Emergencia ' + incendio.LOCALIDAD, `Pasa a estado ${data.TIPO_INCENDIO_ESTADO.INCENDIO_ESTADO}`)
    }
  },
  SOCKET_editEstadoIncendio (state, data) {
    let incendio = state.incendios.find(x => x.ID_INCENDIO.equalsIgnoreCase(data.ID_INCENDIO))

    if (incendio) {
      for (let i = 0; i < incendio.ESTADOS.length; i++) {
        let estado = incendio.ESTADOS[i]

        if (estado.ID_TIPO_INCENDIO_ESTADO.equalsIgnoreCase(data.ID_TIPO_INCENDIO_ESTADO)) {
          incendio.ESTADOS[i].FECHA = data.FECHA

          if (data.CODIGO_TIPO_ESTADO === 0) { // Fecha inicio
            incendio.FECHA_CREACION = data.FECHA
          }

          this.notify('Emergencia ' + incendio.LOCALIDAD, `Estado '${estado.TIPO_INCENDIO_ESTADO.INCENDIO_ESTADO}' actualizado`)
          break
        }
      }

      // TODO:? Vue.set(state.incendios, positionIncendio, incendio)
    }
  },
  SOCKET_deleteEstadoIncendio (state, data) {
    let incendio = state.incendios.find(x => x.ID_INCENDIO.equalsIgnoreCase(data.ID_INCENDIO))

    if (incendio) {
      incendio.ESTADOS.splice(0, 1)

      this.notify('Emergencia ' + incendio.LOCALIDAD, `Pasa a estado ${incendio.ESTADOS[0].TIPO_INCENDIO_ESTADO.INCENDIO_ESTADO}`)
    }
  },

  SOCKET_changeSectoresIncendio (state, data) {
    let incendio = state.incendios.find(x => x.ID_INCENDIO.equalsIgnoreCase(data.ID_INCENDIO))

    if (incendio) {
      incendio.SECTORES = data.SECTORES

      this.notify('Emergencia ' + incendio.LOCALIDAD, 'Sectores actualizados')
    }
  },

  SOCKET_addMediosIncendio (state, data) {
    let indexIncendio = state.incendios.findIndex(x => x.ID_INCENDIO.equalsIgnoreCase(data.ID_INCENDIO))

    if (indexIncendio !== -1) {
      let incendio = state.incendios[indexIncendio]

      let indexSector = incendio.SECTORES.findIndex(x => x.ID_SECTOR.equalsIgnoreCase(data.ID_SECTOR))
      if (indexSector !== -1) {
        let sector = incendio.SECTORES.find(x => x.ID_SECTOR.equalsIgnoreCase(data.ID_SECTOR))

        let mediosMsg = []
        for (let i = 0; i < data.MEDIOS.length; i++) {
          let medioAdd = data.MEDIOS[i]
          sector.MEDIOS.push(medioAdd)

          let newEstado = 0
          if (medioAdd.FECHA_AVISO) {
            newEstado = 1
          }
          if (medioAdd.FECHA_LLEGADA) {
            newEstado = 2
          }
          if (medioAdd.FECHA_SALIDA) {
            newEstado = 3
          }
          if (medioAdd.FECHA_LLEGADA_BASE) {
            newEstado = 0
          }

          let medio = this.getters['medio/getMedioByID'](medioAdd.ID_MEDIO)
          medio.ESTADO = newEstado // TODO: esto habria que cambiarlo
          medio.ID_INCENDIO = incendio.ID_INCENDIO

          mediosMsg.push(medio.MEDIO)
        }

        Vue.set(state.incendios, indexIncendio, incendio)
        Vue.set(incendio.SECTORES, indexSector, sector)

        this.notify('Emergencia ' + incendio.LOCALIDAD, `Medios [${mediosMsg}] asignados`)
      }
    }
  },
  SOCKET_editMedioIncendio (state, data) {
    let incendio = state.incendios.find(x => x.ID_INCENDIO.equalsIgnoreCase(data.ID_INCENDIO))

    if (incendio) {
      /* let positionSector = App.getPositionSector(data.ID_SECTOR, incendio.SECTORES)
      let positionMedioSector = App.getPositionMedioSector(data.ID_MEDIO_SECTOR, incendio.SECTORES[positionSector].MEDIOS)
      // incendio.SECTORES[positionSector].MEDIOS[positionMedioSector] = data
      incendio.SECTORES[positionSector].MEDIOS.splice(positionMedioSector, 1)
      incendio.SECTORES[positionSector].MEDIOS.push(data) */

      // Sacar del sector previo
      for (let i = 0; i < incendio.SECTORES.length; i++) {
        let sector = incendio.SECTORES[i]
        for (let j = 0; j < sector.MEDIOS.length; j++) {
          let medioSector = sector.MEDIOS[j]

          if (medioSector.ID_MEDIO_SECTOR.equalsIgnoreCase(data.ID_MEDIO_SECTOR.toUpperCase())) {
            incendio.SECTORES[i].MEDIOS.splice(j, 1)
            break
          }
        }
      }

      // Add al sector nuevo
      let sector = incendio.SECTORES.find(x => x.ID_SECTOR.equalsIgnoreCase(data.ID_SECTOR))
      sector.MEDIOS.push(data)

      let medio = this.getters['medio/getMedioByID'](data.ID_MEDIO)
      medio.ESTADO = data.ESTADO
      medio.ID_INCENDIO = data.ID_INCENDIO
    }
  },
  SOCKET_deleteMedioIncendio (state, data) {
    let indexIncendio = state.incendios.findIndex(x => x.ID_INCENDIO.equalsIgnoreCase(data.ID_INCENDIO))

    if (indexIncendio !== -1) {
      let incendio = state.incendios[indexIncendio]

      let indexSector = incendio.SECTORES.findIndex(x => x.ID_SECTOR.equalsIgnoreCase(data.ID_SECTOR))
      if (indexSector !== 1) {
        let sector = incendio.SECTORES[indexSector]
        let medio = this.getters['medio/getMedioByID'](data.ID_MEDIO)

        let positionMedioSector = sector.MEDIOS.findIndex(x => x.ID_MEDIO_SECTOR.equalsIgnoreCase(data.ID_MEDIO_SECTOR))
        sector.MEDIOS.splice(positionMedioSector, 1)

        if (data.IS_ACTUALIZAR_ESTADO) {
          medio.ESTADO = 0
          medio.ID_INCENDIO = null
        }

        Vue.set(state.incendios, indexIncendio, incendio)
        Vue.set(incendio.SECTORES, indexSector, sector)

        this.notify('Emergencia ' + incendio.LOCALIDAD, `El medio ${medio.MEDIO} se ha eliminado`)
      }
    }
  },

  SOCKET_editPlanificacionMedio (state, data) {
    let indexIncendio = state.incendios.findIndex(x => x.ID_INCENDIO.equalsIgnoreCase(data.ID_INCENDIO))

    if (indexIncendio !== -1) {
      let incendio = state.incendios[indexIncendio]

      for (let i = 0; i < incendio.SECTORES.length; i++) {
        let sector = incendio.SECTORES[i]
        for (let j = 0; j < sector.MEDIOS.length; j++) {
          let medioSector = sector.MEDIOS[j]

          if (medioSector.ID_MEDIO_SECTOR.equalsIgnoreCase(data.PLANIFICACION.ID_MEDIO_SECTOR.toUpperCase())) {
            incendio.SECTORES[i].MEDIOS[j].PLANIFICACION = data.PLANIFICACION
          }
        }
      }

      Vue.set(state.incendios, indexIncendio, incendio)
    }
  },

  SOCKET_addPlanOperaciones (state, data) {
    let position = state.incendios.findIndex(x => x.ID_INCENDIO.equalsIgnoreCase(data.ID_INCENDIO))

    if (position !== -1) {
      state.incendios[position].PLANES_OPERACIONES.unshift(data)
      this.notify('Nuevo plan de operaciones', `Se ha creado un plan de operaciones para la emergencia en ${state.incendios[position].LOCALIDAD}`)
    }
  },
  SOCKET_editPlanOperaciones (state, data) {
    let incendioIndex = state.incendios.findIndex(x => x.ID_INCENDIO.equalsIgnoreCase(data.ID_INCENDIO))

    if (incendioIndex !== -1) {
      let planIndex = state.incendios[incendioIndex].PLANES_OPERACIONES.findIndex(x => x.ID_PLAN_OPERACIONES.equalsIgnoreCase(data.ID_PLAN_OPERACIONES))

      if (planIndex !== -1) {
        state.incendios[incendioIndex].PLANES_OPERACIONES[planIndex] = data
        Vue.set(state.incendios, incendioIndex, state.incendios[incendioIndex])
        this.notify('Plan de operaciones modificado', `Se ha modificado un plan de operaciones para la emegencia en ${state.incendios[incendioIndex].LOCALIDAD}`)
      }
    }
  },
  SOCKET_deletePlanOperaciones (state, data) {
    let incendioIndex = state.incendios.findIndex(x => x.ID_INCENDIO.equalsIgnoreCase(data.ID_INCENDIO))

    if (incendioIndex !== -1) {
      let planIndex = state.incendios[incendioIndex].PLANES_OPERACIONES.findIndex(x => x.ID_PLAN_OPERACIONES.equalsIgnoreCase(data.ID_PLAN_OPERACIONES))

      if (planIndex !== -1) {
        state.incendios[incendioIndex].PLANES_OPERACIONES.splice(planIndex, 1)
        this.notify('Plan de operaciones eliminado', `Se ha modificado un plan de operaciones para la emergencia en ${state.incendios[incendioIndex].LOCALIDAD}`)
      }
    }
  }
}

const actions = {
  async fetchAllIncendios ({ commit }) { // Listado historico de todos los incendios
    let deferred = Q.defer()

    try {
      const response = await api.incendio.getAllIncendios()
      commit('SET_ALL_INCENDIOS', response.data)

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async fetchIncendios ({ commit }) { // Incendios actuales
    let deferred = Q.defer()

    try {
      let daysTimeBack = constants.daysTimeBackIncendios
      let COD_CCAA = constants.comunidad.CODIGO_INE_CCAA
      let COD_PROV = constants.comunidad.CODIGO_INE_PROVINCIA

      const response = await api.incendio.getIncendios(daysTimeBack, COD_CCAA, COD_PROV)
      commit('SET_INCENDIOS', response.data)

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async fetchTiposIncendioEstado ({ commit }) {
    let deferred = Q.defer()

    try {
      const response = await api.incendio.getTiposIncendioEstado()
      commit('SET_TIPOS_INCENDIO_ESTADO', response.data)

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async fetchTiposDeteccion ({ commit }) {
    let deferred = Q.defer()

    try {
      const response = await api.incendio.getTiposDeteccion()
      commit('SET_TIPOS_DETECCION', response.data)

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  // INCENDIO
  async addIncendio ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.incendio.addIncendio(data)

      if (response.status === 200) {
        commit('SOCKET_addIncendio', data)
        //this._vm.$socket.emit('addIncendio', data)
      }

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },
  async editIncendio ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.incendio.editIncendio(data)

      if (response.status === 200) {
        commit('SOCKET_changeIncendio', data)
        //this._vm.$socket.emit('changeIncendio', data)
      }

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },
  async ocultarIncendio ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.incendio.ocultarIncendio(data)

      if (response.status === 200) {
        commit('OCULTAR_INCENDIO', data)
        // commit('SOCKET_ocultarIncendio', data)
        // this._vm.$socket.emit('ocultarIncendio', data)
      }

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  // ESTADOS INCENDIO
  async addEstadoIncendio ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.incendio.addEstadoIncendio(data)

      if (response.status === 200) {
        commit('SOCKET_addEstadoIncendio', data)
        //this._vm.$socket.emit('addEstadoIncendio', data)
      }

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },
  async editEstadoIncendio ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.incendio.editEstadoIncendio(data)

      if (response.status === 200) {
        commit('SOCKET_editEstadoIncendio', data)
        //.emit('editEstadoIncendio', data)
      }

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },
  async deleteEstadoIncendio ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.incendio.deleteEstadoIncendio(data)

      if (response.status === 200) {
        commit('SOCKET_deleteEstadoIncendio', data)
        //this._vm.$socket.emit('deleteEstadoIncendio', data)
      }

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  // SECTORES
  async changeSectoresIncendio ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.incendio.changeSectoresIncendio(data)

      if (response.status === 200) {
        commit('SOCKET_changeSectoresIncendio', data)
        //this._vm.$socket.emit('changeSectoresIncendio', data)
      }

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  // MEDIOS
  async addMediosIncendio ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.incendio.addMediosIncendio(data.MEDIOS)

      if (response.status === 200) {
        data.MEDIOS = response.data // TODO: Chapuza para Actualizar los medios con los datos de los eventos
        commit('SOCKET_addMediosIncendio', data)
        //this._vm.$socket.emit('addMediosIncendio', data)
      }

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },
  async editMedioIncendio ({ commit, dispatch }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.incendio.editMedioIncendio(data)

      if (response.status === 200) {
        let incendio = state.incendios.find(x => x.ID_INCENDIO.equalsIgnoreCase(data.ID_INCENDIO))

        let estado = 0
        if (!data.FECHA_AVISO) {
          estado = 0
        } else if (!data.FECHA_LLEGADA) {
          estado = 1
        } else if (!data.FECHA_SALIDA) {
          estado = 2
        } else if (!data.FECHA_LLEGADA_BASE) {
          estado = 3
        }
        data.ESTADO = estado

        commit('SOCKET_editMedioIncendio', data)
        //this._vm.$socket.emit('editMedioIncendio', data)

        // ChangeEstadoIncendio. Añadir o quitar Extinción
        let mediosActuando = false
        for (let i = 0; i < incendio.SECTORES.length; i++) {
          let sector = incendio.SECTORES[i]
          for (let j = 0; j < incendio.SECTORES[i].MEDIOS.length; j++) {
            let medioSector = sector.MEDIOS[j]
            if (medioSector.FECHA_LLEGADA && !medioSector.FECHA_SALIDA) {
              mediosActuando = true
              break
            }
          }
        }

        if (incendio.ESTADOS[0].TIPO_INCENDIO_ESTADO.VALUE === state.tiposIncendioEstado[0].VALUE) { // Si esta en 'INICIO'
          if (!mediosActuando && estado === 2) { // Y pasamos un medio a 'LLEGADA INCENDIO', pasar el incendio a 'EXTINCION'
            let estadoIncendio = {
              ID_INCENDIO_ESTADO: UUIDService.createUUID(),
              ID_INCENDIO: incendio.ID_INCENDIO,
              ID_TIPO_INCENDIO_ESTADO: state.tiposIncendioEstado[1].ID_TIPO_INCENDIO_ESTADO,
              FECHA: DateService.now(),
              TIPO_INCENDIO_ESTADO: state.tiposIncendioEstado[1]
            }

            dispatch('addEstadoIncendio', estadoIncendio)
          }
        } else if (incendio.ESTADOS[0].TIPO_INCENDIO_ESTADO.VALUE === state.tiposIncendioEstado[1].VALUE) { // Si esta en 'EXTINCION'
          if (!mediosActuando) { // Y no quedan medios, pasar a 'INICIO'
            let data = {
              ID_INCENDIO_ESTADO: incendio.ESTADOS[0].ID_INCENDIO_ESTADO,
              ID_INCENDIO: incendio.ESTADOS[0].ID_INCENDIO,
              NUEVO_ESTADO: incendio.ESTADOS[1].TIPO_INCENDIO_ESTADO.INCENDIO_ESTADO
            }
            dispatch('deleteEstadoIncendio', data)
          }
        }
      }

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },
  async deleteMedioIncendio ({ commit, dispatch }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.incendio.deleteMedioIncendio(data)

      if (response.status === 200) {
        data.IS_ACTUALIZAR_ESTADO = response.data.IS_ACTUALIZAR_ESTADO
        commit('SOCKET_deleteMedioIncendio', data)
        //this._vm.$socket.emit('deleteMedioIncendio', data)

        // Si no quedan medios y el incendio está en 'EXTINCION', pasar a 'INICIO'
        let incendio = state.incendios.find(x => x.ID_INCENDIO.equalsIgnoreCase(data.ID_INCENDIO))
        let mediosActuando = false
        if (incendio.ESTADOS[0].TIPO_INCENDIO_ESTADO.VALUE === state.tiposIncendioEstado[1].VALUE) {
          for (let i = 0; i < incendio.SECTORES.length; i++) {
            let sector = incendio.SECTORES[i]

            for (let j = 0; j < incendio.SECTORES[i].MEDIOS.length; j++) {
              let medioSector = sector.MEDIOS[j]

              if (medioSector.FECHA_LLEGADA && !medioSector.FECHA_SALIDA) {
                mediosActuando = true
                break
              }
            }
          }
          if (!mediosActuando) {
            let data = {
              ID_INCENDIO_ESTADO: incendio.ESTADOS[0].ID_INCENDIO_ESTADO,
              ID_INCENDIO: incendio.ESTADOS[0].ID_INCENDIO,
              NUEVO_ESTADO: incendio.ESTADOS[1].TIPO_INCENDIO_ESTADO.INCENDIO_ESTADO
            }
            dispatch('deleteEstadoIncendio', data)
          }
        }
      }

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async editPlanificacionMedio ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.incendio.editPlanificacionMedio(data)

      if (response.status === 200) {
        commit('SOCKET_editPlanificacionMedio', data)
        //this._vm.$socket.emit('editPlanificacionMedio', data)
      }

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  // PLAN OPERACIONES
  async addPlanOperaciones ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.incendio.addPlanOperaciones(data)

      if (response.status === 200) {
        commit('SOCKET_addPlanOperaciones', data)
        //this._vm.$socket.emit('addPlanOperaciones', data)
      }

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },
  async editPlanOperaciones ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.incendio.editPlanOperaciones(data)

      if (response.status === 200) {
        commit('SOCKET_editPlanOperaciones', data)
        //.emit('editPlanOperaciones', data)
      }

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },
  async deletePlanOperaciones ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.incendio.deletePlanOperaciones(data)

      if (response.status === 200) {
        commit('SOCKET_deletePlanOperaciones', data)
        //this._vm.$socket.emit('deletePlanOperaciones', data)
      }

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  }
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default module
