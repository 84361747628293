import request from '../request'

export default {
  getAllTiposAccion: function () {
    return request.get('tipoAccion')
  },

  addTipoAccion: function (data) {
    return request.post('tipoAccion', data)
  },

  updateTipoAccion: function (data) {
    return request.put('tipoAccion', data)
  },

  deleteTipoAccion: function (data) {
    return request.delete('tipoAccion', { params: { tipoAccionId: data.id }})
  }
}