import api from '@/api'
import Vue from 'vue'
import constants from '@/helpers/constants'

let Q = require('q')

const state = {
  unidadesMedios: []
}

const getters = {
  unidadesMedios: (state) => state.unidadesMedios,

  getDocumentoByUnidadMedio: (state) => async (id_unidad_medio) => {
    try {
      let docSave
      const response = await api.unidadMedio.getDocumentoUnidadMedio(id_unidad_medio)

      if (response.status === 200) {
        docSave = {
          id_unidad_medio: id_unidad_medio,
          nombre: response.data.body.nombre,
          documento: response.data.body.ruta_protocolo
        }

        return docSave
      }
    } catch (err) {
      if (err.response && (err.response.status !== 200 && err.response.status !== 500)) {
        Vue.notify({
          title: err.response.statusText,
          text: constants.notificacionesPermisos.MediosRecursos.getDocumentacionMedio,
          type: 'error'
        })
      } else if (err.request) {
        Vue.notify({
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
          type: 'error'
        })
      }
    }
  }
}

const mutations = {
  SET_UNIDADES_MEDIOS (state, data) {
    if (data) {
      state.unidadesMedios = data   
    }
  },

  ADD_UNIDAD_MEDIO (state) {
    this.notify('Nueva unidad de medio', 'Se han añadido nuevas unidades de medio')
  },

  UPDATE_UNIDAD_MEDIO (state) {
    this.notify('Unidad medio actualizada', 'Se ha actualizado la unidad medio')
  },

  DELETE_UNIDAD_MEDIO (state, unidadMedio) {
    let position = state.unidadesMedios.findIndex(x => x.id_unidad_medio === unidadMedio.id_unidad_medio)

    if (position !== -1) {
      state.unidadesMedios.splice(position, 1)
      this.notify('Unidad medio borrado', 'Se han borrado las unidades para el medio ', unidadMedio.Medio.nombre)
    }
  },

  ERROR_AUTORIZACION (state, data) {
    this.notify(data.title, data.text, 'error')
  },

  ERROR_RESPUESTA_SERVER (state, data) {
    this.notify(data.title, data.text, 'error')
  }
}

const actions = {
  async fetchUnidadesMedios ({ commit }) {
    let deferred = Q.defer()
  
    try {
      const response = await api.unidadMedio.getUnidadesMedios()
      if (response.status === 200) {
        commit('SET_UNIDADES_MEDIOS', response.data.body)
      }
  
      deferred.resolve()
    } catch (err) {
      if (err.response && (err.response.status !== 200 && err.response.status !== 500)) {

        commit('ERROR_AUTORIZACION', 
          {
            title: err.response.statusText, 
            text: constants.notificacionesPermisos.MediosRecursos.getUnidadesMedio
          })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500]
        })
      }
      console.error(err)
      deferred.reject(err)
    }
  
    return deferred.promise
  },

  async addUnidadMedio ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.unidadMedio.addUnidadMedio(data)
      if (response.status === 200) {
        commit('ADD_UNIDAD_MEDIO')
      }

      deferred.resolve()
    } catch (err) {
      if (err.response && (err.response.status !== 200 && err.response.status !== 500)) {

        commit('ERROR_AUTORIZACION', 
          {
            title: err.response.statusText, 
            text: constants.notificacionesPermisos.MediosRecursos.nuevaUnidadMedio
          })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500]
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async updateUnidadMedio ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.unidadMedio.updateUnidadMedio(data)
      if (response.status === 200) {
        commit('UPDATE_UNIDAD_MEDIO')
      }

      deferred.resolve()
    } catch (err) {
      if (err.response && (err.response.status !== 200 && err.response.status !== 500)) {

        commit('ERROR_AUTORIZACION', 
          {
            title: err.response.statusText, 
            text: constants.notificacionesPermisos.MediosRecursos.editarUnidadMedio
          })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500]
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async deleteUnidadMedio ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.unidadMedio.deleteUnidadMedio(data)
      if (response.status === 200) {
        commit('DELETE_UNIDAD_MEDIO', data)
      }
      deferred.resolve()
    } catch (err) {
      if (err.response && (err.response.status !== 200 && err.response.status !== 500)) {

        commit('ERROR_AUTORIZACION', 
          {
            title: err.response.statusText, 
            text: constants.notificacionesPermisos.MediosRecursos.borrarUnidadMedio
          })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500]
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  }
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
  
export default module