<template>
  <div class="chat-lateral-menu-component">
    <vx-add-chat 
      :show="isShowAddChat"
      @cancelar="isShowAddChat = false"
    />
    <div class="header">
      <div class="header-start">
        <v-icon @click="$emit('close')" class="icon">mdi-close</v-icon>
        <span>{{ headerTitle }}</span>
      </div>
      <div v-if="canAddChats" class="action">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              @click="isShowAddChat = true"
              fab small
              color="#1A33F4"
              v-bind="attrs" v-on="on" 
            >
              <v-icon
                :color="'white'"
              >
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
          <span>Crear grupo</span>
        </v-tooltip>
      </div>
    </div>
    <div class="content">
      <slot ref="insideComponent" />
    </div>
  </div>
</template>

<script>
import DialogAddChat from './DialogAddChat.vue'

export default {

  components: {
    'vx-add-chat': DialogAddChat
  },

  data: () => ({
    isShowAddChat: false
  }),

  props: {
    isDisplay: Boolean,
    headerTitle: null,
    canAddChats: null,
  },

  computed: {},

}
</script>


<style scoped lang="scss">

.chat-lateral-menu-component {
  display: flex;
  flex-direction: column;
  height: 100%;
  .content {
    flex-grow: 1;
    overflow: hidden;
  }
  .header {
    justify-content: space-between;
    height: 40px;
    flex-shrink: 0;
    width: 100%;
    background-color: #272727;
    display: flex;
    padding: 0 6px;
    align-items: center;
    color: #FFFFFF;
    .icon {
      color: #FFFFFF;
      margin-right: 0.5rem;
    }
    span {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      
    }
    .action{
      margin-top: 1.5rem !important;
      margin: 0.3rem;
    }
  }
}

</style>