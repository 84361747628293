import api from '@/api'

let Q = require('q')

const state = {
  tiposOrganizacion: null
}

const getters = {
  tiposOrganizacion: (state) => state.tiposOrganizacion
}

const mutations = {
  SET_TIPOS_ORGANIZACION (state, organizaciones) {
    state.tiposOrganizacion = organizaciones
  }
}

const actions = {
  async fetchTiposOrganizacion ({ commit }) {
    let deferred = Q.defer()

    try {
      const response = await api.organizacion.getTiposOrganizacion()
      if (response.status === 200) {
        commit('SET_TIPOS_ORGANIZACION', response.data.body)

        deferred.resolve()
      }
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  }
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default module