<template>
  <v-dialog class="dialog-contactos" v-model="value" width="300px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Contactos entidad</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="dialog-content">
        <v-form ref="form">
          <v-container class="contactos-entidad" v-if="entidadRepesentada && entidadRepesentada.Usuario">
            <section v-if="contactos && contactos.length" class="contactos">
              <div class="contacto" v-for="contacto in contactos" :key="contacto.id">
                <label class="name">
                  {{ contacto.nombre }}
                </label>
                <div class="telefonos">
                  <div class="telefono" v-for="telefono in contacto.telefonos" :key="telefono.telefono">
                    {{ telefono.telefono }}
                  </div>
                </div>
                <div class="mails">
                  <div class="mail" v-for="mail in contacto.emails" :key="mail.email">
                    {{ mail.email }}
                  </div>
                </div>
              </div>
            </section>
            <span v-else> No hay contactos de emergencia registrados para esta entidad </span>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import api from '@/api'

export default {

  
  components: {
  },

  props: {
    modelValue: Boolean,
    entidad: Object
  },

  emits: ['update:modelValue'],

  data: () => ({
    isShow: false,
    entidadContactos: [],
    entidadRepesentada: null
  }),

  methods: {
    async fetchEntidadContactos (entidad) {
      this.entidadContactos = []
      const responseContactosEmergencias = await api.contacto.getContactosByEntidad({...entidad, type: true}) 
      if (responseContactosEmergencias.status === 200) {
        this.entidadContactos = responseContactosEmergencias.data.body
          .filter(c => (c.Telefono_contactos && c.Telefono_contactos.length) || (c.Email_contactos && c.Email_contactos.length))
          .map(c => ({...c, isEmergencia: true}))
      }
    },

    fusionarContactos (contacto1, contacto2) {
      const contactoFusionado = {
        id: '',
        nombre: '',
        telefonos: [],
        emails: []
      }
      contactoFusionado.id = contacto1.id
      contactoFusionado.nombre = contacto1.nombre
      contacto1.telefonos.forEach(telefono => {
        if (!contactoFusionado.telefonos.some(t => t === telefono)){
          contactoFusionado.telefonos.push(telefono)
        }
      })
      contacto1.emails.forEach(email => {
        if (!contactoFusionado.emails.some(e => e === email)){
          contactoFusionado.emails.push(email)
        }
      })
      contacto2.telefonos.forEach(telefono => {
        if (!contactoFusionado.telefonos.some(t => t === telefono)){
          contactoFusionado.telefonos.push(telefono)
        }
      })
      contacto2.emails.forEach(email => {
        if (!contactoFusionado.emails.some(e => e === email)){
          contactoFusionado.emails.push(email)
        }
      })
      return contactoFusionado
    }
  },

  computed: {
    contactos () {
      const contactosRegistrados = []
      this.entidadContactos.forEach(contacto => {
        const contactoRegistrado = {
          id: '',
          nombre: '',
          telefonos: [],
          emails: [],
          isEmergencia: false
        }
        contactoRegistrado.id = contacto.id_contacto
        contactoRegistrado.nombre = contacto.nombre
        contactoRegistrado.isEmergencia = contacto.isEmergencia
        contacto.Telefono_contactos.forEach(telefono => {
          if (!contactoRegistrado.telefonos.some(t => t === telefono.numero)){
            contactoRegistrado.telefonos.push({telefono: telefono.numero, isEmergencia: contactoRegistrado.isEmergencia})
          }
        })
        contacto.Email_contactos.forEach(email => {
          if (!contactoRegistrado.emails.some(e => e === email.email)){
            contactoRegistrado.emails.push({email: email.email, isEmergencia: contactoRegistrado.isEmergencia})
          }
        })
        if (!contactosRegistrados.some(c => c.nombre === contactoRegistrado.nombre)){
          contactosRegistrados.push(contactoRegistrado)
        } else {
          const contactoFusionado = this.fusionarContactos(
            contactosRegistrados.find(c => c.nombre === contactoRegistrado.nombre),
            contactoRegistrado
          )
          contactosRegistrados.splice(
            contactosRegistrados.findIndex(c => c.nombre === contactoRegistrado.nombre),
            1,
            contactoFusionado
          )
        }
      })
      return contactosRegistrados
    },
    value: {
      get () {
        return this.modelValue
      },
      set (newValue) {
        this.$emit('update:modelValue', newValue)
      }
    }
  },

  watch: {
    entidad (newValue){
      if (newValue){
        this.entidadRepesentada = newValue
        this.fetchEntidadContactos(this.entidadRepesentada)
      }
    },
  },

}
</script>

<style scoped lang="scss">

.dialog-content {
  max-height: 25rem;
  overflow: auto;
}
.inputText {
  margin-top: -15px;
}
.entidades {
  height: 20rem;
}

.contacto {
  .name {
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
  }
  .telefono, .mail {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
  }
  :not(.name) {
    padding-left: 0.25rem;
  }
}
</style>