<template>
  <div class="content">
    <div class="campos-informacion-adicional">
      <div class="datos">
        <span>
          <!-- NUMERO DE VICTIMAS -->
          <span class="label-title">Número de víctimas:</span>
          {{ emergenciaSelected.victimas }}
        </span>

        <span>
          <!-- VEHICULOS AFECTADOS -->
          <span class="label-title">Vehículos afectados:</span>
          {{ emergenciaSelected.vehiculosAfectados }}
        </span>

        <span>
          <!-- NUMERO DE OPERATIVOS INTERVINIENDO -->
          <span class="label-title">Número de operativos interviniendo:</span>
          {{ emergenciaSelected.operativosIntervencion }}
        </span>

        <span>
          <!-- PERSONAS EVACUADAS -->
          <span class="label-title">Personas evacuadas:</span>
          {{ emergenciaSelected.personasEvacuadas }}
        </span>

        <span>
          <!-- EDIFICIOS E INFRAESTRUCTURAS AFECTADAS -->
          <span class="label-title">Edificios e infraestructuras afectadas:</span>
          {{ emergenciaSelected.infraestructurasAfectadas }}
        </span>

        <span>
          <!-- SERVICIOS INVOLUCRADOS -->
          <span class="label-title">Servicios involucrados:</span>
          {{ emergenciaSelected.serviciosInvolucrados }}
        </span>
      </div>
      <div class="actions">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              fab
              small 
              v-bind="attrs" 
              v-on="on" 
              color="#1A33F4"
              v-show="userCanEditEmergencia"
              @click="$emit('showEditInfoEmergencia')"
            >
              <v-icon color="white">mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Editar información de la emergencia</span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({

  }),

  computed: {
    emergenciaSelected: {
      get () {
        return this.$store.state.emergencia.emergenciaSelected
      }
    },
    userCanEditEmergencia (){
      return this.$store.getters['usuario/rolesUsuario'](['MOD_EMERGENCIAS'])
    },
  }
}
</script>

<style scoped lang="scss">
.content {
  padding: 12px;
  .campos-informacion-adicional{
    width: 100%;
    display: flex;
    gap: 3px;
    .datos {
      width: 100%;
      display: flex;
      gap: 6px;
      flex-direction: column;
      margin-bottom: 9px;
      span {
        line-height: 19px;
        .label-title {
          font-weight: bold;
        }
      }
    }
    .actions {
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      align-items: center;
      gap: 6px;
      margin-bottom: 6px;
    }
  }
  .add-so {
    display: flex;
    justify-content: flex-end;
  }
}

/* Estilo cabecera asignacion de tipo de emergencia */
.colorText {
  color: #003DF6;
  font-weight: bold;
}

/* Estilo divider */
.colorDivider {
  background: #003DF6;
  border-bottom-width: thin;
}

/* Input text */
.inputText {
    margin-top: -18px;
    margin-bottom: -10px
}
.inputTextLast {
    margin-top: -18px;
}

/* Botton actualizar */
.buttonUpdate {
    margin-top: 18px;
    margin-bottom: 5px;
    margin-right: 20px;
}
</style>