export class StringHelper {
    
  static compareString (string1, string2) {
    let str1Normalize = string1.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    let str2Normalize = string2.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

    if (str2Normalize.toLowerCase().indexOf(str1Normalize.toLowerCase()) > -1) {
      return true
    } else {
      return false
    }
  }
}