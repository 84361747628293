<template>
  <v-dialog v-model="show" scrollable persistent max-width="500px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Contraseña expirada</v-toolbar-title>

        <v-spacer />
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn icon dark @click="cancelar" v-bind="attrs" v-on="on" :disabled="isLoading">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>Cerrar ventana de actualización de contraseña</span>
        </v-tooltip>
      </v-toolbar>

      <v-card-text>
        <v-form ref="form" v-model="isValid">
          <v-container>
            <!-- EMAIL/USERNAME -->
            <v-row class="mt-5" dense align="center" justify="center">
              <v-col>
                <v-text-field
                  label="Email*"
                  v-model="email"
                  :rules="emailRules"
                  outlined
                  dense
                />
              </v-col>
            </v-row>

            <!-- CONTRASEÑA ACTUAL -->
            <v-row dense align="center" justify="center">
              <v-col>
                <v-text-field 
                  label="Contraseña actual*"
                  v-model="currentPassword"
                  :rules="[globalRules.required]" 
                  :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'" 
                  :type="showCurrentPassword ? 'text' : 'password'" 
                  @click:append="showCurrentPassword = !showCurrentPassword" 
                  outlined
                  dense
                />
              </v-col>
            </v-row>

            <!-- NUEVA CONTRASEÑA -->
            <v-row dense align="center" justify="center">
              <v-col>
                <v-text-field 
                  label="Nueva contraseña*"
                  v-model="newPassword"
                  :rules="[securePasswordRules.complete]" 
                  :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'" 
                  :type="showNewPassword ? 'text' : 'password'" 
                  @click:append="showNewPassword = !showNewPassword" 
                  outlined
                  dense
                />
              </v-col>
            </v-row>

            <!-- REPETIR CONTRASEÑA -->
            <v-row dense align="center" justify="center">
              <v-col>
                <v-text-field 
                  label="Repetir nueva contraseña*"
                  v-model="repeatNewPassword"
                  :rules="[securePasswordRules.complete, 
                           passwordRules.match(repeatNewPassword, newPassword)]" 
                  :append-icon="showRepeatNewPassword ? 'mdi-eye' : 'mdi-eye-off'" 
                  :type="showRepeatNewPassword ? 'text' : 'password'" 
                  @click:append="showRepeatNewPassword = !showRepeatNewPassword" 
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="cancelar()"
          text color="red"
          :disabled="isLoading"
        >
          Cancelar
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-btn
          v-show="isValid"
          @click="aceptar()"
          text color="green"
          :disabled="isLoading"
          :loading="isLoading"
        >
          Actualizar
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    show: Boolean,
    usuario: String
  },

  watch: {
    show (newValue) {
      if (newValue) {
        this.email = this.usuario ? this.usuario : null
      }
    }
  },

  data: () => ({
    isValid: false,
    email: null,
    currentPassword: null,
    showCurrentPassword: false,
    newPassword: null,
    showNewPassword: false,
    showRepeatNewPassword: false,
    repeatNewPassword: null,
    isLoading: false
  }),

  methods: {
    ...mapActions('auth', [
      'changeExpiredPassword'
    ]),

    cancelar () {
      this.$emit('cancelar')
      this.$refs.form.reset()
    },

    async aceptar () {
      try {
        this.isLoading = true
        const responseUpdate = await this.changeExpiredPassword({
          username: this.email,
          currentPassword: this.currentPassword,
          newPassword: this.newPassword
        })
        responseUpdate && this.notificarPasswordActualizada()
        responseUpdate && this.$emit('aceptar', this.newPassword)
        responseUpdate && this.$refs.form.reset()
        this.isLoading = false
      } catch (err) {
        if (err && err.response) {
          switch (err.response.status) {
          case 500:
            this.$emit('mensajeError', err.response.data)
            break
          }
        }
        this.isLoading = false
      }
    },

    notificarPasswordActualizada () {
      this.$notify({
        title: 'Contraseña actualizada',
        text: 'La contraseña expirada ha sido actualizada, inicie sesión con la nueva contraseña',
      })
    }
  }
}
</script>

<style>

</style>