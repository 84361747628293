import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import Login from '@/components/Login'

import MainLayout from '@/components/layout/MainLayout'
import MainMap from '@/components/map/Main'

// Funiones MainHeader
import Medios from '@/components/medios/Medios'
import Usuarios from '@/components/usuarios/Usuarios'
import Contactos from '@/components/contactos/Contactos'
import ListaContactos from '@/components/contactos/ListaContactos'
import MediosRecursos from '@/components/medios_recursos/MediosRecursos'
import Entidades from '@/components/entidades/Entidades'
import Delegaciones from '@/components/entidades/delegaciones/Delegaciones'
import Documentacion from '@/components/documentacion/Documentacion'
import Acciones from '@/components/acciones/Acciones'
import TipoEmergencia from '@/components/tipo_emergencia/TipoEmergencia'
import AsignarAccion from '@/components/acciones/AsignarAccion'
import Cargos from '@/components/cargos/Cargos'

import NotFound from '@/components/NotFound'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    component: MainLayout,
    children: [
      {
        path: '',
        name: 'Map',
        component: MainMap
      },
      // {
      //   path: 'medios',
      //   name: 'Medios',
      //   component: Medios
      // },
      {
        path: 'usuarios',
        name: 'Usuarios',
        component: Usuarios
      },
      {
        path: 'contactos',
        name: 'Contactos',
        component: Contactos
      },
      {
        path: 'contactos/listaContactos',
        name: 'Lista de contactos',
        component: ListaContactos
      },
      {
        path: 'medios_recursos',
        name: 'Medios y Recursos',
        component: MediosRecursos
      },
      {
        path: 'entidades',
        name: 'Entidades',
        component: Entidades
      },
      {
        path: 'entidades/delegaciones',
        name: 'Delegaciones',
        component: Delegaciones,
        props: true
      },
      {
        path: 'documentacion',
        name: 'Documentación',
        component: Documentacion
      },
      {
        path: 'acciones',
        name: 'Acciones',
        component: Acciones
      },
      {
        path: 'tipo_emergencia',
        name: 'Tipo emergencia',
        component: TipoEmergencia
      },
      {
        path: 'asignar_accion',
        name: 'Asignar acción',
        component: AsignarAccion
      },
      {
        path: 'cargos',
        name: 'Cargos',
        component: Cargos
      }
    ]
  },
  { // Capturar rutas no existentes
    path: '*',
    component: NotFound
  }
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let token = store.getters['auth/accessToken']
  if (!token && to.name !== 'Login') {
    router.push('/login')
  } else if (token && to.name === 'Login') {
    router.push('/')
  }

  next()
})

export default router
