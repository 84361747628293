<template>
  <v-dialog v-model="show" scrollable persistent max-width="500px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title> {{ accionDialog }} </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-form ref="form" v-model="isValid">
          <v-container>
            <v-row class="mt-5">
              <v-col>
                <v-text-field
                  type="text"
                  label="Nombre*"
                  placeholder="Nombre del cargo"
                  v-model="nombre"
                  counter maxlength="100" 
                  outlined dense
                  :rules="[globalRules.required]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  type="text"
                  label="Descripcion"
                  placeholder="Descripción del cargo"
                  v-model="descripcion"
                  counter maxlength="100"
                  outlined dense
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn text color="green" :disabled="!isValid" @click="aceptar">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    isEdit: Boolean,
    editedCargo: Object
  },

  data: () => ({
    isValid: false,
    isDuplicated: true,

    nombre: null,
    descripcion: null
  }),

  computed: {
    accionDialog () {
      return this.isEdit ? 'Editar cargo' : 'Nuevo cargo'
    }
  },

  watch: {
    async show () {
      if (this.show) {
        this.isEdit && this.cargarDatos()
      }
    }
  },

  methods: {
    cancelar () {
      this.$emit('cancelar')
      this.$refs.form.reset()
    },

    async aceptar () {
      let cargo = this.editedCargo ? JSON.parse(JSON.stringify(this.editedCargo)) : {}
      cargo.nombre = this.nombre
      cargo.descripcion = this.descripcion

      // Validar que el nombre introducido no esté repetido en bd y notificar al usuario si se repite

      // Si estamos añadiendo cargo y es duplicado
      if (!this.isEdit) {
        this.isDuplicated = await this.comprobarNombreCargoDuplicado(cargo)
        this.isDuplicated && this.notificarNombreCargoDuplicado(cargo.nombre)
      } else if (this.isEdit && (this.editedCargo.nombre === cargo.nombre)) {
        // Si estamos editando el cargo y es el mismo nombre
        this.isDuplicated = false
      } else if (this.isEdit && (this.editedCargo.nombre !== cargo.nombre)) {
        // Si estamos editando el cargo y el nombre ha cambiado
        this.isDuplicated = await this.comprobarNombreCargoDuplicado(cargo)
        this.isDuplicated && this.notificarNombreCargoDuplicado(cargo.nombre)
      }

      // Añadir validacion de que no se repite el nombre del cargo
      if (this.isEdit && !this.isDuplicated) {
        // Editando cargo
        this.$refs.form.reset()
        this.$emit('updateCargo', cargo)
      } else if (!this.isEdit && !this.isDuplicated) {
        // Nuevo cargo    
        this.$emit('addCargo', cargo)
        this.$refs.form.reset()
      }
    },

    notificarNombreCargoDuplicado (nombreCargo) {
      this.$notify({
        title: 'Cargo duplicado',
        text: `Ya existe un cargo con el nombre "${nombreCargo}" en la aplicación`,
        type: 'warning'
      })
    },

    async comprobarNombreCargoDuplicado (cargo) {
      return await this.$store.getters['cargo/compruebaSiExisteCargo'](cargo.nombre)
    },

    cargarDatos () {
      this.nombre = this.editedCargo.nombre
      this.descripcion = this.editedCargo.descripcion
    }
  }
}
</script>

<style>

</style>