<template>
  <div id="buttonsMap" ref="buttonsMap" class="buttonsMap">
    <!-- Centrar mapa -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs" v-on="on" @click="eventButton('home')">
          <v-icon>esri-icon-home</v-icon>
        </v-btn>
      </template>
      <span>Centrar</span>
    </v-tooltip>

    <!-- Zoom+ -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-show="!$vuetify.breakpoint.mobile" v-bind="attrs" v-on="on" @click="eventButton('zoomIn')">
          <v-icon>esri-icon-plus</v-icon>
        </v-btn>
      </template>
      <span>Ampliar zoom</span>
    </v-tooltip>

    <!-- Zoom- -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-show="!$vuetify.breakpoint.mobile" v-bind="attrs" v-on="on" @click="eventButton('zoomOut')">
          <v-icon>esri-icon-minus</v-icon>
        </v-btn>
      </template>
      <span>Disminuir zoom</span>
    </v-tooltip>

    <!-- Orientar norte -->
    <!-- <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs" v-on="on" @click="eventButton('orientar')">
          <v-icon>esri-icon-compass</v-icon>
        </v-btn>
      </template>
      <span>Orientar</span>
    </v-tooltip> -->

    <!-- Capas -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs" v-on="on" @click="eventButton('layerList')" v-show="comprobarPermisos(['VISOR'])">
          <v-icon>esri-icon-layers</v-icon>
        </v-btn>
      </template>
      <span>Capas</span>
    </v-tooltip>

    <!-- Leyenda -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs" v-on="on" @click="eventButton('legend')">
          <v-icon>esri-icon-legend</v-icon>
        </v-btn>
      </template>
      <span>Leyenda</span>
    </v-tooltip>

    <!-- Mapas base -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-speed-dial v-model="sdBasemaps" direction="left" transition="slide-x-reverse-transition">
            <template #activator>
              <v-btn v-model="sdBasemaps" fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall">
                <v-icon v-if="sdBasemaps">mdi-close</v-icon>
                <v-icon v-else>esri-icon-basemap</v-icon>
              </v-btn>
            </template>

            <!-- Botones mapas base -->
            <v-tooltip bottom v-for="(basemap, i) in basemaps" :key="i">
              <template #activator="{ on2, attrs2 }">
                <!-- TODO: el tooltip hace que se rompa la animacion de cerrar -->
                <v-btn fab icon v-bind="attrs2" v-on="on2" color="transparent" @click="setBaseMap(basemap)" :small="isButtonXSmall" :large="!isButtonXSmall">
                  <v-avatar class="basemap">
                    <img :src="require(`@/assets/basemap/${basemap.icon}`)" :title="`${basemap.label}`" height="100%" width="100%">
                  </v-avatar>
                </v-btn>
              </template>
              <span><!-- {{ basemap.label }} --></span>
            </v-tooltip>
          </v-speed-dial>
        </div>
      </template>
      <span>Cambiar mapa</span>
    </v-tooltip>

    <!-- Buscar coordenadas -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs" v-on="on" @click="eventButton('searchCoords')">
          <img :src="require(`@/assets/icons/search_web.svg`)" height="24px" width="24px">
        </v-btn>
      </template>
      <span>Buscar coordenadas</span>
    </v-tooltip>

    <!-- Vista 2D -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn v-show="!is2D" fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs" v-on="on" @click="eventButton('2D')">
          <span class="text-button font-weight-bold">2D</span>
        </v-btn>
      </template>
      <span>Vista 2D</span>
    </v-tooltip>

    <!-- Compartir mapa -->
    <!-- <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs" v-on="on" :color="shareMapButtonColor" @click="shareMap">
          <v-icon>mdi-monitor-share</v-icon>
        </v-btn>
      </template>
      <span>Compartir mapa</span>
    </v-tooltip> -->

    <!-- Dibujar compartir mapa -->
    <v-tooltip :bottom="!$vuetify.breakpoint.mobile">
      <template #activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-speed-dial v-model="sdGraficos" direction="left" transition="slide-x-reverse-transition">
            <v-tooltip bottom>
              <template #activator="{ on2, attrs2 }">
                <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs2" v-on="on2" @click="drawGraphicShareMap('reset')" :title="'Borrar'">
                  <v-icon>esri-icon-trash</v-icon>
                </v-btn>
              </template>
              <span>Borrar</span>
            </v-tooltip>
            
            <template #activator>
              <v-btn v-model="sdGraficos" fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall">
                <v-icon v-if="sdGraficos">mdi-close</v-icon>
                <img v-else :src="require('@/assets/icons/dibujar_grafico.svg')">
              </v-btn>
            </template>

            <!-- Botones graficos -->
            <v-tooltip bottom>
              <template #activator="{ on2, attrs2 }">
                <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs2" v-on="on2" @click="drawGraphicShareMap('point')" :title="'Punto'">
                  <v-icon>esri-icon-blank-map-pin</v-icon>
                </v-btn>
              </template>
              <span>Punto</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on2, attrs2 }">
                <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs2" v-on="on2" @click="drawGraphicShareMap('multipoint')" :title="'Multi punto'">
                  <img :src="require(`@/assets/icons/seleccion_multipunto.svg`)" height="24px" width="24px">
                </v-btn>
              </template>
              <span>Multi punto</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on2, attrs2 }">
                <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs2" v-on="on2" @click="drawGraphicShareMap('polyline')" :title="'Línea'">
                  <v-icon size="20">esri-icon-polyline</v-icon>
                </v-btn>
              </template>
              <span>Línea</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on2, attrs2 }">
                <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs2" v-on="on2" @click="drawGraphicShareMap('polygon')" :title="'Polígono'">
                  <img :src="require('@/assets/icons/dibujar_poligono.svg')">
                </v-btn>
              </template>
              <span>Polígono</span>
            </v-tooltip>
          </v-speed-dial>
        </div>
      </template>
      <span v-if="!$vuetify.breakpoint.mobile">Dibujar gráfico</span>
    </v-tooltip>

    <!-- Capturar mapa -->
    <!-- <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs" v-on="on" @click="eventButton('captureMap')">
          <v-icon>esri-icon-media</v-icon>
        </v-btn>
      </template>
      <span>Capturar mapa</span>
    </v-tooltip> -->

    <!-- Medicion -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-speed-dial v-model="sdMedicion" v-show="showBtnGraphics" direction="left" transition="slide-x-reverse-transition">
            <template #activator>
              <v-btn v-model="sdMedicion" fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall">
                <v-icon v-if="sdMedicion">mdi-close</v-icon>
                <v-icon v-else>esri-icon-measure</v-icon>
              </v-btn>
            </template>

            <!-- Botones graficos -->
            <v-tooltip bottom>
              <template #activator="{ on2, attrs2 }">
                <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs2" v-on="on2" @click="measure('distance')">
                  <v-icon>esri-icon-measure-line</v-icon>
                </v-btn>
              </template>
              <span>Medir distancia</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on2, attrs2 }">
                <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs2" v-on="on2" @click="measure('area')">
                  <v-icon>esri-icon-measure-area</v-icon>
                </v-btn>
              </template>
              <span>Medir área</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on2, attrs2 }">
                <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs2" v-on="on2" @click="clearMeasurement()">
                  <v-icon>esri-icon-trash</v-icon>
                </v-btn>
              </template>
              <span>Borrar medidas</span>
            </v-tooltip>
          </v-speed-dial>
        </div>
      </template>
      <span>Medir</span>
    </v-tooltip>

    <!-- Atributos mapa -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs" v-on="on" @click="eventButton('attributesMap')">
          <img :src="require(`@/assets/icons/atributos_del_mapa.svg`)" height="24px" width="24px">
        </v-btn>
      </template>
      <span>Atributos del mapa</span>
    </v-tooltip>

    <v-tooltip bottom v-show="!$vuetify.breakpoint.mobile">
      <template #activator="{ on, attrs }">
        <v-btn
          id="hideMapButton"
          :class="{'active': isOverviewMapActive}"
          fab tile 
          :x-small="isButtonXSmall" 
          :small="!isButtonXSmall" 
          v-bind="attrs" v-on="on" 
          @click="eventButton('showHideOverviewMap')"
        >
          <img :src="require(`@/assets/icons/visor_mini_mapa.svg`)" height="24px" width="24px">
        </v-btn>
      </template>
      <span>Mapa de localización</span>
    </v-tooltip>

    <!-- Simulacion
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs" v-on="on" @click="eventButton('showDialogSimulacion')">
          <v-icon>mdi-pine-tree-fire</v-icon>
        </v-btn>
      </template>
      <span>Simulacion</span>
    </v-tooltip> -->

    <!--
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs" v-on="on" @click="eventButton('showEmergencias')">
          <v-icon>mdi-car-brake-alert</v-icon>
        </v-btn>
      </template>
      <span>Emergencias activas</span>
    </v-tooltip>
  -->

    <!-- Geofencing
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn fab tile :x-small="isButtonXSmall" :small="!isButtonXSmall" v-bind="attrs" v-on="on" @click="eventButton('showDialogGeofencing')">
          <v-icon>mdi-alert</v-icon>
        </v-btn>
      </template>
      <span>Geofencing</span>
    </v-tooltip> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import api from '@/api'
import * as ArcGIS from '../../helpers/ArcGIS'
import constants from '../../helpers/constants'


export default {
  data: () => ({
    sdBasemaps: false,
    sdGraficos: false,
    sdMedicion: false,

    is2D: true,

    testHtml: 'mapa'
  }),

  props: {
    isOverviewMapActive: Boolean
  },

  computed: {
    ...mapGetters('shareMap', [
      'isSharingMap',
      'requestShareMap'
    ]),

    ...mapGetters('map', [
      'paramsComunidad',
      'basemaps'
    ]),

    ...mapGetters('usuario', [
      'rolesUsuario'
    ]),

    isButtonXSmall () {
      return this.$vuetify.breakpoint.name === 'sd' || this.$vuetify.breakpoint.mobile
    },

    // shareMapButtonColor () {
    //   return this.isSharingMap ? 'red' : 'white'
    // },

    showBtnGraphics () {
      return this.isSharingMap && !this.requestShareMap
    }
  },

  methods: {
    eventButton (event) {
      this.$log.debug('typeEventButton', event)

      switch (event) {
      case 'home':
        ArcGIS.setExtentHome(constants.mapExtent)
        break
      // case 'orientar':
      //   ArcGIS.centerCompass()
      //   break
      case 'searchCoords':
        this.$emit('showSearchCoords')
        break
      case 'layerList':// 
        this.$emit('showLayerList')
        break
      case 'legend':
        this.$emit('showLegend')
        break
      case '2D':
        this.is2D = !this.is2D
        ArcGIS.use2D()
        break
      case 'zoomIn':
        ArcGIS.setZoom(+1)
        break
      case 'zoomOut':
        ArcGIS.setZoom(-1)
        break
      case 'attributesMap':
        this.$eventHub.$emit('attributesMap')
        break
      // case 'captureMap':
      //   this.captureMap()
      //   break
      case 'showDialogSimulacion':
        this.$emit('showDialogSimulacion')
        break
      case 'showDialogEmergencias':
        this.$emit('showDialogEmergencias')
        break

      case 'showDialogGeofencing':
        this.$emit('showDialogGeofencing')
        break
      case 'showHideOverviewMap':
        this.$emit('showHideOverviewMap')
        break
      default:
        console.error('Boton desconocido:', event)
      }
    },

    changeMapView () {
      if (this.is2D) {
        this.eventButton('3D')
      } else {
        this.eventButton('2D')
      }

      this.is2D = !this.is2D
    },

    setBaseMap (basemap) {
      ArcGIS.setBaseMap(basemap)
      ArcGIS.setExtent(constants.mapExtent)
    },

    shareMap () {
      // this.$emit('shareMap', !this.isSharingMap)
      this.$eventHub.$emit('shareMap', !this.isSharingMap)
    },

    drawGraphicShareMap (type) {
      let event = {
        function: type
      }

      ArcGIS.paintEventShareMap(event)
    },

    moverBotonesMap () {
      let divButtons = this.$refs.buttonsMap
      divButtons.classList.remove('buttonsMapDisplace')
      divButtons.classList.add('buttonsMap')
    },

    // async captureMap () {
    //   let base64Img = await ArcGIS.captureMap()

    //   this.$root.$confirmDialog.open('¿Está seguro?', 'Enviar captura a Telegram').then(result => {
    //     if (result) {
    //       let msg = {
    //         image: base64Img.substr(22),
    //         user: this.$store.getters['usuario/currentUsuario'].USUARIO
    //       }

    //       api.others.sendMapCapture(msg)
    //     }
    //   })
    // },

    measure (type) {
      ArcGIS.measure(type)
    },
    clearMeasurement () {
      ArcGIS.clearMeasurement()
    },

    comprobarPermisos (listaRolesPermisos) {
      return this.$store.getters['usuario/rolesUsuario'](listaRolesPermisos)
    },
  },

  mounted () {
    // this.$eventHub.$on('moveButtonsMap', this.moverBotonesMap)
  }
}
</script>

<style lang="scss" scoped>
  .buttonsMap {
    position: absolute;
    top: 70px;
    right: 0px;
    /* right: 354px; Mover a la izquierda al abrir el menu de capas */
    /* overflow-y: auto; */
    height: calc(100% - 120px) !important; /* 26 px footer */
  }

  .buttonsMapDisplace {
    position: absolute;
    top: 70px;
    right: 354px; /*Mover a la izquierda al abrir el menu de capas*/
    /* overflow-y: auto; */
    height: calc(100% - 120px) !important; /* 26 px footer */
  }

  #buttonsMap {
    z-index: 1;
    button {
      display: block;
      margin-top: 7px;
    }
    .basemap {
      box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
    }
    #hideMapButton {
      @media (max-width: 600px) {
        visibility: hidden;
      }
      // &:focus::before{
      //   opacity: 0;
      // }
      // &.active::before {
      //   opacity: 0.24;
      // }
    }
  }

  /** Botón de mapas base */
   .v-speed-dial {
    position: relative;
    z-index: 0;
  }
</style>
