<template>
  <v-container id="mainContent" fluid pa-0 class="grey lighten-1">
    <!-- <vx-left-menu/> -->
    <main-header id="mainHeader" />
    <main-content id="mainContent" />
  </v-container>
</template>

<script>
// import LeftMenu from './LeftMenu'

import MainHeader from './MainHeader'
import MainContent from './MainContent'

export default {
  components: {
    // 'vx-left-menu': LeftMenu,
    'main-header': MainHeader,
    'main-content': MainContent
  }
}
</script>

<style scoped>
  #mainContent { /* Fill-height centra los items verticalmente */
    align-items: unset !important;
    height: 100%;
    /*display: flex;
    flex-direction: column;*/
  }

  /* ::v-deep .container {
    width: 100%;
    padding: 12px;
    margin-right: auto;
    margin-left: auto;
    height: 100%;
  } */
</style>
