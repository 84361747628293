var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":"","height":"100%"}},[_c('v-toolbar',{staticStyle:{"z-index":"2"},attrs:{"color":"primaryLight","dark":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Gestión de usuarios")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.comprobarPermisos(['MOD_ACCIONES'])),expression:"comprobarPermisos(['MOD_ACCIONES'])"}],staticStyle:{"z-index":"2"},attrs:{"id":"create"}},[_c('v-speed-dial',{attrs:{"top":_vm.top,"bottom":_vm.bottom,"right":_vm.right,"left":_vm.left,"direction":_vm.direction,"open-on-hover":_vm.hover,"transition":_vm.transition,"title":"Menú de acciones"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","dark":"","fab":""},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[(_vm.fab)?_c('v-icon',[_vm._v(" mdi-close ")]):_c('img',{attrs:{"src":require('@/assets/icons/menu_emergente.svg')}})],1)]},proxy:true}]),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.comprobarPermisos(['MOD_USUARIOS'])),expression:"comprobarPermisos(['MOD_USUARIOS'])"}],attrs:{"fab":"","height":"50","width":"50","color":"primary"},on:{"click":_vm.showAddUsuarioDialog}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Añadir usuario")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.comprobarPermisos(['MOD_USUARIOS'])),expression:"comprobarPermisos(['MOD_USUARIOS'])"}],staticClass:"iconsSpeedDial",attrs:{"fab":"","height":"50","width":"50","color":"primary","to":"cargos"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-clipboard-account")])],1)]}}])},[_c('span',[_vm._v("Gestión cargos")])])],1)],1)],1),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6","md":"3","sm":"3"}},[_c('v-text-field',{class:{'inputSelectMobile': _vm.$vuetify.breakpoint.mobile},attrs:{"append-icon":"mdi-magnify","label":"Búsqueda","dense":_vm.$vuetify.breakpoint.mobile,"outlined":"","clearable":"","hide-details":""},on:{"input":_vm.searchUsuarios},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"6","md":"3","sm":"3"}},[_c('v-select',{class:{'inputSelectMobile': _vm.$vuetify.breakpoint.mobile},attrs:{"label":"Rol","items":_vm.tiposRol,"menu-props":{ bottom: true, offsetY: true },"item-text":"nombre","item-value":"nombre","return-object":"","dense":_vm.$vuetify.breakpoint.mobile,"outlined":"","hide-details":"","clearable":true},model:{value:(_vm.rolSelected),callback:function ($$v) {_vm.rolSelected=$$v},expression:"rolSelected"}})],1),_c('v-col',{class:_vm.$vuetify.breakpoint.mobile ? 'checkboxMobile' : '',attrs:{"cols":"12","md":"3","sm":"6"}},[_c('v-checkbox',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" Mostrar usuarios deshabilitados. ")])]},proxy:true}]),model:{value:(_vm.showUsuariosBorrados),callback:function ($$v) {_vm.showUsuariosBorrados=$$v},expression:"showUsuariosBorrados"}})],1)],1),_c('v-row',[_c('v-data-table',{class:{
            'table': !_vm.$vuetify.breakpoint.mobile,
            'tableMobile': _vm.$vuetify.breakpoint.mobile},attrs:{"height":"100%","headers":_vm.headers,"items":_vm.usuariosFilter ? 
            _vm.usuariosFilter.filter(function (x) { return x.borrado === _vm.showUsuariosBorrados; }) : 
            _vm.usuarios ? _vm.usuarios.filter(function (x) { return x.borrado === _vm.showUsuariosBorrados; }) : 
            [],"loading":_vm.isLoading,"loading-text":"Cargando datos... por favor, espere","fixed-header":"","hide-default-footer":"","page":_vm.currentPage,"items-per-page":_vm.itemsPerPage},on:{"update:page":function($event){_vm.currentPage=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('p',[_vm._v("Datos no disponibles.")])]},proxy:true},{key:"item.estado",fn:function(ref){
            var item = ref.item;
return [(item.estado === false)?_c('span',[_vm._v("Activo")]):_c('span',[_vm._v("No activo")])]}},{key:"item.Rols",fn:function(ref){
            var item = ref.item;
return _vm._l((item.Rols),function(subitem,iSub){return _c('div',{key:subitem.id_rol},[(iSub < item.Rols.length - 1)?_c('span',[_vm._v(_vm._s(subitem.nombre)+", ")]):_c('span',[_vm._v(_vm._s(subitem.nombre))])])})}},{key:"item.fechastamp",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.fechastamp)))])]}},{key:"item.UsuarioModificador",fn:function(ref){
            var item = ref.item;
return [(item.UsuarioModificador)?_c('span',[_vm._v(" "+_vm._s(item.UsuarioModificador.nombre + ' ' + item.UsuarioModificador.apellidos)+" ")]):_vm._e()]}},{key:"item.ACCIONES",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.comprobarPermisos(['MOD_USUARIOS']) && !item.borrado),expression:"comprobarPermisos(['MOD_USUARIOS']) && !item.borrado"}],attrs:{"icon":"","disabled":!_vm.userIsSameEntity(item) && !_vm.comprobarPermisos([])},on:{"click":function($event){return _vm.showEditUsuarioDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"teal"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.comprobarPermisos([]) && !item.borrado),expression:"comprobarPermisos([]) && !item.borrado"}],attrs:{"icon":""},on:{"click":function($event){return _vm.acceptDeleteUsuario(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Borrar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.comprobarPermisos([]) && !item.borrado),expression:"comprobarPermisos([]) && !item.borrado"}],attrs:{"icon":""},on:{"click":function($event){return _vm.showEditPassUsuarioDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"warning"}},[_vm._v("mdi-lock-reset")])],1)]}}],null,true)},[_c('span',[_vm._v("Resetear contraseña")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.comprobarPermisos(['MOD_USUARIOS']) && item.borrado),expression:"comprobarPermisos(['MOD_USUARIOS']) && item.borrado"}],attrs:{"icon":""},on:{"click":function($event){return _vm.acceptReactivateUsuario(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-account-reactivate")])],1)]}}],null,true)},[_c('span',[_vm._v("Reactivar usuario")])])]}}],null,true)})],1),_c('v-row',[(!_vm.$vuetify.breakpoint.mobile)?_c('v-col',{attrs:{"xs":"2","sm":"3","md":"2"}},[_c('v-row',[_c('div',{staticClass:"text-center align-center mt-6 pl-6 ml-6"},[_c('span',[_vm._v("Total "+_vm._s(_vm.totalRecords)+" registros")])])])],1):_vm._e(),_c('v-col',{attrs:{"xs":"1","sm":"4","md":"3"}},[_c('v-row',[_c('v-col',{staticClass:"textMostrar",attrs:{"sm":"4","md":"4"}},[_c('div',[_c('span',[_vm._v("Mostrar")])])]),_c('v-col',{attrs:{"xs":"4","sm":"5","md":"4"}},[_c('v-select',{class:{
                  'select': !_vm.$vuetify.breakpoint.mobile,
                  'selectMobile': _vm.$vuetify.breakpoint.mobile},attrs:{"dense":"","outlined":"","hide-details":"","items":_vm.perPageOptions},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"6"}},[_c('div',[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])],1)],1)],1),_c('vx-dialog-add-edit-usuario',{attrs:{"show":_vm.showDialog,"isEdit":_vm.isEdit,"isEditPass":_vm.isEditPass,"editedUsuario":_vm.editUsuario},on:{"cancelar":function($event){_vm.showDialog = false},"cancelarPass":function($event){_vm.isEditPass = false},"addUsuario":_vm.acceptAddUsuario,"editUsuario":_vm.acceptEditUsuario,"resetPassword":_vm.acceptResetPassword}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }