var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vx-add-situacion-operativa-dialog',{attrs:{"show":_vm.showEditFase,"emergencia":_vm.emergenciaSelected},on:{"close":function($event){_vm.showEditFase = false},"selectedSituacionOperativa":_vm.updateSituacionOperativa}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"campos-emergencia"},[_c('div',{staticClass:"datos"},[_c('span',[_c('span',{staticClass:"label-title"},[_vm._v("Coordenadas:")]),_vm._v(" "+_vm._s(_vm.emergenciaSelected.latitude)+", "+_vm._s(_vm.emergenciaSelected.longitude)+" ")]),_c('span',[_c('span',{staticClass:"label-title"},[_vm._v("Descripcion:")]),_vm._v(" "+_vm._s(_vm.emergenciaSelected.descripcion)+" ")]),(_vm.emergenciaSelected.TipoEmergencium)?_c('span',[_c('span',{staticClass:"label-title"},[_vm._v("Tipo de emergencia:")]),_vm._v(" "+_vm._s(_vm.emergenciaSelected.TipoEmergencium.nombre)+" ")]):_vm._e(),_c('span',[_c('span',{staticClass:"label-title"},[_vm._v("Fecha de inicio:")]),_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.emergenciaSelected.fechaCreacion))+" ")]),_c('span',[_c('span',{staticClass:"label-title"},[_vm._v("Fecha de activación:")]),_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.emergenciaSelected.fechaActivacion))+" ")]),_c('span',[_c('span',{staticClass:"label-title"},[_vm._v("Riesgos:")]),_vm._v(" "+_vm._s(_vm.emergenciaSelected.TipoRiesgos && _vm.showRiesgosEmergencia(_vm.emergenciaSelected.TipoRiesgos))+" ")])]),_c('div',{staticClass:"actions"},[(_vm.userCanEditEmergencia && !_vm.$vuetify.breakpoint.mobile)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"#1A33F4"},on:{"click":function($event){return _vm.$emit('showEditEmergencia')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-pencil")])],1)]}}],null,false,4079625014)},[_c('span',[_vm._v("Editar emergencia")])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.userCanEditEmergencia && _vm.$vuetify.breakpoint.mobile),expression:"userCanEditEmergencia && $vuetify.breakpoint.mobile"}],staticStyle:{"z-index":"2"},attrs:{"id":"create"}},[_c('v-speed-dial',{attrs:{"top":_vm.top,"bottom":_vm.bottom,"right":_vm.right,"left":_vm.left,"direction":_vm.direction,"open-on-hover":_vm.hover,"transition":_vm.transition},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","dark":"","fab":"","small":""},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[(_vm.fab)?_c('v-icon',[_vm._v(" mdi-close ")]):_c('img',{attrs:{"src":require('@/assets/icons/menu_emergente.svg')}})],1)]},proxy:true}]),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[(_vm.userCanEditEmergencia)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"#1A33F4"},on:{"click":function($event){return _vm.$emit('showEditEmergencia')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-pencil")])],1)]}}],null,false,4079625014)},[_c('span',[_vm._v("Editar emergencia")])]):_vm._e(),(_vm.userCanEditEmergencia)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"#1A33F4"},on:{"click":_vm.openAddSituacionOperativa}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-plus")])],1)]}}],null,false,2235460742)},[_c('span',[_vm._v("Añadir situación operativa a emergencia")])]):_vm._e()],1)],1)],1)]),_c('div',{staticClass:"estados"},[_c('v-data-table',{staticClass:"table",attrs:{"sort-by":['fechastamp'],"headers":_vm.headers,"items":_vm.situacionesOperativasEmergencia,"height":_vm.mobile ? 400 : 160,"hide-default-footer":"","disable-pagination":"","fixed-header":"","dense":"","loading":_vm.isLoading,"item-key":"id","single-select":true},on:{"click:row":_vm.emergenciaSituacionClicked},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('p',[_vm._v("Datos no disponibles.")])]},proxy:true},{key:"item.fecha_declaracion",fn:function(ref){
var item = ref.item;
return [(item.tiene_hora)?_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.fecha_declaracion)))]):_c('span',[_vm._v(" "+_vm._s(_vm.fechaSinHoraFormateada(item.fecha_declaracion))+" ")])]}},{key:"header.ACCIONES",fn:function(){return [(_vm.userCanEditEmergencia)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.openAddSituacionOperativa}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus-circle")])],1)]}}],null,false,960649186)},[_c('span',[_vm._v("Añadir situacion operativa a emergencia")])]):_vm._e()]},proxy:true},{key:"item.fechastamp",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.fechastamp)))])]}},{key:"item.FASE",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fase-container"},[_c('span',{staticClass:"dot",class:{
                'green': item.SituacionOperativa.codigo == 0,
                'red':item.SituacionOperativa.codigo == 1,
                'blue': item.SituacionOperativa.codigo == 3,
                'strong-red': item.SituacionOperativa.codigo == 2,
                'purple': item.SituacionOperativa.codigo == 4
              }}),_c('span',{class:_vm.$vuetify.breakpoint.mobile ? 'title-fase-mobile' : 'title-fase'},[_vm._v(_vm._s(item.SituacionOperativa.FaseEmergencium.nombre))])])]}},{key:"item.SITUACION_OPERATIVA",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.SituacionOperativa.nombre))])]}},{key:"item.ACCIONES",fn:function(ref){
              var item = ref.item;
return [(_vm.userCanEditEmergencia)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteSituacionOperativaChange(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Quitar estado emergencia")])]):_vm._e()]}}],null,true)})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }