import request from '../request'

export default {
  getSimulaciones: function (data) {
    return request.get('simulacion', { params: data })
  },

  addSimulacion: function (data) {
    return request.post('simulacion', data)
  }
}
