import request from './request'

export class RelevanciaRequest  {
  static async getAll () {
    try {
      return (await request.get('relevancia')).data.body
    } catch (error) {
      console.error('ERROR AL OBTENER RELEVANCIAS', error)
      throw error
    }
  }
}