import api from '@/api'
import constants from '@/helpers/constants'
import Vue from 'vue'

let Q = require('q')

const state = {
  contactos: [],
}

const getters = {
  contactos: (state) => state.contactos,

  getContactosByEntidad: (state) => async (data) => {
    // DATA: {id_entidad, type (tipo de contacto)}
    try {
      const response = await api.contacto.getContactosByEntidad(data)
      if (response.status === 200) {
        return response.data.body
      }
    } catch (err) {
      if (err.response && (err.response.status !== 200 && err.response.status !== 500)) {
        Vue.notify({
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Contactos.getContactos,
          type: 'error'
        })
      } else if (err.request) {
        Vue.notify({
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
          type: 'error'
        })
      }
    }
    
  },

  addContactoByDelegacion: (state) => async (data) => {
    const response = await api.contacto.addContactoByDelegacion(data)
    if (response.status === 200) {
      return response.data.body
    }
  }
}

const mutations = {
  SET_CONTACTOS (state, contactos) {
    state.contactos = contactos
  },

  ERROR_AUTORIZACION (state, data) {
    this.notify(data.title, data.text, 'error')
  },

  ERROR_RESPUESTA_SERVER (state, data) {
    this.notify(data.title, data.text, 'error')
  }
}

const actions = {
  
  async fetchContactos ({ commit }) {
    let deferred = Q.defer()
  
    try {
      const response = await api.contacto.getContactos()
      if (response.status === 200) {
        commit('SET_CONTACTOS', response.data.body)
  
        deferred.resolve()
      }
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }
  
    return deferred.promise
  },

  async addContactoByDelegacion ({ commit },data) {
    let deferred = Q.defer()

    try {
      const response = await api.contacto.addContactoByDelegacion(data)
      if (response.status === 200) {
        return response.data.body
      }
      deferred.resolve()
    } catch (err) {
      if (err.response && (err.response.status !== 200 && err.response.status !== 500)) {

        commit('ERROR_AUTORIZACION', 
          {
            title: err.response.statusText, 
            text: constants.notificacionesPermisos.Contactos.nuevoContacto
          })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500]
        })
      }
    }
  },

  async editContactoByDelegacion ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.contacto.editContactoByDelegacion(data)
      if (response.status === 200) {
        this.notify('Contacto actualizado', data.nombre ? `Se ha actualizado el contacto ${data.nombre}` : 'Se ha actualizado el contacto')
      }

      deferred.resolve()
    } catch (err) {
      if (err.response && (err.response.status !== 200 && err.response.status !== 500)) {

        commit('ERROR_AUTORIZACION', 
          {
            title: err.response.statusText, 
            text: constants.notificacionesPermisos.Contactos.editarContacto
          })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500]
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async deleteContactoById ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.contacto.deleteContactoById(data)
      if (response.status === 200) {
      }

      deferred.resolve()
    } catch (err) {
      if (err.response && (err.response.status !== 200 && err.response.status !== 500)) {

        commit('ERROR_AUTORIZACION', 
          {
            title: err.response.statusText, 
            text: constants.notificacionesPermisos.Contactos.borrarContacto
          })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500]
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  }
}
  
const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
  
export default module
  