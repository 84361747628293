import api from '@/api'
import constants from '@/helpers/constants'

let Q = require('q')

const state = {
  tiposAccion: []
}

const getters = {
  tiposAccion: (state) => {
    return state.tiposAccion
  }
}

const mutations = {
  SET_TIPOS_ACCION (state, data) {
    state.tiposAccion = data
  },

  ADD_TIPO_ACCION (state, data) {
    if (data) {
      this.notify('Nueva acción añadida', `Se ha añadido la acción:  ${data.nombre}`)
    }
  },

  UPDATE_TIPO_ACCION (state, data) {
    if (data) {
      this.notify('Acción actualizada', `Se ha actualizado la acción ${data.nombre}`)
    }
  },

  ERROR_AUTORIZACION (state, data) {
    this.notify(data.title, data.text, 'error')
  },

  ERROR_RESPUESTA_SERVER (state, data) {
    this.notify(data.title, data.text, 'error')
  }
}

const actions = {
  async fetchTiposAccion ({ commit }) {
    let deferred = Q.defer()

    try {
      const response = await api.tipoAccion.getAllTiposAccion()
      if (response.status === 200) {
        commit('SET_TIPOS_ACCION', response.data.body)
      }
      deferred.resolve()
    } catch (err) {
      if (err.response && (err.response.status !== 200 && err.response.status !== 500)) {

        commit('ERROR_AUTORIZACION', 
          {
            title: err.response.statusText, 
            text: constants.notificacionesPermisos.FlujoDeTrabajo.getTiposAccion
          })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500]
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async addTipoAccion ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.tipoAccion.addTipoAccion(data)
      if (response.status === 200) {
        commit('ADD_TIPO_ACCION', response.data.body)
      }
      deferred.resolve()
    } catch (err) {
      if (err.response && (err.response.status !== 200 && err.response.status !== 500)) {

        commit('ERROR_AUTORIZACION', 
          {
            title: err.response.statusText, 
            text: constants.notificacionesPermisos.FlujoDeTrabajo.nuevoTipoAccion
          })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500]
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async updateTipoAccion ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.tipoAccion.updateTipoAccion(data)
      if (response.status === 200) {
        commit('UPDATE_TIPO_ACCION', data)
      }

      deferred.resolve()
    } catch (err) {
      if (err.response && (err.response.status !== 200 && err.response.status !== 500)) {

        commit('ERROR_AUTORIZACION', 
          {
            title: err.response.statusText, 
            text: constants.notificacionesPermisos.FlujoDeTrabajo.editarTipoAccion
          })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500]
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async deleteTipoAccionbyId ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.tipoAccion.deleteTipoAccion(data)
      if (response.status === 200) {
        return response.data.body
      }

      deferred.resolve()
    } catch (err) {
      if (err.response && (err.response.status !== 200 && err.response.status !== 500)) {

        commit('ERROR_AUTORIZACION', 
          {
            title: err.response.statusText, 
            text: constants.notificacionesPermisos.FlujoDeTrabajo.borrarTipoAccion
          })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500]
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  }
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default module

