import { render, staticRenderFns } from "./ProtocoloIntervencion.vue?vue&type=template&id=e111fada&scoped=true&"
import script from "./ProtocoloIntervencion.vue?vue&type=script&lang=js&"
export * from "./ProtocoloIntervencion.vue?vue&type=script&lang=js&"
import style0 from "./ProtocoloIntervencion.vue?vue&type=style&index=0&id=e111fada&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e111fada",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCheckbox,VDataTable,VIcon,VTooltip})
