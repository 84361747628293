<template>
  <v-main id="content">
    <v-container fluid fill-height>
      <v-row align="center" justify="center">
        <v-col xs="12" sm="6" md="4" xl="3" lg="3" align="center">
          <v-fade-transition>
            <div>
              <div><img class="mb-5" width="180px" src="../assets/firma_madrid_blanco.svg"></div>
              <div><img class="mb-10" width="209px" src="../assets/PEMAM_logo.svg"></div>
              
              <v-card class="elevation-12 pa-3" v-show="!loading" v-if="!showResetCredentials">
                <v-card-text>
                  <v-form v-model="isValid">
                    <v-text-field v-model="credentials.USUARIO" prepend-icon="mdi-account" label="Usuario" type="text" :rules="[globalRules.required]" />
                    <v-text-field v-model="credentials.PASSWD" prepend-icon="mdi-lock" label="Contraseña" type="password" :rules="[globalRules.required]" @keyup.enter="initLogin" />

                    <!-- Recuperacion de contraseña -->
                    <div class="containerRecuperacionPassword">
                      <span class="restablecerPassword" @click="mostrarRecuperacionPassword">
                        <a href="#">
                          ¿Ha olvidado su usuario o contraseña?
                        </a>
                      </span>
                    </div>
                  </v-form>
                </v-card-text>

                <v-card-actions class="justify-center">
                  <v-btn color="primary" width="25%" :disabled="!isValid" @click="initLogin">Login</v-btn>
                </v-card-actions>

                <vx-dialog-required-password-update
                  :show="showRequiredPasswordUpdate"
                  :usuario="credentials.USUARIO"
                  @cancelar="showRequiredPasswordUpdate = false"
                  @aceptar="acceptChangePasswordOk"
                  @mensajeError="showError"
                />
              </v-card>

              <!-- MOSTRAR RECUPERACION DE CREDENCIALES -->
              <vx-recuperar-credenciales
                v-if="showResetCredentials"
                @cancelar="showResetCredentials = false"
              />

              <v-progress-circular v-show="loading" color="white" indeterminate size="100" width="5" />
            </div>
          </v-fade-transition>
        </v-col>
      </v-row>
    </v-container>

    <span class="bottomLabel" style="left: 10px">v{{ version }}</span>
    <!-- <a href="https://www.vexiza.com"> <img class="bottomLabel" src="../assets/logo_vexiza_blanco.svg" style="width:100px; right: 10px;"> </a> -->

    <v-snackbar v-model="showSnackbarError" bottom color="error" :timeout="5000">
      <v-icon>mdi-lock</v-icon>
      {{ errorMessage }}
    </v-snackbar>
  </v-main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import RecuperarCredenciales from '@/components/RecuperarCredenciales.vue'
import DialogRequiredPasswordUpdate from '@/components/DialogRequiredPasswordUpdate.vue'

const version = require('../../package.json').version

export default {

  components: {
    'vx-recuperar-credenciales': RecuperarCredenciales,
    'vx-dialog-required-password-update': DialogRequiredPasswordUpdate
  },

  name: 'Login',

  data: () => ({
    isValid: false,

    version: version,

    credentials: {},

    loading: false,

    showSnackbarError: false,
    errorMessage: '',

    showResetCredentials: false,

    showRequiredPasswordUpdate: false,
  }),

  computed: {
    ...mapGetters('auth', [
      'accessToken'
    ])
  },

  methods: {
    ...mapActions('app', [
      'init'
    ]),
    ...mapActions('auth', [
      'login'
    ]),

    async initLogin () {
      this.loading = true

      try {
        await this.login(this.credentials)

        // await this.init() // Si el login es correcto, init descarga todos los datos a la store
        this.$router.push('/')
      } catch (err) {
        this.loading = false
        this.showError('Login incorrecto.')

        if (err && err.response) {
          switch (err.response.status) {
          case 401:
            this.showError('Credenciales incorrectas.')
            break
          case 426:
            this.showError('Primer inicio de sesión o contraseña expirada, es necesario actualizarla')
            this.actualizarPassword(this.credentials.USUARIO)
            break
          }
        }
      }
    },
    showError (msg) {
      this.errorMessage = msg
      this.showSnackbarError = true
    },

    mostrarRecuperacionPassword () {
      this.showResetCredentials = true
    },

    actualizarPassword () {
      // Mostrar modal para solicitar el cambio de contraseña
      this.showRequiredPasswordUpdate = true
    },

    acceptChangePasswordOk (newPassword) {
      this.showRequiredPasswordUpdate = false
      this.credentials.PASSWD = newPassword
    }
  },

  mounted () {
    let reason = this.$route.query.reason

    if (reason && reason === 'token_invalid') {
      this.showError('La sesión ha expirado. Vuelva a iniciar sesión.')
    }
  }
}
</script>

<style scoped>
  #content {
    box-shadow: inset 0 0 0 1000px #00496fcf;
    background-color: var(--v-primary-base);
    /* background-image: url('~@/assets/login_background.jpg'); */
    background-position: center;
    background-size: cover;
    height: 100%;
  }

  .bottomLabel {
    color: white;
    position: absolute;
    bottom: 0px;
  }

  .restablecerPassword {
    font-size: 0.95rem;
  }

  .containerRecuperacionPassword {
    margin-top: 5px;
  }
</style>
