import Vue from 'vue'
import request from './request'
import constants from '@/helpers/constants'

export class SubriesgoRequest {
  static async getByTipoRiesgo (tipoRiesgoId){
    try {
      return await (await (
        request.get('subriesgo', {params: {
          tipoPadre: tipoRiesgoId
        }}))
      ).data.body
    } catch (err) {
      if (err.response && (err.response.status !== 200 && err.response.status !== 500)) {
        Vue.notify({
          title: err.response.statusText, 
          text: constants.notificacionesPermisos.SubRiesgo.getSubRiesgos,
          type: 'error'
        })
      } else if (err.request) {
        Vue.notify({
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
          type: 'error'
        })
      }
    }
  }
}
