<template>
  <v-card tile height="100%">
    <v-toolbar color="primaryLight" dark dense style="z-index: 2">
      <v-toolbar-title>Gestión de medios</v-toolbar-title>

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            :disabled="disabledByRol"
            fab
            bottom
            right
            absolute
            color="primary"
            @click="showAddMedioDialog"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Añadir medio</span>
      </v-tooltip>
    </v-toolbar>

    <v-card-text>
      <v-container fluid>
        <v-row align="center">
          <!-- <v-btn fab small color="red" @click="exportarMediosPDF()">
            <v-icon color="white">mdi-file-pdf-box</v-icon>
          </v-btn> -->

          <v-col sm="3">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Búsqueda"
              outlined
              clearable
              hide-details
            />
          </v-col>
        </v-row>

        <v-row>
          <v-data-table
            class="table"
            :headers="headers"
            :items="medios"
            :search="search"
            fixed-header
            disable-pagination
            hide-default-footer
          >
            <!-- :items-per-page="30" -->
            <template #no-data>
              <p>Datos no disponibles.</p>
            </template>

            <template #[`item.TELEFONO_INTERNO`]="{ item }">
              <span>{{ item.TELEFONO_INTERNO }}</span>
            </template>

            <template #[`item.TELEFONO_EXTERNO`]="{ item }">
              <a :href="'tel:+34' + item.TELEFONO_EXTERNO">{{
                item.TELEFONO_EXTERNO
              }}</a>
            </template>

            <template #[`item.FECHA`]="{ item }">
              <span>{{ item.FECHA | formatDate }}</span>
            </template>

            <template #[`item.ACCIONES`]="{ item }">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :disabled="disabledByRol"
                    icon
                    @click="showEditMedioDialog(item)"
                  >
                    <v-icon color="teal">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :disabled="disabledByRol"
                    icon
                    @click="acceptDeleteMedio(item)"
                  >
                    <v-icon color="error">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Borrar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-row>
      </v-container>
    </v-card-text>

    <vx-dialog-add-edit-medio
      :show="showDialog"
      :isEdit="isEdit"
      :editedMedio="editMedio"
      @cancelar="showDialog = false"
      @addMedio="acceptAddMedio"
      @editMedio="acceptEditMedio"
    />
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import DialogAddEditMedio from './DialogAddEditMedio'

import constants from '../../helpers/constants'

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
pdfMake.vfs = pdfFonts.pdfMake.vfs

export default {
  components: {
    'vx-dialog-add-edit-medio': DialogAddEditMedio,
  },

  data: () => ({
    headers: [
      { text: 'Medio', value: 'MEDIO', align: 'left', sortable: true },
      { text: 'Tipo', value: 'TIPO', align: 'left', sortable: true },
      { text: 'Categoría', value: 'CATEGORIA', align: 'left', sortable: true },
      { text: 'Nombre', value: 'NOMBRE', align: 'left', sortable: true },
      {
        text: 'Tel. interno',
        value: 'TELEFONO_INTERNO',
        align: 'left',
        sortable: false,
      },
      {
        text: 'Tel. externo',
        value: 'TELEFONO_EXTERNO',
        align: 'left',
        sortable: false,
      },
      { text: 'Zona', value: 'ZONA', align: 'left', sortable: true },
      { text: 'Guardias', value: 'GUARDIAS', align: 'left', sortable: true },
      { text: 'Fecha ult. posicion', value: 'FECHA', align: 'left' },
      { text: 'Hora entrada', value: 'HORA_ENTRADA', align: 'left' },
      { text: 'Hora salida', value: 'HORA_SALIDA', align: 'left' },
      { text: 'Base', value: 'BASE', align: 'left' },
      {
        text: 'Acciones',
        value: 'ACCIONES',
        align: 'center',
        width: '10%',
        sortable: false,
      },
    ],

    showDialog: false,

    search: '',
    editMedio: {},
    isEdit: false,
  }),

  computed: {
    medios () {
      return this.$store.getters['medio/medios']
    },

    disabledByRol () {
      // Deshabilita funciones dependiendo del rol
      return !this.$store.getters['usuario/isCurrentUsuarioAdmin']
    },
  },

  methods: {
    ...mapActions('medio', ['addMedio', 'updateMedio', 'deleteMedio']),

    showAddMedioDialog () {
      this.editMedio = null
      this.showDialog = true
      this.isEdit = false
    },

    showEditMedioDialog (item) {
      this.editMedio = Object.assign({}, item)
      this.showDialog = true
      this.isEdit = true
    },

    acceptDeleteMedio (medio) {
      let msg = 'Eliminando el medio: ' + medio.MEDIO
      this.$root.$confirmDialog.open('¿Está seguro?', msg).then((result) => {
        if (result) {
          this.$store.dispatch('medio/deleteMedio', medio)
        }
      })
    },

    acceptAddMedio (medio) {
      this.showDialog = false
      this.addMedio(medio)
    },

    acceptEditMedio (medio) {
      this.showDialog = false
      this.updateMedio(medio)
    },

    exportarMediosPDF () {
      // Exporta medios terrestres y fechas a un PDF
      let bodyPdf = []
      bodyPdf.push(['Medio', 'Tipo', 'Fecha'])

      let mediosFechaOk = this.medios.filter((x) =>
        this.$date.parseDate(x.FECHA).isValid()
      )
      let mediosFechaMal = this.medios.filter(
        (x) => !this.$date.parseDate(x.FECHA).isValid()
      )

      let mediosOrdenados = mediosFechaOk.sort((a, b) => {
        let aOrden = this.$date.parseDate(a.FECHA)
        let bOrden = this.$date.parseDate(b.FECHA)

        return bOrden > aOrden ? 1 : -1
      })

      for (let i = 0; i < mediosFechaMal.length; i++) {
        mediosOrdenados.push(mediosFechaMal[i])
      }

      for (let i = 0; i < mediosOrdenados.length; i++) {
        let medio = mediosOrdenados[i]

        let datosMedio = []
        datosMedio.push({ text: medio.MEDIO, bold: true })
        datosMedio.push(medio.TIPO)
        datosMedio.push(this.$date.formatDate(medio.FECHA, 'DD/MM/YYYY HH:mm'))
        bodyPdf.push(datosMedio)
      }

      let docDefinition = {
        content: [],
        defaultStyle: {
          fontSize: 9,
        },
      }

      docDefinition.content.push(constants.headerPDF)

      docDefinition.content.push(
        {
          text: [
            { text: 'Fecha/hora informe: ', bold: true },
            this.$date.currentDate(),
          ],
          margin: [0, 0, 0, 15],
        },
        {
          layout: 'lightHorizontalLines', // optional
          table: {
            headerRows: 1,
            body: bodyPdf,
          },
          margin: [0, 15, 0, 20],
        }
      )

      pdfMake
        .createPdf(docDefinition)
        .download(
          'Medios_Fecha_' + this.$date.now().format('YYYY_MM_DD_hh_mm')
        )
    },
  },
}
</script>

<style scoped>
/* Estilos para que la tabla tenga un max-height y pueda crecer */
.table {
  width: 100vw;
  max-height: calc(100vh - 215px)
}
.v-data-table {
  overflow: auto;
}
.v-data-table >>> .v-data-table__wrapper {
  /* Header fijo */
  overflow: unset;
}
/* .v-data-table >>> .v-data-footer {
    position: fixed;
    bottom: 0;
    width: 100vw;
    background: white;
  } */
</style>
