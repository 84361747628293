import { Message } from '@/models/Message'
import request from './request'

export class ChatRequest {

  /**
   * 
   * @param {String} chatId 
   * @param {Message} message 
   * @returns 
   */
  static async sendMessage (chatId, message, document) {
    return (await request.post(
      `chat/${chatId}`, 
      {
        ...message,
        documento: document
      }
    )).data.body
  }

  static async getMessages (chatId) {
    try {
      return (await request.get(`chat/${chatId}`)).data.body
    } catch (error) {
      console.error('ERROR AL OBTENER MENSAGES', error)
      throw error
    }
  }

  /**
   * 
   * @param {String[]} messagesIds 
   */
  static async markMessagesAsInvalid (messagesIds) {
    try {
      return (await request.post('message/markInvalid', messagesIds)).data.body
    } catch (error) {
      throw error
    }
  }

  /**
   * 
   * @param {String[]} messagesIds 
   */
  static async markMessagesAsValid (messagesIds) {
    try {
      const result = await request.post('message/markValid', messagesIds).catch(err => {
        throw err
      })
      return result.data.body
    } catch (error) {
      throw error
    }
  }

  static async getEntidades (idChat) {
    try {
      return (await request.get(`chat/${idChat}/entidades`)).data.body
    } catch (error) {
      console.error('ERROR AL OBTENER ENTIDADES DE CHAT',error)
      throw error
    }
  }

  static async setEntidades (idChat ,idsEntidades) {
    try {
      return (await request.put(`chat/${idChat}/entidades`, idsEntidades)).data.body
    } catch (error) {
      console.error('ERROR AL AÑADIR ENTIDADES AL CHAT', error)
      throw error
    }
  }

  static async setName (idChat, newName) {
    try {
      await await request.put(`chat/${idChat}/setName`, {nombre: newName})
    } catch (error) {
      console.error('ERROR AL EDITAR NOMBRE DE CHAT', error)
      throw error
    }
  }
}