<template>
  <v-dialog v-model="show" scrollable persistent max-width="600px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title v-if="tipoContacto === 'emergencias'"> {{ accionDialog }} contacto de {{ tipoContacto }}</v-toolbar-title>
        <v-toolbar-title v-else> {{ accionDialog }} contacto administrativo</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-form ref="form" v-model="isValid">
          <v-container>
            <v-row class="mt-1">
              <v-col>
                <!-- TIPO CONTACTO: ENTIDAD O DELEGACION -->
                <v-select v-model="tipoContactoSelected" label="Tipo de contacto*" :items="opcionContacto" :menu-props="{ bottom: true, offsetY: true }" item-text="nombre" item-value="nombre" return-object outlined dense :rules="[globalRules.required]" :disabled="isEdit" />
              </v-col>

              <v-col
                v-if="tipoContactoSelected && tipoContactoSelected.value === 'entidad'"
              >
                <!-- SI CONTACTO TIPO ENTIDAD -->
                <v-select v-model="entidadSelected" :items="delegacion.filter(x => x.is_default)" :menu-props="{ bottom: true, offsetY: true }" item-text="nombre" item-value="nombre" label="Asignar a entidad" return-object outlined dense :rules="[globalRules.required]" disabled />
              </v-col>

              <v-col v-else-if="tipoContactoSelected && tipoContactoSelected.value === 'delegacion'">
                <!-- SI CONTACTO TIPO DELEGACION -->
                <v-select v-model="delegacionSelected" :items="delegacion.filter(x => !x.is_default)" :menu-props="{ bottom: true, offsetY: true }" item-text="nombre" item-value="nombre" label="Asignar a delegación*" return-object outlined dense :rules="[globalRules.required]" />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <!-- NIVEL CONTACTO, tipo textfield -->
                <!-- <v-select v-model="nivelEscaladoSelected" label="Nivel escalado" :items="nivelEscalado" item-text="nivel" item-value="nivel" return-object outlined dense :rules="[globalRules.required]" /> -->
                <v-text-field label="Nivel escalado*" v-model="nivelEscalado" v-mask="'##'" dense maxlenght="2" :rules="[globalRules.required]" />
              </v-col>

              <v-col>
                <!-- TIPO CONTACTO (TITULAR O SUPLENTE) -->
                <v-select v-model="titularidadSelected" :items="titularidadContacto" :menu-props="{ bottom: true, offsetY: true }" label="Titularidad contacto*" item-text="nombre" item-value="nombre" return-object outlined dense :rules="[globalRules.required]" />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <!-- CARGO -->
                <v-text-field label="Cargo" v-model="cargo" dense maxlenght="50" />
              </v-col>

              <v-col>
                <!-- NOMBRE -->
                <v-text-field label="Nombre" v-model="nombre" dense maxlenght="50" />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <!-- TELEFONO MOVIL -->
                <v-combobox
                  v-model="telefonoMovil"
                  :items="itemsTelefonoMovil"
                  :search-input.sync="searchTelefono"
                  v-mask="'#########'"
                  hide-selected
                  label="Teléfono/s móvil/es"
                  multiple
                  small-chips
                  deletable-chips
                  :rules="[globalRules.required]"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <!-- TELEFONO FIJO -->
                <v-combobox
                  v-model="telefonoFijo"
                  :items="itemsTelefonoFijo"
                  :search-input.sync="searchTelefono"
                  v-mask="'#########'"
                  hide-selected
                  label="Teléfono/s fijo/s"
                  multiple
                  small-chips
                  deletable-chips
                  :rules="[globalRules.required]"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <!-- EMAIL --> 
                <v-combobox
                  v-model="emails"
                  :items="itemsEmail"
                  :search-input.sync="searchEmail"
                  hide-selected
                  label="Email/s"
                  multiple
                  small-chips
                  deletable-chips
                  :rules="[globalRules.required]"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <!-- OTROS MEDIOS -->
                <v-text-field label="Otros medios" v-model="otros_medios" dense maxlenght="100" />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <!-- OBSERVACIONES -->
                <v-text-field label="Observaciones" v-model="observaciones" dense maxlenght="240" />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <!-- <v-btn text color="green" @click="aceptar">Aceptar</v-btn> -->
        <v-btn text color="green" :disabled="!isValid" @click="aceptar">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {

  props: {
    show: Boolean,
    isEdit: Boolean,
    editedContacto: Object,
    tipoContacto: String,
    idEntidad: String
  },

  data: () => ({
    isValid: false,
    // isValidContact: false,

    contacto: null,

    entidad: [],
    delegacion: [],

    tipoContactoSelected: null,
    entidadSelected: null,
    delegacionSelected: null,

    nivelEscalado: null,
    titularidadSelected: null,

    cargo: null,
    nombre: null,

    telefonoMovil: [],
    itemsTelefonoMovil: [],
    telefonoFijo: [],
    itemsTelefonoFijo: [],

    emails: [],
    itemsEmail: [],
    otros_medios: null,
    observaciones: null,

    searchTelefono: null,
    searchEmail: null
  }),

  watch: {
    async show () {
      if (this.show) {
        this.entidad = await this.$store.getters['entidad/getEntidadByIdContactos'](this.idEntidad)
        this.delegacion = this.entidad.Delegacions
        if (this.editedContacto) {          
          this.contacto = this.editedContacto
          let tipoDeContacto = this.getTipoContacto(this.entidad, this.editedContacto)
          this.setTipoContacto(tipoDeContacto)
          if (tipoDeContacto === 'delegacion') {
            this.delegacionSelected = this.getDelegacionDeContacto(this.entidad, this.editedContacto)
          } else {
            this.entidadSelected = this.getDelegacionDefaultDeEntidad(this.entidad)
          }
          this.nivelEscalado = this.editedContacto.Nivel_contacto.nivel
          this.titularidadSelected = this.titularidadContacto.find(x => x.value === this.editedContacto.titular)
          this.cargo = this.editedContacto.cargo
          this.nombre = this.editedContacto.nombre
          this.setTelefonos(this.editedContacto)
          this.setEmails(this.editedContacto)
          this.otros_medios = this.editedContacto.otros_medios
          this.observaciones = this.editedContacto.observaciones
        }
      }
    },

    async tipoContactoSelected () {
      if (this.tipoContactoSelected && this.tipoContactoSelected.value === 'entidad') {
        this.entidadSelected = this.getDelegacionDefaultDeEntidad(this.entidad)
      } 
    },

    telefonoMovil () {
      // TODO: Validar movil?
      // if (newValue.length) {
      //   let ultimoMovil = newValue[newValue.length - 1]
      //   let movilValido = this.validarTelefono(ultimoMovil)
      //   movilValido ? this.itemsTelefonoMovil = this.telefonoMovil : this.notificarTelefonoIncorrecto(ultimoMovil)
      //   !movilValido && this.itemsTelefonoMovil.pop()
      //   !movilValido && this.telefonoMovil.pop()
      // } else {
      //   // Vaciar combobox movil
      //   this.itemsTelefonoMovil = this.telefonoMovil
      // }
      
      this.itemsTelefonoMovil = this.telefonoMovil
      // this.validarContactos()
    },

    telefonoFijo () {
      // TODO: Validar fijo??
      // if (newValue.length) {
      //   let ultimoFijo = newValue[newValue.length - 1]
      //   let fijoValido = this.validarTelefono(ultimoFijo)
      //   fijoValido ? this.itemsTelefonoFijo = this.telefonoFijo : this.notificarTelefonoIncorrecto(ultimoFijo)
      //   !fijoValido && this.itemsTelefonoFijo.pop()
      //   !fijoValido && this.telefonoFijo.pop()
      // } else {
      //   // Vaciar combobox fijo
      //   this.itemsTelefonoFijo = this.telefonoFijo
      // }
      this.itemsTelefonoFijo = this.telefonoFijo
      // this.validarContactos()
    },

    emails (newValue) {
      // Validar email
      if (newValue.length) {
        let ultimoEmail = newValue[newValue.length - 1]
        let emailValido = this.validarEmail(ultimoEmail)
        emailValido ? this.itemsEmail = this.emails : this.notificarEmailIncorrecto(ultimoEmail)
        !emailValido && this.itemsEmail.pop()
        !emailValido && this.emails.pop()
      } else {
        // Vaciar combobox emails
        this.itemsEmail = this.emails
      }

      // this.validarContactos()
    }
  },

  computed: {

    accionDialog () {
      return this.editedContacto ? 'Editar' : 'Nuevo'
    },

    opcionContacto () {
      return [
        {
          nombre: 'Entidad',
          value: 'entidad'
        },
        {
          nombre: 'Delegación',
          value: 'delegacion'
        }
      ]
    },

    titularidadContacto () {
      return [
        {
          nombre: 'Titular',
          value: true
        },
        {
          nombre: 'Suplente',
          value: false
        }
      ]
    },

    ...mapGetters('entidad', [
      'listaEntidades', 'getEntidadById'
    ]),
    ...mapGetters('usuario', [
      'currentUsuario'
    ]),
  },

  methods: {

    setEmails (contacto) {
      this.itemsEmail = []
      this.emails = []
      let emails = contacto.Email_contactos
      if (emails) {
        emails.forEach((email) => {
          this.itemsEmail.push(email.email)
          this.emails.push(email.email)
        })
      }
    },

    setTelefonos (contacto) {
      this.itemsTelefonoFijo = []
      this.telefonoFijo = []
      this.itemsTelefonoMovil = []
      this.telefonoMovil = []
      let telefonos = contacto.Telefono_contactos
      if (telefonos) {
        telefonos.forEach((telefono) => {
          if (telefono.fijo) {
            this.itemsTelefonoFijo.push(telefono.numero)
            this.telefonoFijo.push(telefono.numero)
          } else {
            this.itemsTelefonoMovil.push(telefono.numero)
            this.telefonoMovil.push(telefono.numero)
          }   
        })
      }
    },

    getEmails (contacto, editedContacto) {
      const emails = []
      for (let i = 0; i < this.emails.length; i++) {
        // Comprobar previamente los email
        let encontrado = editedContacto.Email_contactos.find(x => x.email === this.emails[i])
        if (encontrado) {
          // Añadir email existente
          emails.push(encontrado)
        } else {
          // Añadir nuevo email
          emails.push(this.createEmail(contacto, this.emails[i]))
        }
      }
      return emails
    },

    createEmail (contacto, email){
      return {
        id_email_contacto: this.$uuid.createUUID(),
        id_contacto: contacto.id_contacto,
        email: email,
        usuariostamp: this.currentUsuario.ID,
        borrado: false
      }
    },

    createNewTelefono (isFijo, contacto, telefono) {
      return {
        id_telefono_contacto: this.$uuid.createUUID(),
        id_contacto: contacto.id_contacto,
        fijo: isFijo,
        numero: parseInt(telefono),
        borrado: false,
        usuariostamp: this.currentUsuario.ID
      }
    },

    getTelefonosFijos (contacto, editedContacto){
      const telefonosFijos = []
      this.telefonoFijo.forEach((telefono) => {
        let encontrado = editedContacto.Telefono_contactos.find(x => x.numero === parseInt(telefono))
        if (encontrado) {
          telefonosFijos.push(encontrado)
        } else {
          telefonosFijos.push(this.createNewTelefono(true, contacto, telefono))
        }
      })
      return telefonosFijos
    },

    getMoviles (contacto, editedContacto){
      const moviles = []
      this.telefonoMovil.forEach((telefono) => {
        let encontrado = editedContacto.Telefono_contactos.find(x => x.numero === parseInt(telefono))
        if (encontrado) {
          moviles.push(encontrado)
        } else {
          moviles.push(this.createNewTelefono(false, contacto, telefono))
        }
      })
      return moviles
    },

    getDelegacionDefaultDeEntidad (entidad){
      return entidad.Delegacions.find(x => x.is_default && x.id_entidad === this.idEntidad)
    },

    getDelegacionDeContacto (entidadContacto, contacto){
      return entidadContacto.Delegacions
        .find(x => x.id_delegacion === contacto.Nivel_contacto.Delegacion.id_delegacion)
    },

    getTipoContacto (entidad, contacto){
      let contactoEntidadOdelegacion = 
        entidad.Delegacions.find(
          delegacion => delegacion.id_delegacion === 
          contacto.Nivel_contacto.Delegacion.id_delegacion
        )
      return contactoEntidadOdelegacion.is_default ? 'entidad' : 'delegacion'
    },

    setTipoContacto (tipoContacto) {
      this.tipoContactoSelected = this.opcionContacto
        .find(opcion => opcion.value === tipoContacto)
    },

    cancelar () {
      this.$refs.form.reset()
      this.$emit('cancelar')
    },

    nivelContactoEstaModificado (){
      return this.editedContacto.Nivel_contacto.nivel !== parseInt(this.nivelEscalado)
    },

    getIdNivelContacto (){
      return this.editedContacto.id_nivel_contacto
    },

    getNewNivelContacto (){
      return {
        id_nivel_contacto: this.$uuid.createUUID(),
        nivel: this.nivelEscalado,
        id_delegacion: this.tipoContactoSelected.value === 'entidad' ? this.entidadSelected.id_delegacion : this.delegacionSelected.id_delegacion,
        Delegacion: this.tipoContactoSelected.value === 'entidad' ? this.entidadSelected : this.delegacionSelected,
        usuariostamp: this.currentUsuario.ID,
        borrado: false
      }
    },

    // validarContactos () {
    //   if (this.telefonoMovil.length && this.telefonoFijo.length && this.emails.length) {
    //     this.isValidContact = true
    //   } else {
    //     this.isValidContact = false
    //   }
    // },

    // validarTelefono (telefono) {
    //   let valido = telefono.length === 9 ? true : false
    //   return valido
    // },

    // notificarTelefonoIncorrecto (telefono) {
    //   this.$notify({
    //     title: 'Telefono incorrecto',
    //     text: `El teléfono ${telefono} no es válido`
    //   })
    // },

    validarEmail (email) {
      var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      let valido = email.match(mailformat) ? true : false
      return valido
    },

    notificarEmailIncorrecto (email) {
      this.$notify({
        title: 'Email incorrecto',
        text: `El email ${email} no es válido`
      })
    },

    aceptar () {
      let contacto = (this.editedContacto && JSON.parse(JSON.stringify(this.editedContacto))) || {}
      contacto.nombre = this.nombre
      contacto.otros_medios = this.otros_medios
      contacto.titular = this.titularidadSelected.value
      contacto.emergencia = this.tipoContacto === 'emergencias' ? true : false
      contacto.observaciones = this.observaciones
      contacto.cargo = this.cargo
      contacto.usuariostamp = this.currentUsuario.ID
      contacto.Telefono_contactos = []
      contacto.Email_contactos = []
      if (this.editedContacto) {
        contacto.id_contacto = this.editedContacto.id_contacto
        if (this.nivelContactoEstaModificado()) {
          contacto.id_nivel_contacto_old = this.editedContacto.Nivel_contacto.id_nivel_contacto
          contacto.Nivel_contacto = this.getNewNivelContacto()
          contacto.id_nivel_contacto = contacto.Nivel_contacto.id_nivel_contacto
        } else {
          contacto.id_nivel_contacto = this.editedContacto.id_nivel_contacto
          contacto.Nivel_contacto = null
        }
        if (this.telefonoMovil) {
          contacto.Telefono_contactos = this.getMoviles(contacto, this.editedContacto)
        }
        if (this.emails) {
          contacto.Email_contactos = this.getEmails(contacto, this.editedContacto)
        }
        if (this.telefonoFijo) {
          contacto.Telefono_contactos = contacto.Telefono_contactos.concat(this.getTelefonosFijos(contacto, this.editedContacto))
        }
        this.$refs.form.reset()
        this.$emit('editContacto', contacto)
      } else {
        contacto.id_contacto = this.$uuid.createUUID()
        contacto.borrado = false
        contacto.Nivel_contacto = this.getNewNivelContacto()
        contacto.id_nivel_contacto = contacto.Nivel_contacto.id_nivel_contacto
        if (this.telefonoMovil) {
          this.telefonoMovil.forEach(movil => {
            contacto.Telefono_contactos.push(this.createNewTelefono(false, contacto, movil))
          })
          // contacto.Telefono_contactos
          //   .push(this.telefonoMovil
          //     .map((telefono) => this.createNewTelefono(false, contacto, telefono)))
        }
        if (this.telefonoFijo) {
          this.telefonoFijo.forEach(fijo => {
            contacto.Telefono_contactos.push(this.createNewTelefono(true, contacto, fijo))
          })
          // contacto.Telefono_contactos
          //   .push(this.telefonoFijo
          //     .map((telefono) => this.createNewTelefono(true, contacto, telefono)))
        }
        if (this.emails) {
          this.emails.forEach(email => {
            contacto.Email_contactos.push(this.createEmail(contacto, email))
          })
          // contacto.Email_contactos
          //   .push(this.emails.map((email) => this.createEmail(contacto, email)))
        }
        this.$refs.form.reset()
        this.$emit('addContacto', contacto)
      }
    }
  }
}
</script>

<style scoped>

</style>