var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"estados"},[_c('v-data-table',{staticClass:"table",attrs:{"headers":_vm.headers,"items":_vm.accionesSituacionOperativa.length ? _vm.accionesSituacionOperativa : [],"height":_vm.mobile ? 400 : 160,"loading":_vm.isLoading,"hide-default-footer":"","disable-pagination":"","fixed-header":"","dense":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('p',[_vm._v("Datos no disponibles.")])]},proxy:true},{key:"header.ACCIONES",fn:function(){return [(_vm.comprobarPermisos(['MOD_EMERGENCIAS']))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"add-accion-situacion-operativa",attrs:{"icon":"","disabled":_vm.situacionOperativaCompletaYnoEsLaActual()},on:{"click":_vm.showAddTipoAccionDialog}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus-circle")])],1)]}}],null,false,888411270)},[_c('span',[_vm._v("Añadir acción")])]):_vm._e()]},proxy:true},{key:"item.Accion.nombre",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"acciones-container"},[_c('v-checkbox',{attrs:{"label":item.Accion.nombre,"value":item,"disabled":_vm.situacionOperativaCompletaYnoEsLaActual()},on:{"click":function($event){return _vm.estadoAccionInstanciada(item)}},model:{value:(_vm.accionChecked),callback:function ($$v) {_vm.accionChecked=$$v},expression:"accionChecked"}})],1)]}},{key:"item.fechastamp",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.fechastamp)))])]}},{key:"item.ACCIONES",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"accionesButtons"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.Accion.descripcion)+" ")])]),(_vm.comprobarPermisos(['MOD_EMERGENCIAS']))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.situacionOperativaCompletaYnoEsLaActual()},on:{"click":function($event){return _vm.showEditAccionInstancia(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar acción")])]):_vm._e(),(_vm.comprobarPermisos(['MOD_EMERGENCIAS']))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.situacionOperativaCompletaYnoEsLaActual()},on:{"click":function($event){return _vm.acceptDeleteAccionInstanciada(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Borrar acción")])]):_vm._e()],1)]}}],null,true)})],1),_c('vx-dialog-add-edit-grupo-accion',{attrs:{"show":_vm.showDialog,"editedAccionInstancia":_vm.editAccionInstancia},on:{"cancelar":function($event){_vm.showDialog = false},"editAccion":_vm.acceptEditAccionInstancia}}),_c('vx-dialog-add-edit-tipo_accion',{attrs:{"show":_vm.showDialogAccion,"isEdit":_vm.isEdit,"editedTipoAccion":_vm.editTipoAccion},on:{"cancelar":function($event){_vm.showDialogAccion = false},"addTipoAccion":_vm.acceptAddTipoAccion}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }