<template>
  <v-dialog v-model="show" persistent max-width="600px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>{{ accionDialog }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-form ref="form" v-model="isValid">
          <v-container>
            <v-row class="mt-1">
              <v-col>
                <!-- Nombre delegacion -->
                <v-text-field label="Delegacion*" v-model="nombre" :rules="[globalRules.required]" dense maxlenght="50" />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <!-- Domicilio -->
                <v-text-field label="Dirección" v-model="domicilio" dense maxlenght="50" />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <!-- CP -->
                <v-text-field label="Código postal" v-model="cp" dense v-mask="'#####'" maxlenght="5" />
              </v-col>
              <v-col>
                <!-- Distrito -->
                <v-select v-model="distritoSelected" label="Distrito" :items="distritos" :menu-props="{ bottom: true, offsetY: true }" item-text="nombre" item-value="nombre" return-object outlined dense />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn text color="green" :disabled="!isValid" @click="aceptar">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {

  props: {
    show: Boolean,
    editedDelegacion: Object,
    newDelegacion: Object,
    isEditDelegacion: Boolean
  },

  data: () => ({
    isValid: false,
    delegacion: null,
    nombre: null,
    domicilio: null,
    cp: null,
    distritoSelected: null
  }),

  watch: {
    async show () {
      if (this.show) {
        if (this.isEditDelegacion) {
          this.llenarCamposConDelegacion(this.editedDelegacion)
        }
      }
    }
  },

  computed: {
    accionDialog () {
      return this.isEditDelegacion ? 'Editar delegación' : 'Nueva delegación'
    },
    ...mapGetters('distrito', [
      'distritos'
    ]),
    ...mapGetters('usuario', [
      'currentUsuario'
    ])
  },

  methods: {

    llenarCamposConDelegacion (delegacion){
      this.delegacion = delegacion
      this.nombre = delegacion.nombre
      this.domicilio = delegacion.domicilio
      this.cp = delegacion.cp
      if (delegacion.id_distrito) {
        this.distritoSelected = this.distritos
          .find(x => x.id_distrito === delegacion.id_distrito)
      }
    },

    cancelar () {
      this.$refs.form.reset()
      this.$emit('cancelar')
    },

    crearDepartamentoPorDefecto (delegacion){
      return {
        id_departamento: this.$uuid.createUUID(),
        nombre: this.nombre,
        id_delegacion: delegacion.id_delegacion,
        is_default: true,
        usuariostamp: this.currentUsuario.ID,
        borrado: false,
        Unidad_medios: []
      }
    },

    aceptar () {
      let entidad = !this.isEditDelegacion ? JSON.parse(JSON.stringify(this.newDelegacion)) : {}
      let delegacion = this.isEditDelegacion ? JSON.parse(JSON.stringify(this.editedDelegacion)) : {}
      
      delegacion.nombre = this.nombre
      delegacion.domicilio = this.domicilio
      delegacion.cp = this.cp
      // Usuario
      delegacion.Usuario = {
        nombre: this.currentUsuario.NOMBRE,
        apellidos: this.currentUsuario.APELLIDOS
      }
      if (this.distritoSelected) {
        delegacion.id_distrito = this.distritoSelected.id_distrito
        delegacion.Distrito = this.distritoSelected
      } else {
        delegacion.id_distrito = null
        delegacion.Distrito = {}
      }
      if (this.isEditDelegacion) {
        delegacion.id_delegacion = this.editedDelegacion.id_delegacion
        delegacion.is_default = this.editedDelegacion.is_default
        delegacion.usuariostamp = this.editedDelegacion.usuariostamp
        delegacion.borrado = this.editedDelegacion.borrado
        delegacion.id_entidad = this.editedDelegacion.id_entidad
        delegacion.Departamentos = this.editedDelegacion.Departamentos
        this.$emit('editDelegacion', delegacion)
        this.$refs.form.reset()
      } else {
        delegacion.id_entidad = entidad.id_entidad
        delegacion.id_delegacion = this.$uuid.createUUID()        
        delegacion.is_default = false
        delegacion.usuariostamp = this.currentUsuario.ID
        delegacion.borrado = false
        let departamento = this.crearDepartamentoPorDefecto(delegacion)
        delegacion.Departamentos = [departamento]
        entidad.Delegacions = [delegacion]
        this.$emit('addDelegacion', entidad, delegacion)
        this.$refs.form.reset()
      }
    }
  }
}
</script>

<style>

</style>