
import Vue from 'vue'
import { Emergencia } from '@/models/Emergencia'
import request from './request'
import constants from '@/helpers/constants'

export class EmergenciaRequest {
  /**
   * @returns {Promise<Emergencia>}
   */
  static async addEmergencia (dataEmergenciaSituacionOperativa){
    try {
      return (await request.post('emergencia', dataEmergenciaSituacionOperativa)).data.body
    } catch (err) {
      if (err.response && (err.response.status != 200 && err.response.status !== 500)) {
        Vue.notify({
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Emergencias.nuevaEmergencia,
          type: 'error'
        })
      } else if (err.request) {
        Vue.notify({
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Error[500],
          type: 'error'
        })
      }
    }
    
  }

  /**
   * @param {Emergencia} emergencia 
   * @returns {Promise<Emergencia>}
   */
  static async update (emergencia){
    try {
      return (await request.put(`emergencia/${emergencia.id}`, emergencia)).data.body
    } catch (err) {
      if (err.response.status !== 200) {
        Vue.notify({
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Emergencias.editarEmergencia,
          type: 'error'
        })
      } else if (err.request) {
        Vue.notify({
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Error[500],
          type: 'error'
        })
      }
    }
  }

  static async getEmergencias () {
    return (await request.get('emergencia')).data.body
  }

  static async getEmergencia (id) {
    try {
      return (await request.get(`emergencia/${id}`)).data.body
    } catch (err) {
      if (err.response && (err.response.status !== 200 && err.response.status !== 500)) {
        Vue.notify({
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Emergencias.getEmergencia,
          type: 'error'
        })
      } else if (err.request) {
        Vue.notify({
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
          type: 'error'
        })
      }
    }
  }

  static async deleteSituacionOperativa (emergenciaSituacionOperativaId) {
    
    try {
      return (await request
        .delete(`emergencia/situacionOperativa/${emergenciaSituacionOperativaId}`))
        .data.body
    } catch (err) {
      if (err.response.status !== 200) {
        Vue.notify({
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Emergencias.borrarSituacionOperativa,
          type: 'error'
        })
      } else if (err.request) {
        Vue.notify({
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
          type: 'error'
        })
      }
    }
  }

  static async addSituacionOperativa (emergenciaId, situacionOperativaId){
    try {
      return (await request.post(`emergencia/${emergenciaId}/situacionOperativa`,{
        situacionOperativaId
      })).data.body
    } catch (err) {
      if (err.response.status !== 200) {
        Vue.notify({
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Emergencias.nuevaSituacionOperativa,
          type: 'error'
        })
      } else if (err.request) {
        Vue.notify({
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Error[500],
          type: 'error'
        })
      }
    }
  }

  static async getChats (emergenciaId){
    try {
      return (await request.get(`emergencia/${emergenciaId}/chats`)).data.body
    } catch (err) {
      if (err.response && (err.response.status !== 200 && err.response.status !== 500)) {

        Vue.notify({
          title: err.response.statusText, 
          text: constants.notificacionesPermisos.Emergencias.getChats,
          type: 'error'
        })
      } else if (err.request) {
        Vue.notify({
          title: err.request.statusText, 
          text: constants.notificacionesPermisos.Error[500],
          type: 'error'
        })
      }
    }
  }

  static async createChat (nombre, emergenciaId, idEntidades) {
    try {
      return (await request.post(`emergencia/${emergenciaId}/chat`,{
        nombre,
        idEntidades
      })).data.body
    } catch (err) {
      if (err.response.status !== 200) {
        Vue.notify({
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Chat.createChat,
          type: 'error'
        })
      } else if (err.request) {
        Vue.notify({
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
          type: 'error'
        })
      }
    }
  }

  static async getDocumentos (emergenciaId){
    try {
      return (await request.get(`emergencia/${emergenciaId}/documentos`)).data.body
    } catch (err) {
      if (err.response && (err.response.status !== 200 && err.response.status !== 500)) {

        Vue.notify({
          title: err.response.statusText, 
          text: constants.notificacionesPermisos.Emergencias.getDocumentos,
          type: 'error'
        })
      } else if (err.request) {
        Vue.notify({
          title: err.request.statusText, 
          text: constants.notificacionesPermisos.Error[500],
          type: 'error'
        })
      }
    }
  }
}