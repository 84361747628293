var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":"","height":"100%"}},[_c('v-toolbar',{attrs:{"color":"primaryLight","dark":"","dense":""}},[(_vm.type === 'emergencias' && _vm.entidad_titular)?_c('v-toolbar-title',[_vm._v(_vm._s(_vm.entidad_titular)+" - Contactos de "+_vm._s(_vm.type))]):(_vm.type === 'administrativos' && _vm.entidad_titular)?_c('v-toolbar-title',[_vm._v(_vm._s(_vm.entidad_titular)+" - Contactos "+_vm._s(_vm.type))]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.comprobarPermisos(['MOD_CONTACTOS'])),expression:"comprobarPermisos(['MOD_CONTACTOS'])"}],attrs:{"fab":"","bottom":"","right":"","absolute":"","color":"primary","disabled":!_vm.userIsSameEntity() && !_vm.comprobarPermisos([])},on:{"click":_vm.showAddContactoDialog}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Añadir contacto")])])],1),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"xs":"3","sm":"3"}},[_c('v-text-field',{class:{'inputSelectMobile': _vm.$vuetify.breakpoint.mobile},attrs:{"append-icon":"mdi-magnify","label":"Búsqueda","outlined":"","dense":_vm.$vuetify.breakpoint.mobile,"clearable":"","hide-details":""},on:{"input":_vm.searchContactos},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-data-table',{staticClass:"table",attrs:{"headers":_vm.headersContactos,"items":_vm.contactosFilter ? _vm.contactosFilter : _vm.contactos ? _vm.contactos : [],"height":"100%","loading":_vm.isLoading,"loading-text":"Cargando datos... por favor, espere","fixed-header":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('p',[_vm._v("Datos no disponibles.")])]},proxy:true},{key:"item.titular",fn:function(ref){
var item = ref.item;
return [(item.titular === true)?_c('span',[_vm._v("Titular")]):_c('span',[_vm._v("Suplente")])]}},{key:"item.Telefono_contactos.fijo",fn:function(ref){
var item = ref.item;
return _vm._l((item.Telefono_contactos),function(subItem,iSub){return _c('div',{key:subItem.id_telefono_contacto},[(subItem.fijo)?_c('div',[(iSub < item.Telefono_contactos.findIndex(function (x) { return x.fijo === true; }).length - 1)?_c('span',[_c('a',{attrs:{"href":("tel:" + (subItem.numero))}},[_vm._v(_vm._s(subItem.numero))]),_vm._v(", ")]):_c('span',[_c('a',{attrs:{"href":("tel:" + (subItem.numero))}},[_vm._v(_vm._s(subItem.numero))])])]):_vm._e()])})}},{key:"item.Telefono_contactos",fn:function(ref){
var item = ref.item;
return _vm._l((item.Telefono_contactos),function(subItem,iSub){return _c('div',{key:subItem.id_telefono_contacto},[(!subItem.fijo)?_c('div',[(iSub < item.Telefono_contactos.findIndex(function (x) { return x.fijo === false; }).length - 1)?_c('span',[_c('a',{attrs:{"href":("tel:" + (subItem.numero))}},[_vm._v(_vm._s(subItem.numero))]),_vm._v(", ")]):_c('span',[_c('a',{attrs:{"href":("tel:" + (subItem.numero))}},[_vm._v(_vm._s(subItem.numero))])])]):_vm._e()])})}},{key:"item.Email_contactos",fn:function(ref){
var item = ref.item;
return _vm._l((item.Email_contactos),function(subItem,iSub){return _c('div',{key:subItem.id_email_contacto},[(iSub < item.Email_contactos.length - 1)?_c('span',[_c('a',{attrs:{"href":("mailto:" + (subItem.email))}},[_vm._v(_vm._s(subItem.email))]),_vm._v(", ")]):_c('span',[_c('a',{attrs:{"href":("mailto:" + (subItem.email))}},[_vm._v(_vm._s(subItem.email))])])])})}},{key:"item.fechastamp",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.fechastamp)))])]}},{key:"item.Usuario",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.Usuario.nombre + ' ' + item.Usuario.apellidos))])]}},{key:"item.ACCIONES",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.comprobarPermisos(['MOD_CONTACTOS'])),expression:"comprobarPermisos(['MOD_CONTACTOS'])"}],attrs:{"icon":"","disabled":!_vm.userIsSameEntity() && !_vm.comprobarPermisos([])},on:{"click":function($event){return _vm.showEditContactoDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"teal"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.comprobarPermisos(['MOD_CONTACTOS'])),expression:"comprobarPermisos(['MOD_CONTACTOS'])"}],attrs:{"icon":"","disabled":!_vm.userIsSameEntity() && !_vm.comprobarPermisos([])},on:{"click":function($event){return _vm.acceptDeleteContacto(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Borrar")])])]}}],null,true)})],1)],1)],1),_c('vx-dialog-add-edit-contacto',{attrs:{"show":_vm.showDialog,"isEdit":_vm.isEdit,"editedContacto":_vm.editContacto,"tipoContacto":_vm.type,"idEntidad":_vm.id_entidad},on:{"cancelar":function($event){_vm.showDialog = false},"addContacto":_vm.acceptAddContacto,"editContacto":_vm.acceptEditContacto}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }