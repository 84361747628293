import api from '@/api'
import constants from '@/helpers/constants'

let Q = require('q')

const state = {
  tiposRol: null
}

const getters = {
  tiposRol: (state) => state.tiposRol
}

const mutations = {
  SET_TIPOS_ROL (state, roles) {
    if (roles) {
      state.tiposRol = roles
    }
  },

  ERROR_AUTORIZACION (state, data) {
    this.notify(data.title, data.text, 'error')
  },

  ERROR_RESPUESTA_SERVER (state, data) {
    this.notify(data.title, data.text, 'error')
  }
}

const actions = {
  async fetchTiposRol ({ commit }) {
    let deferred = Q.defer()

    try {
      const response = await api.rol.getTiposRol()
      if (response.status === 200) {
        commit('SET_TIPOS_ROL', response.data.body)

        deferred.resolve()
      }
    } catch (err) {
      if (err.response && (err.response.status !== 200 && err.response.status !== 500)) {

        commit('ERROR_AUTORIZACION', 
          {
            title: err.response.statusText, 
            text: constants.notificacionesPermisos.Roles.getRoles
          })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500]
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  }
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default module