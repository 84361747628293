import api from '@/api'
import constants from '@/helpers/constants'

let Q = require('q')

const state = {
  medios: []

  // tiposMedio: [],
  // bases: [],
  // zonas: [],

  // emergencias: []
}

const getters = {
  medios: (state) => {
    return state.medios
  }
}

const mutations = {
  SET_MEDIOS (state, medios) {
    state.medios = medios
  },

  ERROR_AUTORIZACION (state, data) {
    this.notify(data.title, data.text, 'error')
  },

  ERROR_RESPUESTA_SERVER (state, data) {
    this.notify(data.title, data.text, 'error')
  }
}

const actions = {
  async fetchMedios ({ commit }) {
    let deferred = Q.defer()

    try {
      const response = await api.medio.getMedios()
      commit('SET_MEDIOS', response.data.body)

      deferred.resolve()
    } catch (err) {
      if (err.response && (err.response.status !== 200 && err.response.status !== 500)) {

        commit('ERROR_AUTORIZACION', 
          {
            title: err.response.statusText, 
            text: constants.notificacionesPermisos.MediosRecursos.getMedios
          })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500]
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  }
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default module
