<template>
  <div class="entidades">
    <v-list>
      <v-list-item-group
        v-if="selectedEntidades"
        v-model="selectedEntidades"
        multiple
        active-class=""
      >
        <v-list-item v-for="entidad in entidades" :key="entidad.id_entidad">
          <template #default="{ active }">
            <v-list-item-content>
              <v-list-item-title>{{ entidad.entidad_titular }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-account-cog-outline</v-icon>
              <v-checkbox
                v-if="userCanEditEmergencias"
                :input-value="active"
                color="primary"
              />
            </v-list-item-action>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>
<script>

export default {
  
  components: {
  },

  props: {
    entidades: null,
    value: null
  },

  data: () => ({
    selectedEntidades: [],
  }),

  async mounted (){
    await this.$store.dispatch('entidad/fetchEntidades')
  },

  computed: {
    userCanEditEmergencias (){
      return this.$store.getters['usuario/rolesUsuario'](['MOD_EMERGENCIAS'])
    },
  },

  watch: {
    selectedEntidades (newValue){
      if (newValue 
        && newValue.length > 0 
        && !this.userCanEditEmergencias)
      {
        this.selectedEntidades = []
      }
      this.$emit('input', newValue)
    },

    value (newValue){
      this.selectedEntidades = newValue
    }
  },

  methods: {

    isEntidadSelected (entidad){
      return this.entidadesChat
        .some(item => item.idEntidad  == entidad.id_entidad)
    },

  }

}
</script>

<style scoped lang="scss">

  .entidades {
    flex-grow: 1;
    height: 100%;
    overflow: auto;
    ::v-deep .v-list-item__title {
      text-overflow: inherit;
      white-space: normal;
    }
  }
  .buttons {
    padding: 8px;
    display: flex;
    gap: 6px;
    justify-content: space-between;
    width: 100%;
  }
  
</style>