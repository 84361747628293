import api from '@/api'
import constants from '@/helpers/constants'

let Q = require('q')

const state = {
  sectoresMedios: null
}

const getters = {
  sectoresMedios: (state) => {
    return state.sectoresMedios
  }
}

const mutations = {
  SET_SECTORES_MEDIOS (state, sectoresMedios) {
    state.sectoresMedios = sectoresMedios
  },

  ERROR_AUTORIZACION (state, data) {
    this.notify(data.title, data.text, 'error')
  },

  ERROR_RESPUESTA_SERVER (state, data) {
    this.notify(data.title, data.text, 'error')
  }
}

const actions = {
  async fetchSectoresMedios ({ commit }) {
    let deferred = Q.defer()

    try {
      const response = await api.sectorMedio.getSectoresMedios()
      if (response.status === 200) {
        commit('SET_SECTORES_MEDIOS', response.data.body)
      }

      deferred.resolve()
    } catch (err) {
      if (err.response && (err.response.status !== 200 && err.response.status !== 500)) {

        commit('ERROR_AUTORIZACION', 
          {
            title: err.response.statusText, 
            text: constants.notificacionesPermisos.SectorMedio.getSectoresMedio
          })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500]
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  }
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
  
export default module