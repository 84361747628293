<template>
  <v-card tile height="100%">
    <v-toolbar color="primaryLight" dark dense>
      <v-toolbar-title v-if="entidad">{{ entidad.entidad_titular }} - Gestión de delegaciones</v-toolbar-title>
      <!-- AÑADIR DELEGACION -->
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            fab
            bottom
            right
            absolute
            color="primary"
            :disabled="entidad ? !entidadCurrentUser(entidad.id_entidad) && !comprobarPermisos([]) : false"
            v-show="comprobarPermisos(['MOD_ENTIDADES'])"
            @click="showAddDelegacionDialog(entidad)"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Añadir delegación</span>
      </v-tooltip>
    </v-toolbar>

    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col xs="3" sm="3">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Búsqueda"
              outlined
              clearable
              hide-details
            />
          </v-col>
        </v-row>

        <v-row>
          <!-- <v-col xs="12" sm="12"> -->
          <v-data-table 
            class="table"
            :headers="headersDelegacion"
            :items="entidad ? entidad.Delegacions.filter(x => !x.is_default) : []"
            :search="search"
            :loading="isLoading"
            loading-text="Cargando datos... por favor, espere"
            :single-expand="true"
            :expanded.sync="expanded"
            item-key="id_delegacion"
            show-expand
            fixed-header
            disable-pagination
            hide-default-footer
          >
            <!-- NO DATA -->
            <template #no-data>
              <p>Datos no disponibles.</p>
            </template>

            <!-- USUARIO -->
            <template #[`item.Usuario`]="{ item }">
              <span>{{ item.Usuario.nombre + ' ' + item.Usuario.apellidos }}</span>
            </template>

            <!-- ACCIONES DELEGACION -->
            <template #[`item.ACCIONES`]="{ item }">
              <!-- EDITAR DELEGACION -->
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    :disabled="!entidadCurrentUser(entidad.id_entidad) && !comprobarPermisos([])"
                    v-show="comprobarPermisos(['MOD_ENTIDADES'])"
                    @click="showEditDelegacionDialog(item)"
                  >
                    <v-icon color="teal">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <!-- BORRAR DELEGACION -->
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    :disabled="!entidadCurrentUser(entidad.id_entidad) && !comprobarPermisos([])"
                    v-show="comprobarPermisos(['MOD_ENTIDADES'])"
                    @click="acceptDeleteDelegacion(item)"
                  >
                    <v-icon color="error">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Borrar</span>
              </v-tooltip>

              <!-- AÑADIR DEPARTAMENTO -->
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    color="primary"
                    :disabled="!entidadCurrentUser(entidad.id_entidad) && !comprobarPermisos([])"
                    v-show="comprobarPermisos(['MOD_ENTIDADES'])"
                    @click="showAddDepartamentoDialog(item)"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Añadir departamento</span>
              </v-tooltip>
            </template>

            <!-- EXPAND ROW DELEGACION -->
            <template #[`item.data-table-expand`]="{ item, expand, isExpanded }">
              <v-icon v-if="item.Departamentos.length > 1" @click="expand(!isExpanded)">mdi-chevron-down</v-icon>
            </template>

            <!-- EXPANDED TABLE DEPARTAMENTOS -->
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template #expanded-item="{ item }">
              <td :colspan="12" class="pa-2" style="backgroundColor: rgb(245 245 245)" v-if="item.Departamentos.length > 1">
                <div class="headerToolbarTitle">Lista de departamentos</div>
                <v-card>
                  <v-card-text>
                    <v-container>
                      <!-- SUBTABLA DEPARTAMENTOS (añadir, editar, eliminar) -->
                      <v-row>
                        <v-col xs="12" sm="12">
                          <v-data-table
                            dense
                            :search="search"
                            :headers="headersDepartamento"
                            :items="item.Departamentos.filter(x => !x.is_default)"
                            fixed-header
                            disable-pagination
                            hide-default-footer
                          >
                            <!-- NO DATA -->
                            <template #no-data>
                              <p>Datos no disponibles.</p>
                            </template>

                            <!-- USUARIO -->
                            <!-- eslint-disable-next-line -->
                            <template #[`item.Usuario`]="{ item }">
                              <span>{{ item.Usuario.nombre + ' ' + item.Usuario.apellidos }}</span>
                            </template>

                            <!-- LISTA DEPARTAMENTOS -->
                            <!-- eslint-disable-next-line -->
                            <template #[`item.Departamentos`]="{ item }">
                              <div v-for="(subItem, iSub) in item.Departamentos" :key="subItem.id_departamento">
                                <span v-if="iSub < item.Departamentos.length - 1"> {{ subItem.nombre }},</span>
                                <span v-else> {{ subItem.nombre }}</span>
                              </div>
                            </template>

                            <!-- ACCIONES DEPARTAMENTO -->
                            <!-- eslint-disable-next-line -->
                            <template #[`item.ACCIONES`]="{ item }">
                              <!-- EDITAR DEPARTAMENTO -->
                              <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    :disabled="!entidadCurrentUser(entidad.id_entidad) && !comprobarPermisos([])"
                                    v-show="comprobarPermisos(['MOD_ENTIDADES'])"
                                    @click="showEditDepartamentoDialog(item)"
                                  >
                                    <v-icon color="teal">mdi-pencil</v-icon>
                                  </v-btn>
                                </template>
                                <span>Editar departamento</span>
                              </v-tooltip>

                              <!-- BORRAR DEPARTAMENTO -->
                              <!-- eslint-disable-next-line -->
                              <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    :disabled="!entidadCurrentUser(entidad.id_entidad) && !comprobarPermisos([])"
                                    v-show="comprobarPermisos(['MOD_ENTIDADES'])"
                                    @click="acceptDeleteDepartamento(item)"
                                  >
                                    <v-icon color="error">mdi-delete</v-icon>
                                  </v-btn>
                                </template>
                                <span>Borrar departamento</span>
                              </v-tooltip>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </td>  
            </template>
          </v-data-table>
          <!-- </v-col> -->
        </v-row>
      </v-container>
    </v-card-text>

    <!-- DIALOG ADD/EDIT DELEGACION -->
    <vx-dialog-add-edit-delegacion
      :show="showDialogDelegacion"
      :isEditDelegacion="isEditDelegacion"
      :editedDelegacion="editDelegacion"
      :newDelegacion="editEntidad"
      @cancelar="showDialogDelegacion = false"
      @addDelegacion="acceptAddDelegacion"
      @editDelegacion="acceptEditDelegacion"
    />

    <!-- DIALOG ADD/EDIT DEPARTAMENTO -->
    <vx-dialog-add-edit-departamento
      :show="showDialogDepartamento"
      :isEditDepartamento="isEditDepartamento"
      :editedDepartamento="editDepartamento"
      :newDepartamento="editDelegacion"
      @cancelar="showDialogDepartamento = false"
      @addDepartamento="acceptAddDepartamento"
      @editDepartamento="acceptEditDepartamento"
    />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import DialogAddEditDelegacion from './DialogAddEditDelegacion.vue'
import DialogAddEditDepartamento from './departamentos/DialogAddEditDepartamento.vue'
export default {
  components: {
    'vx-dialog-add-edit-delegacion': DialogAddEditDelegacion,
    'vx-dialog-add-edit-departamento': DialogAddEditDepartamento
  },
  data: () => ({

    headersDelegacion: [
      { text: 'Delegacion', value: 'nombre', align: 'left', sortable: true },
      { text: 'Dirección', value: 'domicilio', align: 'left', sortable: true },
      { text: 'CP', value: 'cp', align: 'left', sortable: true },
      { text: 'Distrito', value: 'Distrito.nombre', align: 'left', sortable: true},
      { text: 'Usuario', value: 'Usuario', align: 'left', width: '140px', sortable: true }
    ],

    headersDepartamento: [
      { text: 'Nombre', value: 'nombre', align: 'left', sortable: true },
      { text: 'Usuario', value: 'Usuario', align: 'left', width: '140px', sortable: true }
    ],

    isLoading: false,

    search: '',

    expanded: [],
    expandedDepartamento: [],
    entidadesTable: [],

    showDialog: false,
    editEntidad: {},
    isEdit: false,

    showDialogDelegacion: false,
    editDelegacion: {},
    isEditDelegacion: false,

    showDialogDepartamento: false,
    isEditDepartamento: false,
    editDepartamento: {},

    id_entidad: null,
    entidad: null,
    delegaciones: null
  }),

  computed: {
    ...mapGetters('entidad', [
      'getEntidadById'
    ])
  },

  async activated () {
    let id_entidad = this.$route.query.id_entidad

    if (id_entidad) {
      this.id_entidad = id_entidad
      this.isLoading = true
      await this.getEntidad(id_entidad)
      this.isLoading = false
    }
  },

  methods: {
    ...mapActions('entidad', [
      'fetchEntidades', 'fetchListaEntidades', 'addDelegacion', 'updateDelegacion', 'deleteDelegacion', 'addDepartamento', 'updateDepartamento'
    ]),

    async getEntidad (id_entidad) {
      // Entidades de backend/store con delegaciones y departamentos por defecto
      let entidadTmp = await this.getEntidadById(id_entidad)

      if (entidadTmp) {
        // Quitar delegacion/Departamento por defecto
        this.entidad = JSON.parse(JSON.stringify(entidadTmp))
      }
    },

    comprobarPermisos (listaRolesPermisos) {
      return this.$store.getters['usuario/rolesUsuario'](listaRolesPermisos)
    },

    entidadCurrentUser (id_entidad){
      return this.$store.getters['usuario/permisosEntidad'](id_entidad)
    },

    showAddDelegacionDialog (item) {
      this.editEntidad = Object.assign({}, item)
      this.editDelegacion = null
      this.showDialogDelegacion = true
      this.isEditDelegacion = false
    },

    showEditDelegacionDialog (item) {
      this.editEntidad = null
      this.editDelegacion = Object.assign({}, item)
      this.showDialogDelegacion = true
      this.isEditDelegacion = true
    },

    async acceptAddDelegacion (entidad, delegacion) {
      this.showDialogDelegacion = false
      let data = {
        entidad: entidad,
        delegacion: delegacion
      }
      await this.addDelegacion(data)
      await this.getEntidad(this.id_entidad)
      await this.fetchEntidades()
      await this.fetchListaEntidades()
    
    },

    async acceptEditDelegacion (delegacion) {
      this.showDialogDelegacion = false
      await this.updateDelegacion(delegacion)
      await this.getEntidad(this.id_entidad)
      await this.fetchEntidades()
      await this.fetchListaEntidades()
    },

    async acceptDeleteDelegacion (delegacion) {
      if (this.departamentoDelegacionTieneUnidadesDeMedioAsignadas (delegacion)) {
        this.notificarDepartamentoDelegacionTieneUnidadMedioAsignada()
      } else {
        let msg = 'Eliminando la delegación: ' + delegacion.nombre
        this.$root.$confirmDialog.open('¿Está seguro?', msg).then((result) => {
          if (result) {
            this.$store.dispatch('entidad/deleteDelegacion', delegacion).then(() => {
              if (result) {
                this.recargarDatos()
              }
            })
          }
        })
      }
    },

    departamentoDelegacionTieneUnidadesDeMedioAsignadas (delegacion) {
      // Comprobar si alguno de los departamenos de la delegacion, tienen unidades de medios asignados
      let tieneUnidadMedio = false
      delegacion.Departamentos.forEach(departamento => {
        if (departamento.Unidad_medios.length > 0) {
          departamento.Unidad_medios.forEach(unidadMedio => {
            if (unidadMedio) {
              tieneUnidadMedio = true
            }
          })
        }
      })

      return tieneUnidadMedio
    },

    notificarDepartamentoDelegacionTieneUnidadMedioAsignada () {
      this.$notify({
        title: 'Departamento de la delegación con medios',
        text: 'Es necesario mover las unidades de los departamentos, antes de borrar la delegación.'
      })
    },

    showAddDepartamentoDialog (item) {
      this.editDelegacion = Object.assign({}, item)
      this.editDepartamento = null
      this.showDialogDepartamento = true
      this.isEditDepartamento = false
    },

    showEditDepartamentoDialog (item) {
      this.editDelegacion = null
      this.editDepartamento = Object.assign({}, item)
      this.showDialogDepartamento = true
      this.isEditDepartamento = true
    },

    async acceptAddDepartamento (delegacion, departamento) {
      this.showDialogDepartamento = false
      let data = {
        delegacion: delegacion,
        departamento: departamento
      }
      await this.addDepartamento(data)
      await this.getEntidad(this.id_entidad)
      await this.fetchEntidades()
      await this.fetchListaEntidades()
    },

    async acceptEditDepartamento (departamento) {
      this.showDialogDepartamento = false
      await this.updateDepartamento(departamento)
      await this.getEntidad(this.id_entidad)
      await this.fetchEntidades()
      await this.fetchListaEntidades()
    },

    async acceptDeleteDepartamento (departamento) {
      if (this.departamentoTieneUnidadesDeMedioAsignadas(departamento)) {
        this.notificarDepartamentoTieneUnidadMedioAsignada()
      } else {
        let msg = 'Eliminando el departamento ' + departamento.nombre
        this.$root.$confirmDialog.open('¿Está seguro?', msg).then((result) => {
          if (result) {
            this.$store.dispatch('entidad/deleteDepartamento', departamento).then(() => {
              if (result) {
                this.recargarDatos()
              }
            })
          }
        })
      }
    },

    departamentoTieneUnidadesDeMedioAsignadas (departamento) {
      return departamento.Unidad_medios.length > 0 ? true : false
    },

    notificarDepartamentoTieneUnidadMedioAsignada () {
      this.$notify({
        title: 'Departamento con medios',
        text: 'Es necesario mover las unidades, antes de borrar el departamento.'
      })
    },

    async recargarDatos () {
      this.isLoading = true
      await this.fetchEntidades()
      await this.fetchListaEntidades()
      await this.getEntidad(this.id_entidad)
      this.isLoading = false
    },
  },

  async created () {

    // Mostrar acciones si currentUsuario tiene rol admin o rol mod_entidades
    if (this.$store.getters['usuario/rolesUsuario'](['MOD_ENTIDADES'])) {
      // Acciones delegacion
      this.headersDelegacion.push({ text: 'Acciones', value: 'ACCIONES', align: 'center', width: '140px', sortable: false})

      // Acciones departamento
      this.headersDepartamento.push({ text: 'Acciones', value: 'ACCIONES', align: 'center', width: '140px', sortable: false})

      // Delegacion -> Fila expandible a la derecha
      this.headersDelegacion.push({ text: '', value: 'data-table-expand' })
    } else if (this.$store.getters['usuario/rolesUsuario'](['VER_ENTIDADES'])) {

      // Delegacion -> Fila expandible a la derecha
      this.headersDelegacion.push({ text: '', value: 'data-table-expand'})
    }
  }

}
</script>

<style lang="scss" scoped>
/* Estilos para que la tabla tenga un max-height y pueda crecer */
.table {
  width: 100vw;
  max-height: calc(100vh - 215px)
}
.v-data-table {
  overflow: auto;
}
.v-data-table >>> .v-data-table__wrapper {
  /* Header fijo */
  overflow: unset;
}

.headerToolbarTitle{
    font-size: 1.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
}

/* Hover transparente para todas las filas */
::v-deep tbody {
          tr:hover {
            background-color: transparent !important;
          }
        }
/* Hover en color gris para las filas impares */
::v-deep tbody {
  tr:hover:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05) !important;
  }
}
/* Color gris fila data table impar */
::v-deep tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}

/* Sticky column data-table - Delegacion */
::v-deep thead tr th:nth-of-type(1) {
  position: sticky;
  left: 0;
}

::v-deep tbody tr:nth-of-type(odd) td:nth-of-type(1) {
  background: whitesmoke;
  position: sticky;
  left: 0;
}

::v-deep tbody tr td:nth-of-type(1) {
  background: white;
  position: sticky;
  left: 0;
}

/* Sticky column data-table - Acciones */
::v-deep thead tr th:nth-of-type(6) {
  position: sticky;
  right: 0;
}

::v-deep tbody tr:nth-of-type(odd) td:nth-of-type(6) {
  background: whitesmoke;
  position: sticky;
  right: 0;
}

::v-deep tbody tr td:nth-of-type(6) {
  background: white;
  position: sticky;
  right: 0;
}
</style>