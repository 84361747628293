<template>
  <v-card tile height="100%">
    <v-toolbar color="primaryLight" dark dense style="z-index: 2">
      <v-toolbar-title>Gestión documental</v-toolbar-title>

      <!-- AÑADIR DOCUMENTO -->
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            fab
            bottom
            right
            absolute
            color="primary"
            v-show="comprobarPermisos(['MOD_DOCUMENTACION'])"
            @click="showAddDocumentoDialog"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Añadir documento</span>
      </v-tooltip>
    </v-toolbar>

    <v-card-text>
      <v-container fluid>
        <v-row class="filters">
          <v-col cols="6" md="3" sm="3">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Búsqueda"
              :dense="$vuetify.breakpoint.mobile"
              outlined
              clearable
              hide-details
              @input="searchDocumentos"
            />
          </v-col>

          <v-col cols="6" md="2" sm="3">
            <v-select
              label="Estado"
              :items="documentoValido"
              :menu-props="{ bottom: true, offsetY: true }"
              item-text="estado"
              item-value="estado"
              v-model="estadoSelected"
              return-object
              :dense="$vuetify.breakpoint.mobile"
              outlined
              hide-details
              :clearable="true"
            />
          </v-col>

          <v-col cols="6" md="2" sm="3">
            <v-select
              :class="{'inputSelectMobile': $vuetify.breakpoint.mobile}"
              label="Emergencias"
              :items="emergencias ? emergencias : []"
              :menu-props="{ bottom: true, offsetY: true }"
              item-text="nombre"
              item-value="nombre"
              v-model="emergenciaSelected"
              return-object
              :dense="$vuetify.breakpoint.mobile"
              outlined
              hide-details
              :clearable="true"
            />
          </v-col>

          <v-col cols="6" md="2" sm="3">
            <v-select
              :class="{'inputSelectMobile': $vuetify.breakpoint.mobile}"
              label="Categoria"
              :items="categoriasDocumento ? categoriasDocumento : []"
              :menu-props="{ bottom: true, offsetY: true }"
              item-text="nombre"
              item-value="nombre"
              v-model="categoriaDocumentoSelected"
              return-object
              :dense="$vuetify.breakpoint.mobile"
              outlined
              hide-details
              :clearable="true"
            />
          </v-col>

          <v-col cols="6" md="3" sm="3" :class="$vuetify.breakpoint.mobile ? 'checkboxMobile' : ''">
            <v-checkbox v-model="showArchivedDocuments">
              <template #label>
                <div>
                  Mostrar documentos archivados.
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>

        <v-row class="documents-table">
          <v-data-table
            :class="{
              'table': !$vuetify.breakpoint.mobile,
              'tableMobile': $vuetify.breakpoint.mobile}"
            :headers="headers"
            :items="documentosFilter ? documentosFilter : documentos"
            height="100%"
            :loading="isLoading"
            loading-text="Cargando datos... por favor, espere"
            fixed-header
            hide-default-footer
            :page.sync="currentPage"
            :items-per-page="itemsPerPage"
            @pagination="paginationData = $event"
          >
            <!-- NO DATA -->
            <template #no-data>
              <p>Datos no disponibles.</p>
            </template>

            <!-- FECHA ULTIMA ACTUALIZACION -->
            <template #[`item.fechastamp`]="{ item }">
              <span>{{ item.fechastamp | formatDate }}</span>
            </template>

            <!-- ICONO DOCUMENTO -->
            <template #[`item.nombre.icon`]="{ item }">
              <span v-if="item.ruta">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="descargarArchivo(item)"
                    >
                      <img :src="iconoTipoArchivo(item)">
                    </v-btn>
                  </template>
                  <span> {{ showFileNameWithOutTime(item.ruta) }}</span>
                </v-tooltip>
              </span>
            </template>

            <!-- NOMBRE DOCUMENTO -->
            <template #[`item.nombre`]="{ item }">
              <span v-if="(item.nombre !== item.ruta) && (item.Categoria_documento.nombre !== 'Mensajería')"> {{ item.nombre }}</span>
              <span v-if="(item.nombre && !item.ruta) && (item.Categoria_documento.nombre === 'Mensajería')"> {{ item.nombre }}</span>
            </template>

            <!-- NOMBRE FICHERO -->
            <template #[`item.ruta`]="{ item }">
              <span> {{ showFileNameWithOutTime(item.ruta) }} </span>
            </template>

            <!-- ESTADO -->
            <template #[`item.valido`]="{ item }">
              <span v-if="item.valido">Válido</span>
              <span v-else>No válido</span>
            </template>

            <!-- USUARIO -->
            <template #[`item.Usuario`]="{ item }">
              <span>{{ item.Usuario.nombre + ' ' + item.Usuario.apellidos }}</span>
            </template>

            <template #[`item.is_archivado`]="{}" />

            <!-- ACCIONES -->
            <template #[`item.ACCIONES`]="{ item }">
              <!-- EDITAR DOCUMENTO -->
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    v-show="comprobarPermisos(['MOD_DOCUMENTACION'])"
                    @click="showEditDocumentoDialog(item)"
                  >
                    <v-icon color="teal">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <!-- BORRAR DOCUMENTO -->
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    v-show="comprobarPermisos(['MOD_DOCUMENTACION'])"
                    @click="acceptValidateInvalidateDocumento(item)"
                  >
                    <v-icon v-if="item.valido" color="red">mdi-close</v-icon>
                    <v-icon v-else color="green">mdi-check</v-icon>
                  </v-btn>
                </template>
                <span v-if="item.valido">Invalidar documento</span>
                <span v-else>Validar documento</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-row>

        <!-- SECCIÓN PAGINACIÓN -->
        <v-row class="pagination">
          <v-col xs="2" sm="3" md="2" v-if="!$vuetify.breakpoint.mobile">
            <v-row>
              <div class="text-center align-center mt-6 pl-6 ml-6">
                <span>Total {{ totalRecords }} registros</span>
              </div>
            </v-row>
          </v-col>
          <v-col xs="1" sm="4" md="3">
            <v-row>
              <v-col sm="4" md="4" class="textMostrar">
                <div>
                  <span>Mostrar</span>
                </div>
              </v-col>
              <v-col xs="4" sm="5" md="4">
                <v-select
                  dense
                  outlined
                  hide-details
                  v-model="itemsPerPage"
                  :items="perPageOptions"
                  :class="{
                    'select': !$vuetify.breakpoint.mobile,
                    'selectMobile': $vuetify.breakpoint.mobile}"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="4" md="6">
            <div>
              <v-pagination
                v-model="currentPage"
                :length="pageCount"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <!-- DIALOG ADD/EDIT DOCUMENTO -->
    <vx-dialog-add-edit-documento
      :show="showDialog"
      :isEdit="isEdit"
      :editedDocumento="editDocumento"
      @cancelar="showDialog = false"
      @addDocumento="acceptAddDocumento"
      @editDocumento="acceptEditDocumento"
    />
  </v-card>
</template>

<script>

import { FileHelper } from '@/helpers/FileHelper'
import { mapActions, mapGetters } from 'vuex'
import { StringHelper } from '@/helpers/StringHelper'

import DialogAddEditDocumento from './DialogAddEditDocumento.vue'

export default {

  components: {
    'vx-dialog-add-edit-documento': DialogAddEditDocumento
  },

  data: () => ({
    search: '',
    documentosFilter: null,
    isLoading: false,
    icon: null,
    estadoSelected: {
      estado: 'Válido',
      value: true
    },
    paginationData: null,
    showArchivedDocuments: false,
    emergencias: [],
    emergenciaSelected: undefined,
    categoriaDocumentoSelected: undefined,
    showDialog: false,
    isEdit: false,
    editDocumento: {},
    fileNameWithOutTime: null,
    currentPage: 1,
    itemsPerPage: 15,
    perPageOptions: [15, 50, 75, 100],
  }),

  computed: {
    ...mapGetters('categoriaDocumento', ['categoriasDocumento']),
    ...mapGetters('documento', ['documentos']),

    documentoValido () {
      return [
        {
          estado: 'Válido',
          value: true
        },
        {
          estado: 'No válido',
          value: false
        }
      ]
    },

    headers () {
      return [
        { text: '', value: 'nombre.icon', icon: null, align: 'left', width: '20px', sortable: false },
        { text: 'Documento', value: 'nombre', icon: null, align: 'left', width: '140px', sortable: true },
        { text: 'Fichero', value: 'ruta', align: 'left', width: '120px', sortable: true },
        { text: 'Categoría', value: 'Categoria_documento.nombre', align: 'left', width: '100px', sortable: true, filter: this.filterCategoriaDocumento },
        { text: 'Id Emergencia', value: 'Emergencium.codigo', align: 'left', width: '140px', sortable: true },
        { text: 'Emergencia', value: 'Emergencium.nombre', align: 'left', width: '140px', sortable: true, filter: this.filterEmergencia },
        { text: 'Estado', value: 'valido', align: 'left', width: '100px', sortable: true, filter: this.filterEstado },
        { text: 'Ult. actualización', value: 'fechastamp', align: 'left',  width: '160px', sortable: true },
        { text: 'Usuario', value: 'Usuario', align: 'left', width: '140px', sortable: true },
        { value: 'is_archivado', width: '0px' , filter: this.filterIsArchived}
      ]
    },

    totalRecords () {
      return (this.paginationData && this.paginationData.itemsLength) || 0
    },

    pageCount () {
      return parseInt((this.totalRecords / this.itemsPerPage) + 1)
    }
  },

  watch: {
    paginationData (newValue) {
      this.currentPage = newValue.page
      this.itemsPerPage = newValue.itemsPerPage
    },
  },

  methods: {
    ...mapActions('categoriaDocumento', ['fetchCategoriasDocumento']),
    ...mapActions('documento', ['fetchDocumentos', 'addDocumento', 'updateDocumento']),
    ...mapActions('emergencia', ['fetchEmergencias']),

    comprobarPermisos (listaRolesPermisos) {
      return this.$store.getters['usuario/rolesUsuario'](listaRolesPermisos)
    },

    showAddDocumentoDialog () {
      this.editDocumento = null
      this.showDialog = true
      this.isEdit = false
    },

    showEditDocumentoDialog (documento) {
      this.editDocumento = Object.assign({}, documento)
      this.showDialog = true
      this.isEdit = true
    },

    async acceptAddDocumento (documento) {
      this.showDialog = false
      try {
        await this.addDocumento(documento)
        await this.fetchDocumentos()
      } catch (error) {
        if (error.request.status === 500) {
          this.$notify({
            title: 'Error al guardar el documento',
            text: error.request.response,
            type: 'error'
          })
        }
      }
      
    },

    async acceptEditDocumento (documento) {
      this.showDialog = false
      try {
        await this.updateDocumento(documento)
        await this.fetchDocumentos()
      } catch (error) {
        if (error.request.status === 500) {
          this.$notify({
            title: 'Error al actualizar el documento',
            text: error.request.response,
            type: 'error'
          })
        }
      }
      
    },

    acceptValidateInvalidateDocumento (documento) {
      let validarDocumento = Object.assign({}, documento)
      let msg = ''
      // Mensaje en funcion de si validamos o invalidamos el documento
      validarDocumento.valido && (msg = 'Invalidando el documento ' + documento.nombre)
      !validarDocumento.valido && (msg = 'Validando el documento ' + documento.nombre)

      // Cambiar valor de atributo valido del documento
      validarDocumento.valido = !validarDocumento.valido

      this.$root.$confirmDialog.open('¿Está seguro?', msg).then((result) => {
        if (result) {
          this.$store.dispatch('documento/validateInvalidateDocumentoById', {documento_id: validarDocumento.id_documento, valido: validarDocumento.valido}).then((result2) => {
            if (result2) {
              this.notificarDocumentoValidadoInvalidado(validarDocumento)
              // Listar nuevamente los documentos
              this.fetchDocumentos()
            }
          })
        }
      })
    },

    notificarDocumentoValidadoInvalidado (documento) {
      this.$notify({
        title: documento.valido ? 'Documento validado' : 'Documento invalidado',
        text: documento.valido ? 
          `Se ha vuelto a validar el documento ${documento.nombre}` 
          : `Se ha invalidado el documento ${documento.nombre}`
      })
    },

    async descargarArchivo (documento) {
      FileHelper.downloadFile(documento)
    },

    iconoTipoArchivo (archivo) {
      return FileHelper.getFileTypeIcon(archivo)
    },

    async loadData () {
      this.isLoading = true
      try {
        await this.fetchCategoriasDocumento()
        await this.fetchDocumentos()
        await this.fetchEmergencias()
        if (this.$store.getters['emergencia/emergencias']) {
          this.emergencias = this.$store.getters['emergencia/orderEmergenciasByNombreAsc']
        }
      } catch (err) {
        if (err.response.status !== 200 && err.response.status !== 500) {
          this.$notify({
            title: err.response.statusText,
            text: 'El usuario no dispone de permisos para acceder al módulo de Repositorio documental',
            type: 'error'
          })
        }
        this.$router.push('/')
      }
      
      this.isLoading = false
    },

    filterEmergencia (value) {
      if (!this.emergenciaSelected) {
        return true
      }

      return value === this.emergenciaSelected.nombre
    },

    filterEstado (value) {
      if (!this.estadoSelected) {
        return true
      }
      return value === this.estadoSelected.value
    },

    filterIsArchived (value) {
      return value === this.showArchivedDocuments
    },

    filterCategoriaDocumento (value) {
      if (!this.categoriaDocumentoSelected) {
        return true
      }
      return value === this.categoriaDocumentoSelected.nombre
    },

    searchDocumentos () {

      // Atributos no validos para filtrar
      function atributoValido (atributo) {
        let atributosNoValidos = ['borrado','fechastamp', 'valido', 'activa', 
          'fechaActivacion', 'fechaCreacion', 'latitude', 'longitude', 'usuariostamp'
          , 'ruta', 'id_emergencia', 'id_documento', 'id_categoria_documento', 'id'
          , 'tipo', 'is_archivado']
        let encontrado = atributosNoValidos.find(x => x === atributo)
        return encontrado ? false : true
      }

      // Filtro de busqueda para todos los documentos
      let searchText = this.search ? this.search : ''

      this.documentosFilter = this.documentos.filter(function (documentos) {
        for (var attrDocumento in documentos) {
          if (atributoValido(attrDocumento)) {
            if (attrDocumento === 'Usuario') {
              for (var attrUsuario in documentos.Usuario) {
                if (atributoValido(attrUsuario)) {
                  let atributo = documentos.Usuario['nombre'] + ' ' + documentos.Usuario['apellidos']
                  if (StringHelper.compareString(searchText, atributo)) return documentos.Usuario
                }
              }
            } else if (attrDocumento === 'Categoria_documento') {
              // Categoria_documento
              for (var attrCateDocu in documentos.Categoria_documento) {
                if (atributoValido(attrCateDocu)) {
                  let atributo = documentos.Categoria_documento[attrCateDocu]
                  if (StringHelper.compareString(searchText, atributo)) return documentos.Categoria_documento
                }
              }
            } else if (attrDocumento === 'Emergencium') {
              // Emergencia
              if (documentos.Emergencium) {
                for (var attrEmergencia in documentos) {
                  if (atributoValido(attrEmergencia) && documentos.Emergencium[attrEmergencia]) {
                    let atributo = documentos.Emergencium[attrEmergencia]
                    if (StringHelper.compareString(searchText, atributo)) return documentos.Emergencium
                  }
                }
              }
            } else {
              if (documentos[attrDocumento]) {
                let atributo = documentos[attrDocumento]

                if (StringHelper.compareString(searchText, atributo)) return documentos
              }
            }
          }
        }
      })
    },

    showFileNameWithOutTime (fileName) {
      return fileName ? FileHelper.getFileNameWithOutTime(fileName) : ''
    }
  },

  async created () {
    // Mostrar acciones si currentUsuario rol admin o mod_documentacion
    if (this.$store.getters['usuario/rolesUsuario'](['MOD_DOCUMENTACION'])) {
      // Acciones documentacion
      this.headers.push({ text: 'Acciones', value: 'ACCIONES', align: 'center', width:'140px', sortable: false })
    }

    // await this.loadData()
  },

  
  async activated () {
    // Recargar documentos al entrar al módulo de gestión documental
    await this.loadData()
  }
}
</script>

<style lang="scss" scoped>

/* Estilos para que la tabla tenga un max-height y pueda crecer */
.table {
  width: 100vw;
  max-height: calc(100vh - 280px);
}
.tableMobile {
  width: 100vw;
  max-height: calc(100vh - 380px)
}

.v-data-table {
  overflow: auto;
}

.v-data-table >>> .v-data-table__wrapper {
  overflow: unset;
}

/* Hover transparente para todas las filas */
::v-deep tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
/* Hover en color gris para las filas impares */
::v-deep tbody {
  tr:hover:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05) !important;
  }
}
/* Color gris fila data table impar */
::v-deep tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}

/* Sticky column data-table - Nombre */
::v-deep thead tr th:nth-of-type(1) {
  position: sticky;
  left: 0;
}

::v-deep tbody tr:nth-of-type(odd) td:nth-of-type(1) {
  background: whitesmoke;
  position: sticky;
  left: 0;
}

::v-deep tbody tr td:nth-of-type(1) {
  background: white;
  position: sticky;
  left: 0;
}

/* Sticky column data-table - Acciones */
::v-deep thead tr th:nth-of-type(10) {
  position: sticky;
  right: 0;
}

::v-deep tbody tr:nth-of-type(odd) td:nth-of-type(10) {
  background: whitesmoke;
  position: sticky;
  right: 0;
}

::v-deep tbody tr td:nth-of-type(10) {
  background: white;
  position: sticky;
  right: 0;
}

/* Tamnaño inputs y select */
.inputSelectMobile {
  margin-top: -15px;
  margin-bottom: 0px;
}

/* Tamaño select mostrar por página */
.select {
  width: 85px;
}
.selectMobile {
  width: 85px;
}

/* Mostrar */
.textMostrar {
  margin-top: 10px;
  padding-left: 0px;
  padding-right: 0px;
  display: flex; 
  justify-content: flex-end;
}

/* Resultados */
.textResultados {
  margin-top: 10px;
  padding-left: 0px;
  padding-right: 0px;
  margin-left: -15px;
  margin-right: 0px;
  display: flex; 
  justify-content: flex-start;
}

/* Paginacion */
.paginacion {
  padding-left: 0px;
  padding-right: 0px;
  display: flex; 
  justify-content: flex-end;
}
</style>