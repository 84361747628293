import Vue from 'vue'
import request from './request'
import constants from '@/helpers/constants'

export class FaseEmergenciaRequest {
  static async getAll (){
    try {
      return (await request.get('faseEmergencia')).data.body
    } catch (err) {
      if (err.response && err.response.status !== 200) {
        Vue.notify({
          title: err.response.statusText,
          text: constants.notificacionesPermisos.FaseEmergencia.getFasesEmergencia,
          type: 'error'
        })
      } else if (err.request) {
        Vue.notify({
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
          type: 'error'
        })
      }
    }
  }

  static async getBySituacionOperativa (situacionOperativaId) {
    try {
      return (await request.get('faseEmergencia', {
        params: {
          situacionOperativa: situacionOperativaId
        }
      })).data.body
    } catch (err) {
      if (err.response && err.response.status !== 200) {
        Vue.notify({
          title: 'Se ha producido un error',
          text: err.response.data.message,
          type: 'error'
        })
      } else if (err.request) {
        Vue.notify({
          title: 'Se ha producido un error',
          text: 'No ha habido respuesta por parte del servidor',
          type: 'error'
        })
      }
    }
  }
}
