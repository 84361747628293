import { render, staticRenderFns } from "./EntidadesChat.vue?vue&type=template&id=a5f52cc8&scoped=true&"
import script from "./EntidadesChat.vue?vue&type=script&lang=js&"
export * from "./EntidadesChat.vue?vue&type=script&lang=js&"
import style0 from "./EntidadesChat.vue?vue&type=style&index=0&id=a5f52cc8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5f52cc8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCheckbox,VIcon,VList,VListItem,VListItemAction,VListItemContent,VListItemGroup,VListItemTitle,VTooltip})
