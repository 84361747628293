var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":"","height":"100%"}},[_c('v-toolbar',{attrs:{"color":"primaryLight","dark":"","dense":""}},[(_vm.entidad)?_c('v-toolbar-title',[_vm._v(_vm._s(_vm.entidad.entidad_titular)+" - Gestión de delegaciones")]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.comprobarPermisos(['MOD_ENTIDADES'])),expression:"comprobarPermisos(['MOD_ENTIDADES'])"}],attrs:{"fab":"","bottom":"","right":"","absolute":"","color":"primary","disabled":_vm.entidad ? !_vm.entidadCurrentUser(_vm.entidad.id_entidad) && !_vm.comprobarPermisos([]) : false},on:{"click":function($event){return _vm.showAddDelegacionDialog(_vm.entidad)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Añadir delegación")])])],1),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"xs":"3","sm":"3"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Búsqueda","outlined":"","clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-data-table',{staticClass:"table",attrs:{"headers":_vm.headersDelegacion,"items":_vm.entidad ? _vm.entidad.Delegacions.filter(function (x) { return !x.is_default; }) : [],"search":_vm.search,"loading":_vm.isLoading,"loading-text":"Cargando datos... por favor, espere","single-expand":true,"expanded":_vm.expanded,"item-key":"id_delegacion","show-expand":"","fixed-header":"","disable-pagination":"","hide-default-footer":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('p',[_vm._v("Datos no disponibles.")])]},proxy:true},{key:"item.Usuario",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.Usuario.nombre + ' ' + item.Usuario.apellidos))])]}},{key:"item.ACCIONES",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.comprobarPermisos(['MOD_ENTIDADES'])),expression:"comprobarPermisos(['MOD_ENTIDADES'])"}],attrs:{"icon":"","disabled":!_vm.entidadCurrentUser(_vm.entidad.id_entidad) && !_vm.comprobarPermisos([])},on:{"click":function($event){return _vm.showEditDelegacionDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"teal"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.comprobarPermisos(['MOD_ENTIDADES'])),expression:"comprobarPermisos(['MOD_ENTIDADES'])"}],attrs:{"icon":"","disabled":!_vm.entidadCurrentUser(_vm.entidad.id_entidad) && !_vm.comprobarPermisos([])},on:{"click":function($event){return _vm.acceptDeleteDelegacion(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Borrar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.comprobarPermisos(['MOD_ENTIDADES'])),expression:"comprobarPermisos(['MOD_ENTIDADES'])"}],attrs:{"icon":"","color":"primary","disabled":!_vm.entidadCurrentUser(_vm.entidad.id_entidad) && !_vm.comprobarPermisos([])},on:{"click":function($event){return _vm.showAddDepartamentoDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Añadir departamento")])])]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [(item.Departamentos.length > 1)?_c('v-icon',{on:{"click":function($event){return expand(!isExpanded)}}},[_vm._v("mdi-chevron-down")]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [(item.Departamentos.length > 1)?_c('td',{staticClass:"pa-2",staticStyle:{"backgroundColor":"rgb(245 245 245)"},attrs:{"colspan":12}},[_c('div',{staticClass:"headerToolbarTitle"},[_vm._v("Lista de departamentos")]),_c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"xs":"12","sm":"12"}},[_c('v-data-table',{attrs:{"dense":"","search":_vm.search,"headers":_vm.headersDepartamento,"items":item.Departamentos.filter(function (x) { return !x.is_default; }),"fixed-header":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('p',[_vm._v("Datos no disponibles.")])]},proxy:true},{key:"item.Usuario",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.Usuario.nombre + ' ' + item.Usuario.apellidos))])]}},{key:"item.Departamentos",fn:function(ref){
var item = ref.item;
return _vm._l((item.Departamentos),function(subItem,iSub){return _c('div',{key:subItem.id_departamento},[(iSub < item.Departamentos.length - 1)?_c('span',[_vm._v(" "+_vm._s(subItem.nombre)+",")]):_c('span',[_vm._v(" "+_vm._s(subItem.nombre))])])})}},{key:"item.ACCIONES",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.comprobarPermisos(['MOD_ENTIDADES'])),expression:"comprobarPermisos(['MOD_ENTIDADES'])"}],attrs:{"icon":"","disabled":!_vm.entidadCurrentUser(_vm.entidad.id_entidad) && !_vm.comprobarPermisos([])},on:{"click":function($event){return _vm.showEditDepartamentoDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"teal"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar departamento")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.comprobarPermisos(['MOD_ENTIDADES'])),expression:"comprobarPermisos(['MOD_ENTIDADES'])"}],attrs:{"icon":"","disabled":!_vm.entidadCurrentUser(_vm.entidad.id_entidad) && !_vm.comprobarPermisos([])},on:{"click":function($event){return _vm.acceptDeleteDepartamento(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Borrar departamento")])])]}}],null,true)})],1)],1)],1)],1)],1)],1):_vm._e()]}}],null,true)})],1)],1)],1),_c('vx-dialog-add-edit-delegacion',{attrs:{"show":_vm.showDialogDelegacion,"isEditDelegacion":_vm.isEditDelegacion,"editedDelegacion":_vm.editDelegacion,"newDelegacion":_vm.editEntidad},on:{"cancelar":function($event){_vm.showDialogDelegacion = false},"addDelegacion":_vm.acceptAddDelegacion,"editDelegacion":_vm.acceptEditDelegacion}}),_c('vx-dialog-add-edit-departamento',{attrs:{"show":_vm.showDialogDepartamento,"isEditDepartamento":_vm.isEditDepartamento,"editedDepartamento":_vm.editDepartamento,"newDepartamento":_vm.editDelegacion},on:{"cancelar":function($event){_vm.showDialogDepartamento = false},"addDepartamento":_vm.acceptAddDepartamento,"editDepartamento":_vm.acceptEditDepartamento}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }