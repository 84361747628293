var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":"","height":"100%"}},[_c('v-toolbar',{attrs:{"color":"primaryLight","dark":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Gestión de entidades")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.comprobarPermisos(['MOD_ENTIDADES'])),expression:"comprobarPermisos(['MOD_ENTIDADES'])"}],attrs:{"fab":"","bottom":"","right":"","absolute":"","color":"primary"},on:{"click":_vm.showAddEntidadDialog}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Añadir entidad")])])],1),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"3","sm":"3"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Búsqueda","outlined":"","dense":_vm.$vuetify.breakpoint.mobile,"clearable":"","hide-details":""},on:{"input":_vm.searchEntidades},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"6","md":"3","sm":"3"}},[_c('v-select',{attrs:{"label":"Entidad","items":_vm.entidades,"menu-props":{ bottom: true, offsetY: true },"item-text":"entidad_titular","item-value":"entidad_titular","return-object":"","outlined":"","dense":_vm.$vuetify.breakpoint.mobile,"hide-details":"","clearable":true},model:{value:(_vm.entidadSelected),callback:function ($$v) {_vm.entidadSelected=$$v},expression:"entidadSelected"}})],1)],1),_c('v-row',[_c('v-data-table',{class:{
            'table': !_vm.$vuetify.breakpoint.mobile,
            'tableMobile': _vm.$vuetify.breakpoint.mobile},attrs:{"headers":_vm.headersEntidad,"items":_vm.entidadesFilter ? _vm.entidadesFilter : _vm.entidades ? _vm.entidades : [],"height":"100%","loading":_vm.isLoading,"loading-text":"Cargando datos... por favor, espere","item-key":"id_entidad","fixed-header":"","hide-default-footer":"","page":_vm.currentPage,"items-per-page":_vm.itemsPerPage},on:{"update:page":function($event){_vm.currentPage=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('p',[_vm._v("Datos no disponibles.")])]},proxy:true},{key:"item.entidad_titular",fn:function(ref){
            var item = ref.item;
return [(item.logo)?_c('span',[_c('v-avatar',{attrs:{"size":"30"}},[_c('img',{attrs:{"src":_vm.$options.filters.base64Image(item.logo)}})]),_vm._v(" "+_vm._s(item.entidad_titular)+" ")],1):_c('span',[_vm._v(_vm._s(item.entidad_titular))])]}},{key:"item.Grupo_accions",fn:function(ref){
            var item = ref.item;
return _vm._l((item.Grupo_accions),function(subItem,iSub){return _c('div',{key:subItem.id_grupo_accion},[(iSub < item.Grupo_accions.length - 1)?_c('span',[_vm._v(" "+_vm._s(subItem.nombre)+",")]):_c('span',[_vm._v(" "+_vm._s(subItem.nombre)+" ")])])})}},{key:"item.convenio",fn:function(ref){
            var item = ref.item;
return [(item.convenio === false || item.convenio === null)?_c('span',[_vm._v("No")]):(item.convenio && item.documentacion)?_c('span',[_vm._v(" Sí "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.getDocumento(item.id_entidad)}}},'v-btn',attrs,false),on),[_c('img',{attrs:{"src":_vm.iconoTipoArchivo(item)}})])]}}],null,true)},[_c('span',[_vm._v("Descargar documento")])])],1):_c('span',[_vm._v("Sí")])]}},{key:"item.fechastamp",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.fechastamp)))])]}},{key:"item.recogida_info",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.recogida_info))+" ")])]}},{key:"item.Usuario",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.Usuario.nombre + ' ' + item.Usuario.apellidos))])]}},{key:"item.ACCIONES",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.comprobarPermisos(['MOD_ENTIDADES'])),expression:"comprobarPermisos(['MOD_ENTIDADES'])"}],attrs:{"icon":"","disabled":!_vm.entidadCurrentUser(item.id_entidad) && !_vm.comprobarPermisos([])},on:{"click":function($event){return _vm.showEditEntidadDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"teal"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.comprobarPermisos(['MOD_ENTIDADES'])),expression:"comprobarPermisos(['MOD_ENTIDADES'])"}],attrs:{"icon":"","disabled":!_vm.entidadCurrentUser(item.id_entidad) && !_vm.comprobarPermisos([])},on:{"click":function($event){return _vm.acceptDeleteEntidad(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Borrar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.comprobarPermisos(['VER_ENTIDADES', 'MOD_ENTIDADES'])),expression:"comprobarPermisos(['VER_ENTIDADES', 'MOD_ENTIDADES'])"}],attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.verDelegacionesEntidad(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver delegaciones de entidad")])])]}}],null,true)})],1),_c('v-row',[(!_vm.$vuetify.breakpoint.mobile)?_c('v-col',{attrs:{"xs":"2","sm":"3","md":"2"}},[_c('v-row',[_c('div',{staticClass:"text-center align-center mt-6 pl-6 ml-6"},[_c('span',[_vm._v("Total "+_vm._s(_vm.totalRecords)+" registros")])])])],1):_vm._e(),_c('v-col',{attrs:{"xs":"1","sm":"4","md":"3"}},[_c('v-row',[_c('v-col',{staticClass:"textMostrar",attrs:{"sm":"4","md":"4"}},[_c('div',[_c('span',[_vm._v("Mostrar")])])]),_c('v-col',{attrs:{"xs":"4","sm":"5","md":"4"}},[_c('v-select',{class:{
                  'select': !_vm.$vuetify.breakpoint.mobile,
                  'selectMobile': _vm.$vuetify.breakpoint.mobile},attrs:{"dense":"","outlined":"","hide-details":"","items":_vm.perPageOptions},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"6"}},[_c('div',[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])],1)],1)],1),_c('vx-dialog-add-edit-entidad',{attrs:{"show":_vm.showDialog,"isEdit":_vm.isEdit,"editedEntidad":_vm.editEntidad},on:{"cancelar":function($event){_vm.showDialog = false},"addEntidad":_vm.acceptAddEntidad,"editEntidad":_vm.acceptEditEntidad}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }