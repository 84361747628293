<template>
  <div
    ref="" class="content"
    v-if="chatActivo"
  >
    <div
      class="chat-lateral-menu" 
      :class="{'active': lateralMenuDisplayed}"
    >
      <vx-chat-lateral-menu 
        @close="closeLateralMenu"
        :headerTitle="lateralMenuTitle"
        :canAddChats="isChatsChatActive && userCanEditEmergencias"
      >
        <vx-entidades-chat v-if="isEntidadesChatActive" ref="entidades" @close="lateralMenuDisplayed = false" />
        <vx-chats-chat v-if="isChatsChatActive" ref="chats" @close="lateralMenuDisplayed = false" />
      </vx-chat-lateral-menu>
    </div>
    <div
      class="chat-container" :class="{
        'message-selected': hasMessagesSelected
      }"
    >
      <div class="header">
        <div class="header-top">
          <v-autocomplete
            v-model="entidadFiltrada"
            prepend-icon="mdi-filter"
            dense
            clearable
            outlined
            label="Filtrar por entidad"
            :items="entidadesFromMessages"
            item-text="entidad_titular"
            item-value="id_entidad"
          />
          <div class="buttons">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  fab small v-bind="attrs" v-on="on" color="#1A33F4" 
                  @click="openCloseChatsChat"
                >
                  <vxi-gestion-chats :active="isChatsChatActive" />
                </v-btn>
              </template>
              <span>Grupos</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  fab small v-bind="attrs" v-on="on" color="#1A33F4" 
                  @click="isFilterDocuments = !isFilterDocuments"
                >
                  <v-icon 
                    :color="isFilterDocuments? '#1AF4C0' : 'white'"
                  >
                    mdi-file-document-multiple-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>Documentos enlazados</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  @click="openCloseEntidadesChat"
                  fab small v-bind="attrs" v-on="on" color="#1A33F4" 
                >
                  <vxi-gestion-entidades :active="isEntidadesChatActive" />
                </v-btn>
              </template>
              <span>Gestionar entidades</span>
            </v-tooltip>
          </div>
        </div>
        <span class="chat-name">{{ chatActivo.nombre }}</span>
      </div>
      <div
        ref="messagesContainer" 
        :class="{
          'selected':hasMessagesSelected
        }"
        class="messages"
      >
        <div class="mensages-center-container">
          <vx-mensaje 
            v-for="message in messages" 
            :key="message.id"
            :user-can-select-messages="userCanSelectMessages"
            :message="message"
          />
        </div>
      </div>
      <div 
        v-if="userCanTextMessage"
        class="submit"
      >
        <div v-if="uploadFile" class="linked-document">
          <v-chip
            dark
            @click:close="removeUploadFile"
            close-icon="mdi-close" 
            close
          >
            {{ uploadFile.name }}
          </v-chip>
        </div>
        <div class="input">
          <input v-if="showInputFullScreen" @change="onFileChange" id="archivoUploadExpanded" type="file" hidden>
          <input v-else @change="onFileChange" id="archivoUpload" type="file" hidden>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                @click="chooseFiles()"
                icon
                v-bind="attrs" v-on="on" 
              >
                <v-icon>mdi-paperclip</v-icon>
              </v-btn>
            </template>
            <span>Añadir archivo</span>
          </v-tooltip>
          <v-textarea
            @keydown="inputHandler"
            v-model="textToMessage"
            rows="2"
            no-resize
            label="Escribe aqui un mensaje"
            counter
            hide-details
            :rules="maxCharactersEmergencyMessage"
          />
          <v-select
            class="relevancia"
            :items="relevancias"
            :menu-props="{ bottom: true, offsetY: true }"
            label="Relevancia"
            item-text="nombre"
            item-value="id"
            v-model="selectedRelevancia"
            outlined
            hide-details
            return-object
          />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                @click="comprobarEmergenciaActivaYEnviarMensaje"
                icon
                :loading="isSending"
                v-bind="attrs" v-on="on" 
              >
                <v-icon>mdi-send</v-icon>
              </v-btn>
            </template>
            <span>Enviar</span>
          </v-tooltip>
        </div>
      </div>
      <div
        v-if="userCanTextMessage"
        :class="{'active': hasMessagesSelected}" class="selection-menu"
      >
        <span class="number-selected">
          {{ `${messagesSelected.length} mensajes seleccionados` }}
        </span>
        <span 
          v-if="validSelectedMessages && validSelectedMessages.length > 0"
          class="action-messages-selected"
          @click="markMessagesInvalid"
        >
          <v-icon color="#1A33F4">mdi-cancel</v-icon>
          <span>Marcar mensajes como no válidos</span>
        </span>
        <span 
          v-if="invalidSelectedMessages && invalidSelectedMessages.length > 0"
          class="action-messages-selected"
          @click="markMessagesValid"
        >
          <v-icon color="#1A33F4">mdi-check</v-icon>
          <span>Marcar mensajes como válidos</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { ChatRequest } from '@/api/chat'
import constants from '@/helpers/constants'
import MensajeVue from './Mensaje.vue'
import ChatLateralMenuVue from './ChatLateralMenu.vue'
import EntidadesChatVue from './EntidadesChat.vue'
import GestionEntidadesIcon from '../../../../assets/icons/chat/GestionEntidades.icon.vue'
import GestionChatsIconVue from '@/assets/icons/chat/GestionChats.icon.vue'
import {FileHelper} from '@/helpers/FileHelper'
import ChatsChat from './ChatsChat.vue'
import { mapGetters, mapState } from 'vuex'


export default {

  components: {
    'vx-mensaje': MensajeVue,
    'vx-chat-lateral-menu': ChatLateralMenuVue,
    'vx-entidades-chat': EntidadesChatVue,
    'vx-chats-chat': ChatsChat,
    'vxi-gestion-entidades': GestionEntidadesIcon,
    'vxi-gestion-chats': GestionChatsIconVue
  },

  props: {
    chatActivo: null
  },

  data: () => ({
    isChatsChatActive: false,
    isEntidadesChatActive: false,
    nameRules: true,
    textToMessage: null,
    refreshInterval: null,
    selectedRelevancia: null,
    lateralMenuDisplayed: false,
    entidadFiltrada: null,
    isFilterDocuments: false,
    uploadFile: null,
    lateralMenuTitle: null,
    isSending: false,
    showInputFullScreen: false
  }),

  async mounted (){
    await this.refreshMessages()
    this.scrollBottom()
    this.selectedRelevancia = this.defaultRelevancia
    this.refreshInterval = setInterval(async () => {
      await this.refreshMessages()
    }, constants.MESSAGES_REFRESH_TIME)

    this.$eventHub.$on('openFullScreen', this.detectFullScrren)
  },

  beforeDestroy () {
    if(this.refreshInterval){
      clearInterval(this.refreshInterval)
    }
  },

  computed: {

    ...mapState('emergencia', ['emergenciaSelected']),

    filterDocumentsButtonColor () {
      return isFilterDocuments? '#1AF4C0' : 'white'
    },

    fileInputId () {
      if (this.showInputFullScreen) {
        return 'archivoUploadExpanded'
      } else {
        return 'archivoUpload'
      }
    },

    messages (){
      return this.$store.state.chat.messages ? this.$store.state.chat.messages.filter(message => 
        (!this.entidadFiltrada || message.Usuario.Entidad.id_entidad == this.entidadFiltrada)
        && (!this.isFilterDocuments || message.idDocumento)
      ) : []
    },
    validSelectedMessages (){
      return this.$store.getters['chat/getValidSelectedMessages']
    },
    invalidSelectedMessages (){
      return this.$store.getters['chat/getInvalidSelectedMessages']
    },
    relevancias () {
      return this.$store.state.chat.relevancias
    },
    defaultRelevancia (){
      return this.$store.state.chat.relevancias.find((relevancia) => relevancia.codigo === 1)
    },
    messagesSelected (){
      return this.$store.state.chat.selectedMessages
    },
    hasMessagesSelected (){
      return this.$store.state.chat.selectedMessages.length > 0 && this.userCanTextMessage
    },
    entidadesChat (){
      const result = this.$store.state.chat.entidades
      return result
    },
    entidadesFromMessages (){
      const result = this.getUniqueItemsFromArray(
        this.messages.map(message => message.Usuario.Entidad)
      )
      return result
    },
    userIsAdmin (){
      return this.$store.getters['usuario/rolesUsuario'](['ADMIN'])
    },
    userCanEditEmergencias (){
      return this.$store.getters['usuario/rolesUsuario'](['MOD_EMERGENCIAS'])
    },
    userCanTextMessage (){
      const userIsEntityCompatible = this.entidadesChat.some(entidad => 
        entidad.idEntidad == this.$store.getters['usuario/currentUsuario'].ENTIDAD.id_entidad || this.userIsAdmin
      )
      return userIsEntityCompatible && this.userCanEditEmergencias
    },
    userCanSelectMessages (){
      return this.userCanTextMessage
    }
  },

  watch: {
    chatActivo (){
      this.refreshMessages()
    },
    lateralMenuDisplayed (newValue) {
      if (!newValue && this.$refs.entidades){
        this.$refs.entidades.cancelar()
      }
    },
    textToMessage (newValue) {
      if (newValue && newValue.length > 1000) {
        this.notificarExcesoCaracteresDelMensaje ()
      }
    }
  },

  methods: {

    removeUploadFile () {
      this.uploadFile = null
    },

    detectFullScrren (value) {
      this.showInputFullScreen = value
    },

    onFileChange (e){
      const files = e.target.files || e.dataTransfer.files
      if (files.length) {
        // Check file size
        const fileExtension = FileHelper.getExtensionNombreArchivo(files[0].name)
        const fileType = FileHelper.getFileType(fileExtension)
        const fileTypeTranslate = FileHelper.getFileTypeTranslate(fileType)
        const fileSize = FileHelper.getSizeType(fileType)
        const fileSizeMb = FileHelper.bytesToMegabytes(fileSize)
        if (files[0].size > fileSize) {
          this.uploadFile = null
          this.$notify({
            title: 'Tamaño máximo excedido',
            text: `Se ha excedido el tamaño máximo de archivo de ${fileSizeMb} MB 
            para ${fileTypeTranslate}`,
            type: 'error'
          })
        } else {
          this.uploadFile = files[0]
        }
      }
    },

    chooseFiles () {
      const fileInputElement = document.getElementById(this.fileInputId)
      fileInputElement.value = null
      fileInputElement.click()
    },

    getUniqueItemsFromArray (array){
      let uniqueItems = []
      array.forEach((element) => {
        if (!uniqueItems.includes(element)) {
          uniqueItems.push(element)
        }
      })
      return uniqueItems
    },

    markMessagesInvalid (){
      this.$store.dispatch('chat/MARK_MESSAGES_AS_INVALID')
    },

    markMessagesValid (){
      this.$store.dispatch('chat/MARK_MESSAGES_AS_VALID')
    },

    scrollBottom (){
      if (this.$refs.messagesContainer){
        this.$refs.messagesContainer.scrollTop = this.$refs.messagesContainer.scrollHeight
      }
    },

    openCloseChatsChat (){
      if (this.lateralMenuDisplayed && this.isChatsChatActive){
        this.closeLateralMenu()
      } else {
        this.lateralMenuTitle = 'Grupos'
        this.openLateralMenu()
        this.isChatsChatActive = true
      }
    },

    openCloseEntidadesChat (){
      if (this.lateralMenuDisplayed && this.isEntidadesChatActive){
        this.closeLateralMenu()
      } else {
        this.lateralMenuTitle = 'Entidades'
        this.openLateralMenu()
        this.isEntidadesChatActive = true
        
      }
    },

    openLateralMenu (){
      this.lateralMenuDisplayed = true
      this.isEntidadesChatActive = false
      this.isChatsChatActive = false
    },

    closeLateralMenu () {
      this.lateralMenuDisplayed = false
      this.isEntidadesChatActive = false
      this.isChatsChatActive = false
    },

    async refreshMessages () {
      return this.$store.dispatch('chat/FETCH_MESSAGES')
    },

    inputHandler (e) {
      if (e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault()
        this.comprobarEmergenciaActivaYEnviarMensaje()
      }
    },

    async comprobarEmergenciaActivaYEnviarMensaje () {
      if (!this.emergenciaSelected.activa && this.uploadFile) {
        const msg = 'La emergencia no está activa, el archivo adjunto se creara como archivado'
        this.$root.$confirmDialog.open('¿Está seguro?', msg).then((result) => {
          if (result) {
            this.enviarMensaje()
          }
        })
      } else {
        this.enviarMensaje()
      }
    },

    async enviarMensaje () {
      if (this.textToMessage || this.uploadFile){
        this.isSending = true
        if (!this.emergenciaSelected.activa) {
        }
        ChatRequest.sendMessage(this.chatActivo.id, 
          {
            texto: this.textToMessage,
            idRelevancia: this.selectedRelevancia.id,
          },
          this.uploadFile ? await FileHelper.createFileDataForChat(this.uploadFile): null
        )
          .then(async () => {
            await this.refreshMessages()
            this.textToMessage = null
            this.uploadFile = null
            this.scrollBottom()
            this.isSending = false
          })
          .catch((error) => {
            console.error('ERROR ENVIAR MENSAJE', error)
            this.textToMessage = null
            this.uploadFile = null
            this.isSending = false
            if (error.response && (error.response.status !== 200 && error.response.status !== 500)) {
              this.$notify({
                title: error.response.statusText,
                text: constants.notificacionesPermisos.Chat.sendMessage,
                type: 'error'
              })
            } else if (error.request) {
              this.$notify({
                title: error.request.statusText,
                text: constants.notificacionesPermisos.Error[500],
                type: 'error'
              })
            } else {
              this.$notify({
                title: 'No se ha podido enviar el mensaje',
                text: 'Ha habido algún error, vuelva a intentarlo en unos minutos',
                type: 'error'
              })  
            }
          })
      }
    },

    notificarExcesoCaracteresDelMensaje () {
      this.$notify({
        title: 'Límite de caracteres superado',
        text: 'Se ha superado el límite de 1000 caracteres del mensaje',
        type: 'warning'
      })
    }
  }
}

</script>

<style scoped lang="scss">

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;

    .chat-lateral-menu {
      overflow: hidden;
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
      transition: all 0.3s cubic-bezier(.25,.8,.25,1);    
      width: 0;
      height: 100%;
      transition: 0.2s ease;
      flex-shrink: 0;
      &.active {
        width: 250px;
        @media (max-width: 575px){
          width: 100%;
        }
      }
    }

    .chat-container {
      position: relative;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      height: 100%;
      overflow: hidden;
      &.message-selected {
      .messages {
        margin-bottom: 30px;
      }

      .submit {
        display: none;
      }
    }

    .header {
      display: flex;
      flex-direction: column;
      padding: 10px;
      justify-content: space-between;
      text-transform: uppercase;
      gap: 6px;
      .header-top {
        display: flex;
        align-items: baseline;
        gap: 30px;
        height: 3rem;
        .buttons {
          display: flex;
          justify-content: space-between;
          gap: 12px;
        }
      }
      span {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 145%;
        word-break: break-all;
      }
      
    }
    .messages {
      overflow: auto;
      align-items: flex-end;
      justify-content: center;
      display: flex;
      flex-grow: 1;
      flex-flow: wrap-reverse;
      padding: 12px;
      background-color: #EFF0F6;
      &.selected {
        margin-bottom: 30px;
      }
      .mensages-center-container{
        max-width: 700px;
        flex-grow: 1;
        align-self: flex-start;        
        flex-direction: column-reverse;
        display: flex;
        gap: 14px;
      }
    }
    .submit {
      background-color: white;
      display: flex;
      padding: 0 10px;
      flex-direction: column;
      .linked-document{
        padding-left: 10px;
        padding-top: 4px;
      }
      .input {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-direction: row;
        padding-bottom: 10px;
        .relevancia {
          width: 150px;
          flex-grow: 0;
        }
      }
      
    }


    .selection-menu {
      height: 40px;
      width: 100%;
      position: absolute;
      bottom: -40px;
      right: 0;
      align-items: center;
      background-color: white;
      transition: 0.3s ease-in-out;
      display: flex;
      justify-content: flex-end;
      gap: 12px;
      padding-right: 22px;
      &.active {
        bottom: 0px
      }
      .action-messages-selected {
        cursor: pointer;
        color: #1A33F4;
        :first-child {
          margin-right: 6px;
        }
      }
    }
  }

    

  }

</style>