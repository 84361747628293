var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-slide-x-reverse-transition',[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],attrs:{"id":"layerList","height":"calc(100% - 43.5px)","width":"350px","tile":""}},[_c('v-toolbar',{attrs:{"color":"primaryLight","dark":"","dense":"","fixed":"","id":"layerListHeader"}},[_c('v-toolbar-title',[_vm._v(" Capas ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.capasActivas),expression:"capasActivas"}],staticClass:"mx-0",attrs:{"icon":"","x-large":""},on:{"click":function($event){return _vm.desactivarCapas()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"28"}},[_vm._v("mdi-eye")])],1)]}}])},[_c('span',[_vm._v("Desactivar todas las capas")])]),_c('v-btn',{staticClass:"mx-0",attrs:{"icon":"","x-large":""},on:{"click":function($event){return _vm.close()}}},[_c('v-icon',{attrs:{"size":"30"}},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{attrs:{"grid-list-sm":""}},[_c('div',[_c('v-expansion-panels',_vm._l((_vm.layersOrder.filter(function (x) { return x.title !== null && x.type !== 'vector-tileLayer' && x.subLayers; })),function(layer1,i){return _c('v-expansion-panel',{key:i},[_c('v-expansion-panel-header',{directives:[{name:"show",rawName:"v-show",value:(layer1.id != 'emergencyLayers' || (!_vm.isShowEmergencias && _vm.userCanSeeEmergencias)),expression:"layer1.id != 'emergencyLayers' || (!isShowEmergencias && userCanSeeEmergencias)"}],attrs:{"hide-actions":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('div',{attrs:{"id":"layerItem"}},[_c('v-icon',[(open)?[_vm._v("mdi-chevron-down")]:[_vm._v("mdi-chevron-right")]],2),_c('span',{class:{
                      'label-strong': layer1.visible
                    }},[_vm._v(_vm._s(layer1.title))])],1)]}}],null,true)}),_c('v-expansion-panel-content',_vm._l((layer1.subLayers),function(layer2,j){return _c('div',{key:j},[(layer2.type === 'groupLayer' && layer2.layers)?_c('div',{staticClass:"abuelo"},[_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"hide-actions":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var open = ref.open;
return [_c('div',{attrs:{"id":"layerItem"}},[_c('v-icon',[(open)?[_vm._v("mdi-chevron-down")]:[_vm._v("mdi-chevron-right")]],2),_c('span',{class:{
                                'label-strong': layer2.visible
                              }},[_vm._v(_vm._s(layer2.title))])],1)]}}],null,true)}),(layer2.layers)?_c('v-expansion-panel-content',_vm._l((layer2.layers),function(layer3,k){return _c('div',{key:k},[(layer3.type === 'groupLayer')?_c('div',{staticClass:"abuelo"},[_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"hide-actions":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var open = ref.open;
return [(layer3.layers.length > 0)?_c('div',{attrs:{"id":"layerItem"}},[_c('v-icon',[(open)?[_vm._v("mdi-chevron-down")]:[_vm._v("mdi-chevron-right")]],2),_c('span',{class:{
                                          'label-strong': layer3.visible
                                        }},[_vm._v(_vm._s(layer3.title))])],1):_vm._e()]}}],null,true)}),(layer3.layers.length > 0)?_c('v-expansion-panel-content',[_c('v-list',{key:_vm.updateList,attrs:{"dense":""}},_vm._l((layer3.layers),function(item,n){return _c('vx-layer-item',{key:n,attrs:{"showTitle":true,"onlyPadre":false,"item":item,"id":"layerItem"}},[_vm._v(_vm._s(item)+" ")])}),1)],1):_vm._e()],1)],1)],1):_c('div',[(layer3.layers.length > 0)?_c('div',{staticClass:"abuelo",attrs:{"id":"layerItem"}},[_c('v-list',{attrs:{"dense":""}},[_c('vx-layer-item',{key:_vm.updateList,attrs:{"showTitle":true,"onlyPadre":false,"item":layer3}})],1)],1):_vm._e()])])}),0):_vm._e()],1)],1)],1):_c('div',[(layer2.id !== 'layer-drawSketcher')?_c('div',{staticClass:"abuelo",attrs:{"id":"layerItem"}},[_c('v-list',{attrs:{"dense":""}},[_c('vx-layer-item',{key:_vm.updateList,attrs:{"showTitle":true,"onlyPadre":false,"item":layer2}})],1)],1):_vm._e()])])}),0)],1)}),1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }