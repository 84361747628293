import api from '@/api'
import constants from '@/helpers/constants'
import Vue from 'vue'

let Q = require('q')

const state = {
  accionesEmergenciaSituacion: []
}

const getters = {
  getAccionesSituacionEmergencia: (state) => async (data) => {
    try {
      const response = await api.accionSituacionEmergencia.getAccionesBySituacionEmergenciaId (data)
      if (response.status === 200) {
        return response.data.body
      }
    } catch (err) {
      if (err.response && (err.response.status !== 200 && err.response.status !== 500)) {
        Vue.notify({
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Emergencias.getAccionesBySituacionEmergenciaId,
          type: 'error'
        })
      } else if (err.request) {
        Vue.notify({
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
          type: 'error'
        })
      }
    }
  }
}

const mutations = {
  ERROR_AUTORIZACION (state, data) {
    this.notify(data.title, data.text, 'error')
  },

  ERROR_RESPUESTA_SERVER (state, data) {
    this.notify(data.title, data.text, 'error')
  }
}

const actions = {

  async addAccionSituacionEmergencia ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.accionSituacionEmergencia.addAccionByAccionSituacionEmergenciaId(data)
      if (response.status === 200) {
        return response.data.body
      }
      deferred.resolve()
    } catch (err) {
      if (err.response && (err.response.status !== 200 && err.response.status !== 500)) {

        commit('ERROR_AUTORIZACION', 
          {
            title: err.response.statusText, 
            text: constants.notificacionesPermisos.Emergencias.nuevaAccionByEmergenciaSituacionOperativaId
          })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500]
        })
      }
      console.error(err)
      deferred.reject(err)
    }
    return deferred.promise
  },

  async updateAccionSituacionEmergencia ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.accionSituacionEmergencia.updateAccionByAccionSituacionEmergenciaId(data)
      if (response.status === 200) {
        return response.data.body
      }
      deferred.resolve()
    } catch (err) {
      if (err.response && (err.response.status !== 200 && err.response.status !== 500)) {

        commit('ERROR_AUTORIZACION', 
          {
            title: err.response.statusText, 
            text: constants.notificacionesPermisos.Emergencias.editarByAccionSituacionEmergenciaId
          })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500]
        })
      }
      console.error(err)
      deferred.reject(err)
    }
    return deferred.promise
  },

  async deleteAccionSituacionEmergencia ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.accionSituacionEmergencia.deleteAccionByAccionSituacionEmergenciaId(data)
      if (response.status === 200) {
        return response.data.body
      }
    } catch (err) {
      if (err.response && (err.response.status !== 200 && err.response.status !== 500)) {

        commit('ERROR_AUTORIZACION', 
          {
            title: err.response.statusText, 
            text: constants.notificacionesPermisos.Emergencias.borrarByAccionSituacionEmergenciaId
          })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500]
        })
      }
      console.error(err)
      deferred.reject(err)
    }
    return deferred.promise
  }
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
    
export default module