var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.chatActivo)?_c('div',{ref:"",staticClass:"content"},[_c('div',{staticClass:"chat-lateral-menu",class:{'active': _vm.lateralMenuDisplayed}},[_c('vx-chat-lateral-menu',{attrs:{"headerTitle":_vm.lateralMenuTitle,"canAddChats":_vm.isChatsChatActive && _vm.userCanEditEmergencias},on:{"close":_vm.closeLateralMenu}},[(_vm.isEntidadesChatActive)?_c('vx-entidades-chat',{ref:"entidades",on:{"close":function($event){_vm.lateralMenuDisplayed = false}}}):_vm._e(),(_vm.isChatsChatActive)?_c('vx-chats-chat',{ref:"chats",on:{"close":function($event){_vm.lateralMenuDisplayed = false}}}):_vm._e()],1)],1),_c('div',{staticClass:"chat-container",class:{
      'message-selected': _vm.hasMessagesSelected
    }},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"header-top"},[_c('v-autocomplete',{attrs:{"prepend-icon":"mdi-filter","dense":"","clearable":"","outlined":"","label":"Filtrar por entidad","items":_vm.entidadesFromMessages,"item-text":"entidad_titular","item-value":"id_entidad"},model:{value:(_vm.entidadFiltrada),callback:function ($$v) {_vm.entidadFiltrada=$$v},expression:"entidadFiltrada"}}),_c('div',{staticClass:"buttons"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"#1A33F4"},on:{"click":_vm.openCloseChatsChat}},'v-btn',attrs,false),on),[_c('vxi-gestion-chats',{attrs:{"active":_vm.isChatsChatActive}})],1)]}}],null,false,3299466561)},[_c('span',[_vm._v("Grupos")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"#1A33F4"},on:{"click":function($event){_vm.isFilterDocuments = !_vm.isFilterDocuments}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.isFilterDocuments? '#1AF4C0' : 'white'}},[_vm._v(" mdi-file-document-multiple-outline ")])],1)]}}],null,false,456603599)},[_c('span',[_vm._v("Documentos enlazados")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"#1A33F4"},on:{"click":_vm.openCloseEntidadesChat}},'v-btn',attrs,false),on),[_c('vxi-gestion-entidades',{attrs:{"active":_vm.isEntidadesChatActive}})],1)]}}],null,false,2314423149)},[_c('span',[_vm._v("Gestionar entidades")])])],1)],1),_c('span',{staticClass:"chat-name"},[_vm._v(_vm._s(_vm.chatActivo.nombre))])]),_c('div',{ref:"messagesContainer",staticClass:"messages",class:{
        'selected':_vm.hasMessagesSelected
      }},[_c('div',{staticClass:"mensages-center-container"},_vm._l((_vm.messages),function(message){return _c('vx-mensaje',{key:message.id,attrs:{"user-can-select-messages":_vm.userCanSelectMessages,"message":message}})}),1)]),(_vm.userCanTextMessage)?_c('div',{staticClass:"submit"},[(_vm.uploadFile)?_c('div',{staticClass:"linked-document"},[_c('v-chip',{attrs:{"dark":"","close-icon":"mdi-close","close":""},on:{"click:close":_vm.removeUploadFile}},[_vm._v(" "+_vm._s(_vm.uploadFile.name)+" ")])],1):_vm._e(),_c('div',{staticClass:"input"},[(_vm.showInputFullScreen)?_c('input',{attrs:{"id":"archivoUploadExpanded","type":"file","hidden":""},on:{"change":_vm.onFileChange}}):_c('input',{attrs:{"id":"archivoUpload","type":"file","hidden":""},on:{"change":_vm.onFileChange}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.chooseFiles()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-paperclip")])],1)]}}],null,false,1733607822)},[_c('span',[_vm._v("Añadir archivo")])]),_c('v-textarea',{attrs:{"rows":"2","no-resize":"","label":"Escribe aqui un mensaje","counter":"","hide-details":"","rules":_vm.maxCharactersEmergencyMessage},on:{"keydown":_vm.inputHandler},model:{value:(_vm.textToMessage),callback:function ($$v) {_vm.textToMessage=$$v},expression:"textToMessage"}}),_c('v-select',{staticClass:"relevancia",attrs:{"items":_vm.relevancias,"menu-props":{ bottom: true, offsetY: true },"label":"Relevancia","item-text":"nombre","item-value":"id","outlined":"","hide-details":"","return-object":""},model:{value:(_vm.selectedRelevancia),callback:function ($$v) {_vm.selectedRelevancia=$$v},expression:"selectedRelevancia"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","loading":_vm.isSending},on:{"click":_vm.comprobarEmergenciaActivaYEnviarMensaje}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-send")])],1)]}}],null,false,167503266)},[_c('span',[_vm._v("Enviar")])])],1)]):_vm._e(),(_vm.userCanTextMessage)?_c('div',{staticClass:"selection-menu",class:{'active': _vm.hasMessagesSelected}},[_c('span',{staticClass:"number-selected"},[_vm._v(" "+_vm._s(((_vm.messagesSelected.length) + " mensajes seleccionados"))+" ")]),(_vm.validSelectedMessages && _vm.validSelectedMessages.length > 0)?_c('span',{staticClass:"action-messages-selected",on:{"click":_vm.markMessagesInvalid}},[_c('v-icon',{attrs:{"color":"#1A33F4"}},[_vm._v("mdi-cancel")]),_c('span',[_vm._v("Marcar mensajes como no válidos")])],1):_vm._e(),(_vm.invalidSelectedMessages && _vm.invalidSelectedMessages.length > 0)?_c('span',{staticClass:"action-messages-selected",on:{"click":_vm.markMessagesValid}},[_c('v-icon',{attrs:{"color":"#1A33F4"}},[_vm._v("mdi-check")]),_c('span',[_vm._v("Marcar mensajes como válidos")])],1):_vm._e()]):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }