import { EmergenciaRequest } from '@/api/emergencia'
import { FaseEmergenciaRequest } from '@/api/faseEmergencia'
import { EmergenciaArcGISHelper } from '@/helpers/ArcGISHelpers/EmergenciaArcGISHelper'
import constants from '@/helpers/constants'
import Vue from 'vue'

function hasEmergenciasSOP1 (){
  return state?.emergencias.some(
    (emergencia) => 
      emergencia.activa &&
      (emergencia.SituacionOperativa?.codigo === 
      constants.SITUACION_OPERATIVA_CODIGO.SOP1)
  )
}

function hasEmergenciasSOP0 (){
  return state?.emergencias.some(
    (emergencia) => 
      emergencia.activa &&
      (emergencia.SituacionOperativa?.codigo === 
      constants.SITUACION_OPERATIVA_CODIGO.SOP0)
  )
}

function hasEmergenciasSOP2 (){
  return state?.emergencias.some(
    (emergencia) => 
      emergencia.activa &&
      (emergencia.SituacionOperativa?.codigo === 
      constants.SITUACION_OPERATIVA_CODIGO.SOP2)
  )
}

function hasEmergenciasSOP3 () {
  return state?.emergencias.some(
    (emergencia) =>
      (emergencia.activa && emergencia.SituacionOperativa?.codigo === 
      constants.SITUACION_OPERATIVA_CODIGO.SOP3)
  )
}

function getEmergenciasIcon (){
  if (hasEmergenciasSOP3()) {
    return require('@/assets/emergencias/alert_purple.svg')
  }
  if (hasEmergenciasSOP2()){
    return require('@/assets/emergencias/alert_red.svg')
  }
  if (hasEmergenciasSOP1()){
    return require('@/assets/emergencias/alert_orange.svg')
  }
  if (hasEmergenciasSOP0()){
    return require('@/assets/emergencias/alert_green.svg')
  }
  return require('@/assets/emergencias/alert_default.svg')
}

const state = {
  isShowEmergencias: false,
  fasesEmergencia: null,
  emergenciaSelected: null,
  emergencias: null,
  emergenciasIcon: require('@/assets/emergencias/alert_default.svg'),
}

const getters = {

  emergencias: (state) => {
    return state.emergencias
  },

  orderEmergenciasByNombreAsc: (state) => {
    let emergenciasOrder = JSON.parse(JSON.stringify(state.emergencias))

    emergenciasOrder && emergenciasOrder.sort((a, b) => {
      let aOrden = a.nombre
      let bOrden = b.nombre
      return (aOrden < bOrden) ? -1 : 1
    })

    return emergenciasOrder
  },

}

const mutations = {
  changeShowEmergencias (state , active){
    state.isShowEmergencias = active
    EmergenciaArcGISHelper.showLayer(active)
  },
  setFasesEmergencia (state, fasesEmergencia){
    state.fasesEmergencia = fasesEmergencia
  },

  setEmergenciaSelected (state, newEmergenciaSelected){
    state.emergenciaSelected = newEmergenciaSelected
    if (newEmergenciaSelected){
      this.dispatch('chat/FETCH_CHATS', newEmergenciaSelected.id)
    }
  },

  setEmergencias (state, emergencias){
    state.emergencias = emergencias
    EmergenciaArcGISHelper.refreshLayer()
    state.emergenciasIcon = getEmergenciasIcon()
  }
}

const actions = {
  changeShowEmergencias ({commit}, active){
    commit('changeShowEmergencias', active)
    if (!active){
      commit('setEmergenciaSelected', null)
    }
  },
  
  async fetchFasesEmergencia ({commit}) {
    const response = await FaseEmergenciaRequest.getAll()
    commit('setFasesEmergencia', response)
  },

  async fetchEmergencias ({commit}){
    try {
      const emergencias = await EmergenciaRequest.getEmergencias()
      commit('setEmergencias', emergencias)
    } catch (err) {
      if (err.response && (err.response.status != 200 && err.response.status !== 500)) {
        Vue.notify({
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Emergencias.getEmergencias,
          type: 'error'
        })
      } else if (err.request) {
        Vue.notify({
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
          type: 'error'
        })
      }
    }
    
  },

  async updateEmergenciaSelectedData ({commit, state}){
    try {
      const emergenciaSelectedUpdated = 
      await EmergenciaRequest.getEmergencia(state.emergenciaSelected.id)
      commit('setEmergenciaSelected', emergenciaSelectedUpdated)
    } catch (err) {
      if (err.response && (err.response.status !== 200 && err.response.status !== 500)) {
        Vue.notify({
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Emergencias.getEmergencia,
          type: 'error'
        })
      } else if (err.request) {
        Vue.notify({
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
          type: 'error'
        })
      }
    }
  }

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}