import Vue from 'vue'
import request from './request'
import constants from '@/helpers/constants'

export class SituacionOperativaRequest {
  static async getAll (){
    try {
      return (await request.get('situacionOperativa')).data.body
    } catch (err) {
      if (err.response && (err.response.status !== 200 && err.response.status !== 500)) {
        Vue.notify({
          title: err.response.statusText,
          text: constants.notificacionesPermisos.SituacionOperativa.getSituacionesOperativas,
          type: 'error',
        })
      } else if (err.request) {
        Vue.notify({
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
          type: 'error'
        })
      }
      
    }
  }

  static async getByFaseEmergencia (faseEmergenciaId){
    return (await request.get('situacionOperativa',{
      params: {
        faseEmergencia: faseEmergenciaId
      }})).data.body
  }

  static async getSituacionesOperativasFromEmergencia (emergenciaId){
    return (await request.get('situacionOperativa',{
      params: {
        idEmergencia: emergenciaId
      }
    })).data.body
  }
}