var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":"","height":"100%"}},[_c('v-toolbar',{attrs:{"color":"primaryLight","dark":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Administrar flujos de trabajo")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.comprobarPermisos(['MOD_ACCIONES'])),expression:"comprobarPermisos(['MOD_ACCIONES'])"}],staticStyle:{"z-index":"2"},attrs:{"id":"create"}},[_c('v-speed-dial',{attrs:{"top":_vm.top,"bottom":_vm.bottom,"right":_vm.right,"left":_vm.left,"direction":_vm.direction,"open-on-hover":_vm.hover,"transition":_vm.transition,"title":"Menú de acciones"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","dark":"","fab":""},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[(_vm.fab)?_c('v-icon',[_vm._v(" mdi-close ")]):_c('img',{attrs:{"src":require('@/assets/icons/menu_emergente.svg')}})],1)]},proxy:true}]),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.comprobarPermisos(['MOD_ACCIONES'])),expression:"comprobarPermisos(['MOD_ACCIONES'])"}],attrs:{"fab":"","height":"50","width":"50","color":"primary"},on:{"click":_vm.showAddTipoAccionDialog}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Añadir acción")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.comprobarPermisos(['MOD_ACCIONES'])),expression:"comprobarPermisos(['MOD_ACCIONES'])"}],staticClass:"iconsSpeedDial",attrs:{"fab":"","icon":"","color":"primary","to":"asignar_accion"}},'v-btn',attrs,false),on),[_c('img',{attrs:{"src":require('@/assets/icons/accion_enlazada.svg')}})])]}}])},[_c('span',[_vm._v("Asignar acción a situación operativa")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.comprobarPermisos(['MOD_ACCIONES'])),expression:"comprobarPermisos(['MOD_ACCIONES'])"}],staticClass:"iconsSpeedDial",attrs:{"fab":"","icon":"","color":"primary","to":"tipo_emergencia"}},'v-btn',attrs,false),on),[_c('img',{attrs:{"src":require('@/assets/icons/tipo_emergencia.svg')}})])]}}])},[_c('span',[_vm._v("Gestión tipo emergencia")])])],1)],1)],1),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"xs":"3","sm":"3"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Búsqueda","outlined":"","dense":_vm.$vuetify.breakpoint.mobile,"clearable":"","hide-details":""},on:{"input":_vm.searchAcciones},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-data-table',{class:{
            'table': !_vm.$vuetify.breakpoint.mobile,
            'tableMobile': _vm.$vuetify.breakpoint.mobile},attrs:{"height":"100%","headers":_vm.headers,"items":_vm.tiposAccionFilter ? _vm.tiposAccionFilter : _vm.tiposAccion ? _vm.tiposAccion : [],"loading":_vm.isLoading,"loading-text":"Cargando datos... por favor, espere","fixed-header":"","hide-default-footer":"","page":_vm.currentPage,"items-per-page":_vm.itemsPerPage},on:{"update:page":function($event){_vm.currentPage=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('p',[_vm._v("Datos no disponibles.")])]},proxy:true},{key:"item.nombre",fn:function(ref){
            var item = ref.item;
return [(item.SituacionOperativa.length)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-link-variant")])],1)]}}],null,true)},[_c('span',[_vm._v("Acción asociada a una situación operativa")])]):_vm._e(),_c('span',[_vm._v(_vm._s(item.nombre))])]}},{key:"item.fechastamp",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.fechastamp)))])]}},{key:"item.Usuario",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.Usuario.nombre + ' ' + item.Usuario.apellidos))])]}},{key:"item.ACCIONES",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.comprobarPermisos(['MOD_ACCIONES'])),expression:"comprobarPermisos(['MOD_ACCIONES'])"}],attrs:{"icon":""},on:{"click":function($event){return _vm.showEditTipoAccionDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"teal"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.comprobarPermisos(['MOD_ACCIONES'])),expression:"comprobarPermisos(['MOD_ACCIONES'])"}],attrs:{"icon":""},on:{"click":function($event){return _vm.acceptDeleteTipoAccion(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Borrar")])])]}}],null,true)})],1),_c('v-row',[(!_vm.$vuetify.breakpoint.mobile)?_c('v-col',{attrs:{"xs":"2","sm":"3","md":"2"}},[_c('v-row',[_c('div',{staticClass:"text-center align-center mt-6 pl-6 ml-6"},[_c('span',[_vm._v("Total "+_vm._s(_vm.totalRecords)+" registros")])])])],1):_vm._e(),_c('v-col',{attrs:{"xs":"1","sm":"4","md":"3"}},[_c('v-row',[_c('v-col',{staticClass:"textMostrar",attrs:{"sm":"4","md":"4"}},[_c('div',[_c('span',[_vm._v("Mostrar")])])]),_c('v-col',{attrs:{"xs":"4","sm":"5","md":"4"}},[_c('v-select',{class:{
                  'select': !_vm.$vuetify.breakpoint.mobile,
                  'selectMobile': _vm.$vuetify.breakpoint.mobile},attrs:{"dense":"","outlined":"","hide-details":"","items":_vm.perPageOptions},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"6"}},[_c('div',[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])],1)],1)],1),_c('vx-dialog-add-edit-tipo-accion',{attrs:{"show":_vm.showDialog,"isEdit":_vm.isEdit,"editedTipoAccion":_vm.editTipoAccion},on:{"cancelar":function($event){_vm.showDialog = false},"addTipoAccion":_vm.acceptAddTipoAccion,"editTipoAccion":_vm.acceptEditTipoAccion}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }